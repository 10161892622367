import React, { useContext, useEffect } from 'react';
import { Checkbox, CircularProgress, Backdrop } from '@material-ui/core';

import { GlobalContext } from 'src/context/store';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);

  /*  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []); */

  useEffect(() => {
    if (state?.isAuth) {
      for (const idx in state?.user?.settings) {
        if (
          state.user.settings[idx].key ===
          'didimo.customerportal.is_welcome_screen_active'
        ) {
          if (state.user.settings[idx].value === true) {
            navigate(`${process.env.REACT_APP_ROOT_PATH}/${'mydidimos'}`);
          } else {
            navigate(`${process.env.REACT_APP_ROOT_PATH}/${'welcome'}`);
          }
        }
      }
    }
  }, [state]);

  return (
    <div className="loading">
      <CircularProgress color="inherit" />
    </div>
  );
};
export default Welcome;
