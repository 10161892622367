import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { GlobalContext } from '../../context/store';
import axios from 'axios';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  applicationDetail: {
    flexDirection: 'column',
  },
  submitButtonAreaStyles: {
    textAlign: 'right',
    paddingTop: 10,
  },
  mainActionButtons: {
    marginLeft: '10px',
  },
  mainActionButtonsDivider: {
    marginTop: '20px',
  },
  deleteButton: {
    color: 'red',
  },
  apiSecret: {
    cursor: 'pointer',
  },
});

let options = [];

const ConfigureApplication = (props) => {
  //console.log('props ConfigureApplication', props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { isOpen, onSave, onDiscard, onClose } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');
  const [webhookURL, setwebhookURL] = useState('https://');
  const [description, setDescription] = useState('');
  const [nameTouched, setNameTouched] = useState(false);
  // [channelRelease, setchannelRelease] = useState(""),

  const [ReleaseValue, setReleaseValue] = useState();
  const [ReleaseInputValue, setReleaseInputValue] = useState('');
  const [dgpOptions, setDGPOptions] = useState([]);
  const [dgp, setDGP] = useState('');
  const { state } = useContext(GlobalContext);

  useEffect(() => {
    axios
      .get(`/didimos/releases`, {
        headers: { Authorization: `Bearer ${state.user.accessToken}` },
      })
      .then((res) => {
        console.log('Response ', res.data);
        setDGPOptions(res.data);
      });
  }, []);

  useEffect(() => {
    const releaseValue = props.applicationData.release
      ? props.releaseCollection.find(
          (i) => i.code === props.applicationData.release.code
        )
      : { code: '', name: '', status: '' };
    setReleaseValue(releaseValue);
    // console.log("ReleaseValue", props.releaseCollection, props.applicationData.release, releaseValue);

    /* let myoption = props.applicationData.release
      ? props.applicationData.release.name
      : "";
    if (myoption !== "") {
      setReleaseInputValue(myoption);
    } */

    options = [];
    for (const idx in props.releaseCollection) {
      options.push(props.releaseCollection[idx]);
    }

    setwebhookURL(
      props.applicationData && props.applicationData.webhook_url
        ? props.applicationData.webhook_url
        : 'https://'
    );
    setName(props.applicationData ? props.applicationData.name : '');
    setDescription(
      props.applicationData ? props.applicationData.description : ''
    );
    setDGP(props.applicationData ? props.applicationData.release?.code : '');
    if (!isOpen) {
      setTimeout(() => {
        setNameTouched(false);
        setIsSaving(false);
      }, 500);
    }
  }, [isOpen, props.applicationData]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="configure-application-title"
    >
      <DialogTitle id="configure-application-title">
        {props.name
          ? `Configure application "${props.name}"`
          : 'Configure new application'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              autoFocus
              required
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
              inputProps={{
                'aria-label': 'name',
                'data-id': 'name',
                maxLength: 100,
              }}
              error={nameTouched && (!name || !name.trim())}
              helperText={
                nameTouched &&
                (!name || !name.trim()) &&
                'This field is required'
              }
              onBlur={(e) => {
                setNameTouched(true);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              id="standard-required"
              label="Description"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => {
                setDescription(e.currentTarget.value);
              }}
              inputProps={{
                'aria-label': 'description',
                'data-id': 'description',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              label="WebHook URL"
              variant="outlined"
              fullWidth
              value={webhookURL}
              onChange={(e) => {
                setwebhookURL(e.currentTarget.value);
              }}
              inputProps={{
                'aria-label': 'webhook',
                'data-id': 'webhook',
                maxLength: 100,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth className="select-version-spacing">
              <InputLabel
                id="demo-simple-select-readonly-label"
                style={{ background: 'white' }}
              >
                DGP Release
              </InputLabel>
              <Select
                labelId="demo-simple-select-readonly-label"
                id="demo-simple-select-readonly"
                label="DGP Release"
                value={dgp}
                onChange={(e) => setDGP(e.target.value)}
              >
                {dgpOptions.map((d) => {
                  return (
                    <MenuItem
                      value={d.code}
                      disabled={
                        d.status === 'coming-soon' || d.status === 'obsolete'
                      }
                    >
                      {d.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* <Autocomplete
              id="combo-release-version"
              defaultValue={ReleaseValue}
              onChange={(event, newValue) => {
                setReleaseValue(newValue);
              }}
              getOptionDisabled={(option) =>
                props.releaseCollection.find(i => i.code === option.code).status !== "active"
              }
              getOptionLabel={(option) => option.name + (option.status === "coming-soon" ? " - Coming soon" : (option.status === "obsolete" ? " - Obsolete" : ""))}
              options={props.releaseCollection}
              renderOption={(props, option) => (
                <>
                  {props.name + (props.status === "coming-soon" ? " - Coming soon" : (props.status === "obsolete" ? " - Deprecated" : ""))}
                </>
              )}
              renderInput={(params) => (
                <TextField {...params} label="DGP Release" variant="outlined" />
              )}
            /> */}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={(e) => {
            setIsSaving(true);
            onSave({
              id: props.applicationData.uuid,
              name,
              description: description.trim(),
              webhookURL,
              releaseCode: dgp,
            }).then(() => {
              // setIsSaving(false);
            });
          }}
          color="primary"
          disabled={isSaving || !name || !name.trim()}
        >
          Save
        </Button>

        <Button onClick={onDiscard} color="primary">
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigureApplication;
