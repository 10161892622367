import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import '../pages/MyDidimos/MyDidimos.css';

const DeleteModal = (props) => {
  const { isOpen, onCloseModal, handleDelete } = props;

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <div>
      <Dialog
        className="edit_modal delete_modal"
        open={isOpen}
        aria-labelledby="configure-api-key-title"
      >
        <DialogTitle id="configure-api-key-title" className="font_fam">
          Delete didimos
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: 'red' }}>
            This action will delete the selected didimos. Do you want to
            continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className="font_fam" onClick={handleDelete}>
            Yes
          </Button>
          <Button color="primary" className="font_fam" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
