import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../context/store';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  CardHeader,
  Button,
  List,
  ListItem,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CurrentLinearProgress from '../../packages/CurrentLinearProgress';
import { formatDate, formatPoints } from '../../Helpers/formaters';
import './ActivePackages.css';
import { v4 } from 'uuid';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorSnack from '../../ErrorSnack';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: '16px',
    height: 'auto',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  value: {
    textTransform: 'uppercase',
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 62,
    width: 62,
  },
  bronze: {
    color: '#a17419',
  },
  silver: {
    color: '#b7b7b7',
  },
  gold: {
    color: '#d5a500',
  },
  platinum: {
    color: '#e5e4e2',
  },
  productName: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '16px',
    width: '50%',
  },
  buyBtnDesign: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#5bed96',
    padding: '4px 10px',
    fontSize: '0.8125rem',
    textTransform: 'capitalize',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));

const ActivePackages = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(GlobalContext);
  const { PackagesData, title, isShowDownload, isShowPointsLeft } = props;
  const classes = useStyles();
  const [errorDownload, setErrorDownload] = useState(false);
  const HandleBuyBtn = () => {
    navigate('/order/create', { replace: true });
  };

  const downloadPackage = (uuid) => {
    setErrorDownload(false);
    const headers = {
      Authorization: `Bearer ${state.user?.accessToken}`,
      'didimo-follow-uri': 'false',
      'access-control-allow-origin': 'https://app.didimo.co',
      'content-type': 'application/json',
    };
    fetch(
      process.env.REACT_APP_API_ROOT +
        'accountproducts/' +
        uuid +
        '/actions/download',
      { headers }
    )
      .then((response) => response.json())

      .then((res) => {
        if (res.uri) {
          window.location.href = res.uri;
        } else {
          setErrorDownload(true);
        }
      })
      .catch((err) => {
        console.log('Error Message', err);
      });

    /*  axios
.get('/accountproducts/'+ uuid + '/actions/download', {
headers: {
Authorization: `Bearer ${state.user?.accessToken}`,
'didimo-follow-uri': 'false',
'access-control-allow-origin': 'https://app.didimo.co',
'content-type': 'application/json',
},
})
.then((res)=>{
window.location.href = res.data.uri;
}).catch((err)=>{
console.log("Error Message",err);
}) */
  };

  return (
    <Card className={classes.root}>
      {isShowDownload ? (
        <CardHeader className={'mainHeaderPackage'} title={title} />
      ) : (
        <CardHeader
          className={'mainHeaderPackage'}
          action={
            <Button
              color="secondary"
              size="small"
              variant="contained"
              className={classes.buyBtnDesign}
              onClick={HandleBuyBtn}
            >
              Buy
            </Button>
          }
          title={title}
        />
      )}
      {PackagesData?.length > 0 ? (
        <>
          <CardContent className={'ActivePackageContent'}>
            <List className={classes.root}>
              {PackagesData?.map((DataPackages) => (
                <ListItem key={v4()} className={'TrailPackage'}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      direction="column"
                      justify="space-between"
                      container
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <Grid container item>
                        <Grid
                          item
                          xs={6}
                          lg={6}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="h5"
                            className={classes.productName}
                          >
                            {DataPackages.product?.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          xs={4}
                          alignContent="left"
                          style={{
                            marginLeft: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '30%',
                            marginTop: '0px',
                          }}
                        >
                          {DataPackages.expires_at ? (
                            <Typography
                              variant="caption"
                              align="center"
                              style={{
                                backgroundColor: 'rgba(42,6,81,0.1)',
                                borderRadius: '15px',
                                padding: '0.3em 0.5em',
                                textAlign: 'center',
                              }}
                            >
                              expires on {formatDate(DataPackages.expires_at)}
                            </Typography>
                          ) : (
                            <Typography variant="subtitle1">N/A</Typography>
                          )}
                        </Grid>

                        {isShowPointsLeft ? (
                          <Grid
                            container
                            item
                            lg={3}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end' }}
                          >
                            <Grid item xs={6} lg={12} container>
                              <Grid
                                item
                                xs={6}
                                lg={12}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  marginLeft: 'auto',
                                  paddingTop: { xs: '8px', lg: '0' },
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  align="right"
                                  style={{ display: 'inline-block' }}
                                >
                                  {formatPoints(DataPackages.balance)}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  align="right"
                                  style={{
                                    display: 'inline-block',
                                    marginLeft: '0.5em',
                                  }}
                                >
                                  points left
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          isShowDownload && (
                            <Grid
                              container
                              item
                              lg={3}
                              xs={2}
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <Grid
                                item
                                xs={6}
                                lg={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}
                              >
                                <Tooltip title="Download">
                                  <IconButton
                                    size="small"
                                    aria-label="download"
                                    onClick={() =>
                                      downloadPackage(DataPackages.uuid)
                                    }
                                  >
                                    <GetAppIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                      <Grid item xs style={{ marginTop: '0.8em' }}>
                        <CurrentLinearProgress
                          value={
                            (DataPackages.balance /
                              DataPackages.initial_value) *
                            100
                          }
                          variant="determinate"
                          style={{ marginBottom: '5px' }}
                          activePackage={DataPackages}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </CardContent>
          {!isShowDownload && (
            <CardActions>
              <Button
                href="/packages"
                size="small"
                color="primary"
                style={{ marginLeft: 'auto', textTransform: 'capitalize' }}
                variant="outlined"
              >
                View all
              </Button>
            </CardActions>
          )}
        </>
      ) : (
        <>
          <CardContent
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '70%',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5">You don't have any {title}</Typography>
          </CardContent>
        </>
      )}
      <ErrorSnack
        open={errorDownload}
        onClose={(e) => {
          setErrorDownload(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        message="Can't download this package"
      />
    </Card>
  );
};

export default ActivePackages;
