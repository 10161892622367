import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  Button,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { GlobalContext } from 'src/context/store';
import './logo.css';
import { Auth } from 'aws-amplify';
import { userLogout, initStatusManager } from 'src/context/actions';
import Logo from './Logo';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import { formatDateTime } from './Helpers/formaters';
import Checkout from '@material-ui/icons/LocalMallOutlined';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);

  const handleChange = () => {
    navigate('order/create');
  };

  const handleNavigator = () => {
    navigate('/notifications');
    setAnchorEl(null);
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handlenotificationOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlenotificationClose = () => {
    setAnchorEl(null);
  };

  const navigateToBar = (notificationId) => {
    navigate(`/notifications#notification=${notificationId}`);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <AppBar elevation={0} {...rest} className={'navBarDashboard'}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/dashboard">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {state.marketPlaceData?.length > 0 && (
          <IconButton
            color="inherit"
            size="large"
            onClick={handleCheckout}
            style={{ marginRight: 7, display: 'block' }}
          >
            <Badge badgeContent={state.cartData?.length} color="success">
              <Checkout variant="outlined" />{' '}
            </Badge>
          </IconButton>
        )}
        <Hidden lgDown>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            style={{
              color: 'rgba(0, 0, 0, 0.87)',
              padding: '4px 10px',
              fontSize: '0.8125rem',
              textTransform: 'capitalize',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
            onClick={handleChange}
          >
            {' '}
            Buy{' '}
          </Button>
          <IconButton
            color="inherit"
            size="large"
            aria-expanded={open ? 'true' : undefined}
            onClick={handlenotificationOpen}
          >
            <Badge
              badgeContent={state.notificationData?.total_size}
              color="success"
            >
              {' '}
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handlenotificationClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Paper>
              <List>
                {(state.notificationData.notifications || [])
                  .filter((notification, index) => index < 5)
                  .map((notification, index) => (
                    <div key={notification}>
                      {index > 0 && <Divider variant="middle" />}
                      <ListItem
                        button
                        component="a"
                        onClick={() => navigateToBar(notification.uuid)}
                      >
                        <ListItemText>
                          <Typography
                            variant="body2"
                            style={{ textAlign: 'center' }}
                            key={notification}
                          >
                            {formatDateTime(notification.created_at)}
                          </Typography>
                          <Typography variant="h5" style={{ marginTop: '1em' }}>
                            {notification.title}
                          </Typography>
                          <Typography variant="body1">
                            <div
                              style={{
                                maxWidth: '350px',
                                maxHeight: '4em',
                                overflow: 'hidden',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: notification.content,
                              }}
                            />
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </div>
                  ))}
              </List>
              <Button
                style={{
                  marginLeft: '10px',
                  margin: '10px',
                  textTransform: 'capitalize',
                  color: 'black',
                }}
                onClick={handleNavigator}
              >
                View all notifications
              </Button>
            </Paper>
          </Popover>
          <IconButton
            onClick={() => {
              Auth.signOut();
              dispatch(initStatusManager([]));
              dispatch(userLogout());
              navigate(`login`, {
                replace: true,
              });
            }}
            olor="inherit"
            size="large"
          >
            <span style={{ fontSize: '14px', color: 'white' }}>Sign Out</span>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
