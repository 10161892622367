// Thanks to https://material-ui.com/components/autocomplete/ for creating exactly the component I needed!
// @see: https://en.wikipedia.org/wiki/ISO_3166-2:US

import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },

  /*select: {
    width: 400
  }*/
});

export default function USStatesField(props) {
  const classes = useStyles(),
    { onBlur, onChange } = props;

  const [stateCode, setstateCode] = useState(0);
  let selectedStateCode = props.value;
  const [stateList, setstateList] = useState(0);

  useEffect(() => {
    try {
      axios
        .get(process.env.REACT_APP_API_ROOT + 'geo/countries/US/subdivisions')
        .then((response) => {
          setstateList(response.data);

          setstateCode(
            response.data.find(
              (country) => country.code === selectedStateCode
            ) || {
              __type: 'CountrySubdivision',
              code: '',
              name: '',
              category: 'state',
            }
          );
        });
    } catch (error) {
      console.log('CountrySubdivisions:', error);
    }
  }, [props.value]);

  return (
    <Autocomplete
      id="state-select"
      options={stateList}
      className={[classes.option, classes.select].join(' ')}
      disabled={props.disabled}
      onChange={onChange}
      onInputChange={(e, value, operation) => {
        if (operation == 'clear') {
          onChange(e, { code: '' });
        }
      }}
      onBlur={onBlur}
      autoHighlight
      required
      getOptionLabel={(option) => option.name}
      /*renderOption={(props, option) => (
          `${option.name} (${option.code})`
      )}*/
      value={stateCode}
      renderInput={(params) => (
        <TextField
          {...params}
          label="State"
          variant="outlined"
          fullWidth
          required={props.required}
          error={props.error}
          helperText={props.helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
