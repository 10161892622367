export const USER_SET_TOKEN = 'USER_SET_TOKEN';
export const SAVE_USER_LOGIN = 'SAVE_USER_LOGIN';
export const INITIAL_STATE = 'INITIAL_STATE';
export const GOTO_USER_AUTHENTICATED_PAGE = 'GOTO_USER_AUTHENTICATED_PAGE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CHANGE_DEVELOPER = 'CHANGE_DEVELOPER';
export const GET_DATA_AFTER_REFRESH = 'GET_DATA_AFTER_REFRESH';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const UPDATE_STATUS_MANAGER = 'UPDATE_STATUS_MANAGER';
export const INIT_STATUS_MANAGER = 'INIT_STATUS_MANAGER';
export const ADD_STATUS_MANAGER = 'ADD_STATUS_MANAGER';
export const ADD_DOWNLOAD_STATUS_MANAGER = 'ADD_DOWNLOAD_STATUS_MANAGER';
export const REMOVE_STATUS_MANAGER = 'REMOVE_STATUS_MANAGER';
export const REMOVE_DOWNLOAD_STATUS_MANAGER = 'REMOVE_DOWNLOAD_STATUS_MANAGER';
export const ADD_TO_CART = 'ADD_TO_CART';
export const MARKET_PLACE = 'MARKET_PLACE';
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
