import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Alert,
} from '@material-ui/core';
import Amplify, { Auth } from 'aws-amplify';
import './layout/Login.css';

const ForgotPassword = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const username = new URLSearchParams(search).get('username');

  async function SubmitPassword(values) {
    if (values) {
      try {
        // const user = await Auth.currentAuthenticatedUser();

        try {
          var raw = JSON.stringify({
            email_address: values.email,
          });

          let myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append('Didimo-Platform', process.env.REACT_APP_PLATFORM);
          myHeaders.append(
            'Didimo-Platform-Version',
            process.env.REACT_APP_VERSION
          );

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
          };

          var createCodeURL =
            process.env.REACT_APP_API_ROOT + 'accounts/resetting-password';

          const res = await fetch(createCodeURL, requestOptions);
          const data = await res.json();

          if (res.status === 200) {
            navigate(
              `${
                process.env.REACT_APP_ROOT_PATH
              }/forgotchange?username=${encodeURIComponent(values.email)}`
            );
          } else {
            setError('An error has occured');
          }
        } catch (error) {
          setError(error);
          console.log('error confirming sign up', error);
        }
      } catch (err) {
        setError(err);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | Didimo</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            })}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Email is Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={SubmitPassword}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    gutterBottom="true"
                  >
                    {isSubmitting}
                    Forgot Password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    gutterBottom="true"
                  >
                    Enter your email below and you will be emailed a
                    verification code.
                  </Typography>
                </Box>

                {error && <Alert severity="error">{error.message}</Alert>}

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />

                <Box sx={{ py: 2 }} className={'SpinnerContainer'}>
                  <Button
                    sx={{ textTransform: 'none' }}
                    className={'signIn-btn'}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
