import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import './GenerateDidimoModal.css';
import DDMElements from '../DDMElements/DDMElements';
import axios from 'axios';
import { styled } from '@material-ui/core/styles';
import Alert from '@mui/material/Alert';

const StyledMenu = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.03) !important',
  },
}));
const GenerateDidimosModel = (props) => {
  const {
    isOpen,
    onCloseModal,
    setPending_orders,
    handleLoadMore,
    versionOptions,
    customerPortal,
  } = props;

  const [version, setVersion] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [dgpVersion, setDgpVersion] = useState('');
  const [dgpVersionUUID, setDgpVersionUUID] = useState('');
  const [componentVersion, setComponentVersion] = useState('');
  const [ddmTemplates, setDDMTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const loading = open && ddmTemplates.length === 0;
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (customerPortal?.versions?.length > 0) {
      let version = process.env.REACT_APP_VERSION
        ? process.env.REACT_APP_VERSION
        : '2.5.0';
      const versionData = customerPortal.versions.find(
        (c) => c.code === version
      );
      let dgpCompatibilityRules = [];
      if (versionData) {
        dgpCompatibilityRules = versionData.dgp_compatibility_rules;
      }
      for (var i = 0; i < dgpCompatibilityRules.length; i++) {
        let regTester = new RegExp(dgpCompatibilityRules[i].pattern);
        let testRegEx = regTester.test(dgpVersion);
        if (testRegEx === true) {
          setComponentVersion(
            dgpCompatibilityRules[i].settings.didimo_generation_component
          );
        }
      }
    }
  }, [customerPortal, dgpVersion]);

  useEffect(() => {
    if (versionOptions.length && !version) {
      for (var i = 0; i < versionOptions.length; i++) {
        if (versionOptions[i].is_default === true) {
          setVersion(versionOptions[i].code);
          setDgpVersion(versionOptions[i].dgp_version);
          setDgpVersionUUID(versionOptions[i].dgp_version_uuid);
        }
      }
    }
  }, [versionOptions]);

  useEffect(() => {
    if (dgpVersionUUID) {
      axios
        .get(
          '/didimo_generation_templates?is_active=true&page_size=100&page=1&dgp_uuid=' +
            dgpVersionUUID
        )
        .then((res) => {
          setDDMTemplates(res.data.didimo_generation_templates);
        });
    }
  }, [dgpVersionUUID]);

  const handleClose = () => {
    setSelectedTemplate('');
    onCloseModal();
  };

  return (
    <div className="generate_ddmo">
      <Dialog
        className="detail-popup new-ddm-model custom-border-model"
        open={isOpen}
        closeAfterTransition
        aria-labelledby="configure-api-key-title"
      >
        <DialogTitle
          id="generate-didimo-title"
          style={{ padding: '0 24px 20px 0', fontSize: '16px' }}
        >
          Generate a new didimo
        </DialogTitle>

        {componentVersion === '' && (
          <div className="loader">
            <CircularProgress color="inherit" />
          </div>
        )}

        {componentVersion !== '' && (
          <>
            <FormControl fullWidth className="select-version-spacing">
              <InputLabel id="select-version-label">
                Select DGP Version
              </InputLabel>
              <Select
                labelId="select-version-label"
                id="version-select"
                value={version}
                label="DGP"
                onChange={(e) => setVersion(e.target.value)}
                disabled={
                  true /*!versionOptions || versionOptions.filter(o => o.dgp_version).length<2*/
                }
              >
                {versionOptions.map((option) => {
                  return option?.dgp_version != undefined ? (
                    <MenuItem value={option.code}>{option.name}</MenuItem>
                  ) : (
                    ''
                  );
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth className="select-version-spacing">
              <InputLabel id="select-template-label">
                Select Template
              </InputLabel>
              <Select
                labelId="select-ddm-template"
                id="ddm-template-select"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                <StyledMenu value={''}>Select Template</StyledMenu>
                {ddmTemplates.map((template) => {
                  return (
                    <StyledMenu value={template.uuid}>
                      {template.template_name}
                    </StyledMenu>
                  );
                })}
              </Select>
            </FormControl>
          </>
        )}
        {disableButton && (
          <Alert
            severity="warning"
            style={{
              border: '1px solid #ff9800',
              backgroundColor: 'white',
              marginBottom: '10px',
            }}
          >
            Your tier does not allow Didimo generation
          </Alert>
        )}

        {componentVersion && (
          <DDMElements
            isDDMGenerate
            onCloseModal={handleClose}
            setPending_orders={setPending_orders}
            handleLoadMore={handleLoadMore}
            selectedTemplate={ddmTemplates.find(
              (f) => f.uuid === selectedTemplate
            )}
            componentVersion={componentVersion}
            disableButton={disableButton}
            setDisableButton={setDisableButton}
          />
        )}
      </Dialog>
    </div>
  );
};

export default GenerateDidimosModel;
