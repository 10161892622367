import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/store';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import DdmJSON from '../didimo-json-stub-version-two.json';
import { dataURLtoFile } from '../../utils/base64ToFile';
import {
  Button,
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import zipImage from '../../assets/Images/Batch_Photos.jpg';
import zip from 'zip-js-esm';
import { fs } from '../../pages/ViewGltf/vendor/zip-fs.js';
import { addStatusManager } from '../../context/actions';
import { useSearchParams } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import './DDMElement.css';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CaptureModal from './CaptureModal';

const errorMsg = 'There was an error when trying to generate the didimo.';
const metadataStructureV1 = {
  attributes: [
    {
      code: 'photo',
      data_type: 'boolean',
      description: 'Photo',
      group: 'input_type',
      name: 'Photo',
      path: '$[?(@property == "input_type" && @ == "photo")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'gltf',
      data_type: 'boolean',
      description: 'GLTF',
      group: 'transfer_formats',
      name: 'glTF',
      path: '$.transfer_formats[?(@.name=="gltf")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'fbx',
      data_type: 'boolean',
      description: 'FBX',
      group: 'transfer_formats',
      name: 'FBX',
      path: '$.transfer_formats[?(@.name=="fbx")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'simple_poses',
      data_type: 'boolean',
      description: 'Simple Poses: blink/expressions',
      group: 'animation_capability',
      name: 'Simple Poses: blink/expressions',
      path: '$.meta_data[?(@.name=="simple_poses" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },
    {
      code: 'arkit_compatible',
      data_type: 'boolean',
      description: 'ARKit compatible',
      group: 'animation_capability',
      name: 'ARKit compatible',
      path: '$.meta_data[?(@.name=="arkit" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },
    {
      code: 'oculus_lipsync_compatible',
      data_type: 'boolean',
      description: 'Oculus Lipsync compatible',
      group: 'animation_capability',
      name: 'Oculus Lipsync compatible',
      path:
        '$.meta_data[?(@.name=="oculus_lipsync" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },
    {
      code: 'animation_polly_compatible',
      data_type: 'boolean',
      description: 'AWS Polly compatible',
      group: 'animation_capability',
      name: 'AWS Polly compatible',
      path: '$.meta_data[?(@.name=="aws_polly" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },
    {
      code: 'texture_size_2k',
      data_type: 'boolean',
      description: '2k',
      group: 'texture_size',
      name: '2k',
      path:
        '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="2048")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'texture_size_1k',
      data_type: 'boolean',
      description: '1k',
      group: 'texture_size',
      name: '1k',
      path:
        '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="1024")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'texture_size_512',
      data_type: 'boolean',
      description: '512',
      group: 'texture_size',
      name: '512',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'head_only',
      data_type: 'boolean',
      description: 'Head Only',
      group: 'avatar_structure',
      name: 'Head Only',
      path: '$.transfer_formats[?(@.name=="head_only")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'full_body',
      data_type: 'boolean',
      description: 'Full Body',
      group: 'avatar_structure',
      name: 'Full Body',
      path: '$.transfer_formats[?(@.name=="full_body")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'garment_none',
      data_type: 'boolean',
      description: 'None',
      group: 'garment',
      name: 'None',
      path: '$.transfer_formats[?(@.name=="garment_none")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'garment_001',
      data_type: 'boolean',
      description: 'Casual',
      group: 'garment',
      name: 'Casual',
      path: '$.transfer_formats[?(@.name=="garment_001")].name',
      __type: 'MetadataAttribute',
    },

    {
      code: 'garment_002',
      data_type: 'boolean',
      description: 'Sporty',
      group: 'garment',
      name: 'Sporty',
      path: '$.transfer_formats[?(@.name=="garment_002")].name',
      __type: 'MetadataAttribute',
    },

    {
      code: 'garment_003',
      data_type: 'boolean',
      description: 'Business',
      group: 'garment',
      name: 'Business',
      path: '$.transfer_formats[?(@.name=="garment_003")].name',
      __type: 'MetadataAttribute',
    },

    {
      code: 'gender_none',
      data_type: 'boolean',
      description: 'None',
      group: 'gender',
      name: 'Auto',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'gender_male',
      data_type: 'boolean',
      description: 'Male',
      group: 'gender',
      name: 'Male',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'gender_female',
      data_type: 'boolean',
      description: 'Female',
      group: 'gender',
      name: 'Female',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },

    {
      code: 'body_pose_a',
      data_type: 'boolean',
      description: 'A',
      group: 'body_pose',
      name: 'A',
      path: '$.meta_data[?(@.name=="profile" && @.value=="Standard")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'body_pose_t',
      data_type: 'boolean',
      description: 'T',
      group: 'body_pose',
      name: 'T',
      path: '$.meta_data[?(@.name=="profile" && @.value=="Standard")]',
      __type: 'MetadataAttribute',
    },
  ],
  groups: [
    {
      code: 'input_type',
      name: 'Input type',
      __type: 'MetadataGroup',
    },
    {
      code: 'transfer_formats',
      name: 'Transfer formats',
      __type: 'MetadataGroup',
    },
    {
      code: 'animation_capability',
      name: 'Animation capability',
      __type: 'MetadataGroup',
    },
    {
      code: 'texture_size',
      name: 'Max texture dimension *',
      __type: 'MetadataGroup',
    },
    {
      code: 'avatar_structure',
      name: 'Avatar Structure',
      __type: 'MetadataGroup',
    },
    {
      code: 'body_pose',
      name: 'Body Pose ',
      __type: 'MetadataGroup',
    },
    {
      code: 'garment',
      name: 'Garment',
      __type: 'MetadataGroup',
    },
    {
      code: 'gender',
      name: 'Gender',
      __type: 'MetadataGroup',
    },
  ],
  __type: 'MetadataConfig',
};

const metadataStructureV2 = {
  attributes: [
    {
      code: 'photo',
      data_type: 'boolean',
      description: 'Photo',
      group: 'input_type',
      name: 'Photo',
      path: '$[?(@property == "input_type" && @ == "photo")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'rgbd',
      data_type: 'boolean',
      description: 'Photo',
      group: 'input_type',
      name: 'RGB-D',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_001',
      data_type: 'select',
      description: 'Hair 001',
      group: 'baseball',
      name: 'Hair 001',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_002',
      data_type: 'select',
      description: 'Hair 002',
      group: 'baseball',
      name: 'Hair 002',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_003',
      data_type: 'select',
      description: 'Hair 003',
      group: 'baseball',
      name: 'Hair 003',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_004',
      data_type: 'select',
      description: 'Hair 004',
      group: 'baseball',
      name: 'Hair 004',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_005',
      data_type: 'select',
      description: 'Hair 005',
      group: 'baseball',
      name: 'Hair 005',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_006',
      data_type: 'select',
      description: 'Hair 006',
      group: 'baseball',
      name: 'Hair 006',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_007',
      data_type: 'select',
      description: 'Hair 007',
      group: 'baseball',
      name: 'Hair 007',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_008',
      data_type: 'select',
      description: 'Hair 008',
      group: 'baseball',
      name: 'Hair 008',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_009',
      data_type: 'select',
      description: 'Hair 009',
      group: 'baseball',
      name: 'Hair 009',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_010',
      data_type: 'select',
      description: 'Hair 010',
      group: 'baseball',
      name: 'Hair 010',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'hair_011',
      data_type: 'select',
      description: 'Hair 011',
      group: 'baseball',
      name: 'Hair 011',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'baseball_cap',
      data_type: 'select',
      description: 'Baseball Cap',
      group: 'baseball',
      name: 'Baseball Cap',
      path: '$[?(@property == "input_type" && @ == "rgbd")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'apple',
      data_type: 'boolean',
      description: 'Apple',
      group: 'depth',
      name: 'Apple',
      path: '$[?(@property == "input_type" && @ == "apple")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'gltf',
      data_type: 'boolean',
      description: 'glTF',
      group: 'transfer_formats',
      name: 'glTF',
      path: '$.transfer_formats[?(@.name=="gltf")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'fbx',
      data_type: 'boolean',
      description: 'FBX',
      group: 'transfer_formats',
      name: 'FBX',
      path: '$.transfer_formats[?(@.name=="fbx")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'simple_poses',
      data_type: 'boolean',
      description: 'Simple Poses: blink/expressions',
      group: 'animation_capability',
      name: 'Simple Poses: blink/expressions',
      path: '$.meta_data[?(@.name=="simple_poses" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },
    {
      code: 'arkit_compatible',
      data_type: 'boolean',
      description: 'ARKit compatible',
      group: 'animation_capability',
      name: 'ARKit compatible',
      path: '$.meta_data[?(@.name=="arkit" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },
    {
      code: 'oculus_lipsync_compatible',
      data_type: 'boolean',
      description: 'Oculus Lipsync compatible',
      group: 'animation_capability',
      name: 'Oculus Lipsync compatible',
      path:
        '$.meta_data[?(@.name=="oculus_lipsync" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },
    {
      code: 'animation_polly_compatible',
      data_type: 'boolean',
      description: 'AWS Polly compatible',
      group: 'animation_capability',
      name: 'AWS Polly compatible',
      path: '$.meta_data[?(@.name=="aws_polly" && @.value!="false")].value',
      __type: 'MetadataAttribute',
    },

    {
      code: 'head_only',
      data_type: 'boolean',
      description: 'Head Only',
      group: 'avatar_structure',
      name: 'Head Only',
      path: '$.transfer_formats[?(@.name=="head_only")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'full_body',
      data_type: 'boolean',
      description: 'Full Body',
      group: 'avatar_structure',
      name: 'Full Body',
      path: '$.transfer_formats[?(@.name=="full_body")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'garment_none',
      data_type: 'boolean',
      description: 'None',
      group: 'garment',
      name: 'None',
      path: '$.transfer_formats[?(@.name=="garment_none")].name',
      __type: 'MetadataAttribute',
    },
    {
      code: 'garment_001',
      data_type: 'boolean',
      description: 'Casual',
      group: 'garment',
      name: 'Casual',
      path: '$.transfer_formats[?(@.name=="garment_001")].name',
      __type: 'MetadataAttribute',
    },

    {
      code: 'garment_002',
      data_type: 'boolean',
      description: 'Sporty',
      group: 'garment',
      name: 'Sporty',
      path: '$.transfer_formats[?(@.name=="garment_002")].name',
      __type: 'MetadataAttribute',
    },

    {
      code: 'garment_003',
      data_type: 'boolean',
      description: 'Business',
      group: 'garment',
      name: 'Business',
      path: '$.transfer_formats[?(@.name=="garment_003")].name',
      __type: 'MetadataAttribute',
    },

    {
      code: 'gender_none',
      data_type: 'boolean',
      description: 'None',
      group: 'gender',
      name: 'Auto',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'gender_male',
      data_type: 'boolean',
      description: 'Male',
      group: 'gender',
      name: 'Male',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'gender_female',
      data_type: 'boolean',
      description: 'Female',
      group: 'gender',
      name: 'Female',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'remove_beard_true',
      data_type: 'boolean',
      description: 'Remove Beard (experimental)',
      group: 'remove_beard',
      name: 'Remove Beard (experimental)',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'profile_standard',
      data_type: 'boolean',
      description: 'Standard',
      group: 'profile',
      name: 'Standard',
      path: '$.meta_data[?(@.name=="profile" && @.value=="Standard")]',
      __type: 'MetadataAttribute',
    },

    {
      code: 'profile_optimized',
      data_type: 'boolean',
      description: 'Optimized',
      group: 'profile',
      name: 'Optimized',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'profile_minimal',
      data_type: 'boolean',
      description: 'Minimal',
      group: 'profile',
      name: 'Minimal',
      path: '$.meta_data[?(@.name=="max_texture_dimension" && @.value=="512")]',
      __type: 'MetadataAttribute',
    },

    {
      code: 'body_pose_a',
      data_type: 'boolean',
      description: 'A',
      group: 'body_pose',
      name: 'A',
      path: '$.meta_data[?(@.name=="profile" && @.value=="Standard")]',
      __type: 'MetadataAttribute',
    },
    {
      code: 'body_pose_t',
      data_type: 'boolean',
      description: 'T',
      group: 'body_pose',
      name: 'T',
      path: '$.meta_data[?(@.name=="profile" && @.value=="Standard")]',
      __type: 'MetadataAttribute',
    },
  ],
  groups: [
    {
      code: 'input_type',
      name: 'Input type',
      __type: 'MetadataGroup',
    },
    {
      code: 'transfer_formats',
      name: 'Transfer formats',
      __type: 'MetadataGroup',
    },
    {
      code: 'baseball',
      name: 'Hair',
      __type: 'MetadataGroup',
    },
    {
      code: 'avatar_structure',
      name: 'Avatar Structure',
      __type: 'MetadataGroup',
    },
    {
      code: 'body_pose',
      name: 'Body Pose ',
      __type: 'MetadataGroup',
    },
    {
      code: 'garment',
      name: 'Garment',
      __type: 'MetadataGroup',
    },
    {
      code: 'gender',
      name: 'Gender',
      __type: 'MetadataGroup',
    },
    {
      code: 'animation_capability',
      name: 'Animation capability',
      __type: 'MetadataGroup',
    },

    {
      code: 'profile',
      name: 'Profile ',
      __type: 'MetadataGroup',
    },

    {
      code: 'remove_beard',
      name: 'Options',
      __type: 'MetadataGroup',
    },
  ],
  __type: 'MetadataConfig',
};

const uploadFile = (
  file,
  accessToken,
  selecteditemsCode,
  tiercode,
  fileDepth,
  selectedTemplate
) => {
  let formData = new FormData();
  let isHeadOnly = false;
  let postURL = 'didimos';

  for (let i = 0; i < selecteditemsCode.length; i++) {
    if (selecteditemsCode[i] === 'head_only') {
      isHeadOnly = true;
    }
  }

  for (let i = 0; i < selecteditemsCode.length; i++) {
    switch (selecteditemsCode[i]) {
      case 'photo':
        if (file.name.indexOf('.zip') > 0) {
          formData.append('photos', file);
          formData.append('input_type', 'photo');
          postURL = 'didimos/bulks';
        } else {
          if (file.preview.indexOf('data:image/jpeg;base64,') > -1) {
            file = dataURLtoFile(file.preview, file.name);
          }

          formData.append('photo', file);
          formData.append('input_type', 'photo');
        }

        break;
      case 'profile_optimized':
        formData.append('profile', 'optimized');
        break;
      case 'profile_minimal':
        formData.append('profile', 'minimal');
        break;
      case 'profile_standard':
        formData.append('profile', 'standard');
        break;
      case 'rgbd':
        formData.append('photo', file);
        formData.append('depth', fileDepth);
        formData.append('input_type', 'rgbd');
        break;
      case 'undefined':
        // formData.append('depth_encoding', 'none');
        break;
      case 'apple':
        //formData.append('depth_encoding', 'apple');
        break;
      case 'fbx':
        formData.append('transfer_formats', 'fbx');
        break;
      case 'gltf':
        formData.append('transfer_formats', 'gltf');
        break;
      case 'oculus_lipsync_compatible':
        formData.append('oculus_lipsync', 'true');
        break;
      case 'animation_polly_compatible':
        formData.append('aws_polly', 'true');
        break;
      case 'arkit_compatible':
        formData.append('arkit', 'true');
        break;
      case 'simple_poses':
        formData.append('simple_poses', 'true');
        break;
      case 'full_body':
        formData.append('avatar_structure', 'full-body');
        break;
      case 'head_only':
        formData.append('avatar_structure', 'head-only');
        break;
      case 'body_pose_a':
        if (isHeadOnly === false) {
          formData.append('body_pose', 'A');
        }
        break;
      case 'body_pose_t':
        if (isHeadOnly === false) {
          formData.append('body_pose', 'T');
        }
        break;
      case 'gender_male':
        if (isHeadOnly === false) {
          formData.append('gender', 'male');
        }
        break;
      case 'gender_female':
        if (isHeadOnly === false) {
          formData.append('gender', 'female');
        }
        break;
      case 'garment_none':
        if (isHeadOnly === false) {
          formData.append('garment', 'none');
        }
        break;
      case 'garment_001':
        if (isHeadOnly === false) {
          formData.append('garment', 'casual');
        }
        break;
      case 'garment_002':
        if (isHeadOnly === false) {
          formData.append('garment', 'sporty');
        }
        break;
      case 'garment_003':
        if (isHeadOnly === false) {
          formData.append('garment', 'business');
        }
        break;
      case 'remove_beard_true':
        formData.append('remove_beard', 'true');
        break;
      case 'remove_beard_false':
        formData.append('remove_beard', 'false');
        break;
      case 'texture_size_2k':
        if (
          DdmJSON.objects['texture_size_2k'].tier_level.includes(tiercode.code)
        ) {
          formData.append('max_texture_dimension', '2048');
        }
        break;
      case 'texture_size_1k':
        if (
          DdmJSON.objects['texture_size_1k'].tier_level.includes(tiercode.code)
        ) {
          formData.append('max_texture_dimension', '1024');
        }
        break;
      case 'texture_size_512':
        if (
          DdmJSON.objects['texture_size_512'].tier_level.includes(tiercode.code)
        ) {
          formData.append('max_texture_dimension', '512');
        }
        break;
      case 'baseball_cap':
        formData.append('hair', 'baseball_cap');
        break;
      case 'hair_001':
        formData.append('hair', 'hair_001');
        break;
      case 'hair_002':
        formData.append('hair', 'hair_002');
        break;
      case 'hair_003':
        formData.append('hair', 'hair_003');
        break;
      case 'hair_004':
        formData.append('hair', 'hair_004');
        break;
      case 'hair_005':
        formData.append('hair', 'hair_005');
        break;
      case 'hair_006':
        formData.append('hair', 'hair_006');
        break;
      case 'hair_007':
        formData.append('hair', 'hair_007');
        break;
      case 'hair_008':
        formData.append('hair', 'hair_008');
        break;
      case 'hair_009':
        formData.append('hair', 'hair_009');
        break;
      case 'hair_010':
        formData.append('hair', 'hair_010');
        break;
      case 'hair_011':
        formData.append('hair', 'hair_011');
        break;
    }
  }
  if (selectedTemplate) {
    formData.append('generation_template', selectedTemplate.uuid);
  }
  return axios.post(postURL, formData).then((response) => {
    if (response) {
      return response;
    } else {
      alert(errorMsg);
    }
  });
};

const DDMElements = (props) => {
  const {
    onCloseModal,
    setPending_orders,
    handleLoadMore,
    selectedTemplate,
    isDDMGenerate,
    componentVersion,
    name,
    desc,
    codeName,
    showTemplates,
    editData,
    templateVersion,
    versionUuid,
    disableButton,
    setDisableButton,
  } = props;
  const [files, setFiles] = useState({});
  const [fileDepth, setFileDepth] = useState([]);

  const [isDragOver, setIsDragOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [isValidateItems, setisValidateItems] = useState(false);
  const [tier, setTier] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [points, setPoints] = useState(0);

  const [displayPoints, setDisplayPoints] = useState(0);

  const [disableCheckbox, setDisableCheckbox] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [processingError, setProcessingError] = useState();
  const [processingErrorMsg, setProcessingErrorMsg] = useState(errorMsg);
  const [accountbalance, setAccountbalance] = useState();
  const { state, dispatch } = useContext(GlobalContext);
  const [zipCount, setZipCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [modelType, setModelType] = useState(1);

  const [dropBox1Text, setDropBox1Text] = useState(
    'Drop or click to select a photo file up to 16MP or add a Zip file'
  );

  const [selectedHair, setSelectedHair] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [src, setSrc] = useState(null);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      Width: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  function _loadZip(file, setGltfObjectMap) {
    var traverse;
    const pending = [];
    const fileMap = new Map();
    const archive = new fs.FS();

    let fileCount = 0;

    if (file?.name?.indexOf('.zip') === -1) {
      setZipCount(0);
      setDisplayPoints(points);
    } else {
      let metadata = {
        type: 'zip',
      };
      let file2 = new File([file], file.name, metadata);

      traverse = (node) => {
        if (node?.directory) {
          if (node?.name != '__MACOSX') {
            node.children.forEach(traverse);
          }
        } else if (node?.data?.name !== '.') {
          fileCount++;
        }
      };

      archive.importBlob(file2, () => {
        if (archive.root.directory) {
          archive.root.children.forEach(traverse);

          if (fileCount > 0) {
            setZipCount(fileCount);
            setDisplayPoints(points * fileCount);
          }
        }
      });
    }
  }

  const onDragEnter = useCallback(() => {
    setIsDragOver(true);
  });
  const onDragLeave = useCallback(() => {
    setIsDragOver(false);
  });

  const handleChangefile = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(files);
    //setIsOpen(true)
    // setModelType(2);
  };

  const {
    getRootProps: getRootProps,
    getInputProps: getInputProps,
  } = useDropzone({
    onDrop: (acceptedFile) => {
      handleChangefile(acceptedFile[0]);
      setFiles(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
      _loadZip(acceptedFile[0], '');
    },
    onDragEnter,
    onDragLeave,
  });

  useEffect(() => {
    validateGenerateButton(selectedCodes);
  }, [files, fileDepth]);

  const {
    getRootProps: getRootDropProps,
    getInputProps: getInputDropProps,
  } = useDropzone({
    onDrop: (acceptedFile) => {
      setIsOpen(true);
      setFileDepth(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
    },
    onDragEnter,
    onDragLeave,
  });

  useEffect(() => {
    validateGenerateButton(selectedCodes);
  }, [fileDepth]);

  useEffect(() => {
    let accessToken = state.user.accessToken;
    axios
      .get(`accounts/default/status`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        const response = res.data;
        setAccountbalance(response.balance);
        if (isDDMGenerate) {
          setPending_orders(response['pending_orders']?.length);
        }
        setTier(response.tier);
      });
    if (isDDMGenerate || editData) {
      setDefaultSelected();
    }
  }, []);

  useEffect(() => {
    if (isDDMGenerate) {
      setDisableButton(false);
      setDefaultSelected();
    }
  }, [selectedTemplate]);

  const getMetaDataVersion = () => {
    return componentVersion === 'Version1'
      ? metadataStructureV1
      : metadataStructureV2;
  };

  const setDefaultSelected = () => {
    let metadataStructure = getMetaDataVersion();
    setSelectedHair('');
    let allSelectedCodes = [];
    if (selectedTemplate || editData) {
      const settings = editData
        ? JSON.parse(editData.settings)
        : JSON.parse(selectedTemplate.settings);
      if (settings.input_type === 'photo') {
        allSelectedCodes.push('photo');
      }
      if (settings.input_type === 'rgbd') {
        allSelectedCodes.push('rgbd');
      }
      if (editData) {
        if (settings.transfer_formats) {
          if (settings.transfer_formats.includes('gltf')) {
            allSelectedCodes.push('gltf');
          }
          if (settings.transfer_formats.includes('fbx')) {
            allSelectedCodes.push('fbx');
          }
        }
      } else {
        if (settings.transfer_formats === 'gltf') {
          allSelectedCodes.push('gltf');
        }
        if (settings.transfer_formats === 'fbx') {
          allSelectedCodes.push('fbx');
        }
      }
      if (settings.hair === 'hair_001') {
        allSelectedCodes.push('hair_001');
      }
      if (settings.hair === 'hair_002') {
        allSelectedCodes.push('hair_002');
      }
      if (settings.hair === 'hair_003') {
        allSelectedCodes.push('hair_003');
      }
      if (settings.hair === 'hair_004') {
        allSelectedCodes.push('hair_004');
      }
      if (settings.hair === 'hair_005') {
        allSelectedCodes.push('hair_005');
      }
      if (settings.hair === 'hair_006') {
        allSelectedCodes.push('hair_006');
      }
      if (settings.hair === 'hair_007') {
        allSelectedCodes.push('hair_007');
      }
      if (settings.hair === 'hair_008') {
        allSelectedCodes.push('hair_008');
      }
      if (settings.hair === 'hair_009') {
        allSelectedCodes.push('hair_009');
      }
      if (settings.hair === 'hair_010') {
        allSelectedCodes.push('hair_010');
      }
      if (settings.hair === 'hair_011') {
        allSelectedCodes.push('hair_011');
      }
      if (settings.avatar_structure === 'head-only') {
        allSelectedCodes.push('head_only');
      }
      if (settings.avatar_structure === 'full-body') {
        allSelectedCodes.push('full_body');
      }
      if (settings.body_pose === 'A') {
        allSelectedCodes.push('body_pose_a');
      }
      if (settings.body_pose === 'T') {
        allSelectedCodes.push('body_pose_t');
      }
      if (settings.garment === 'none') {
        allSelectedCodes.push('garment_none');
      }
      if (settings.garment === 'casual') {
        allSelectedCodes.push('garment_001');
      }
      if (settings.garment === 'sporty') {
        allSelectedCodes.push('garment_002');
      }
      if (settings.garment === 'business') {
        allSelectedCodes.push('garment_003');
      }
      if (settings.gender === 'auto') {
        allSelectedCodes.push('gender_none');
      }
      if (settings.gender === 'male') {
        allSelectedCodes.push('gender_male');
      }
      if (settings.gender === 'female') {
        allSelectedCodes.push('gender_female');
      }
      if (settings.simple_poses === 'true') {
        allSelectedCodes.push('simple_poses');
      }
      if (settings.arkit === 'true') {
        allSelectedCodes.push('arkit_compatible');
      }
      if (settings.oculus_lipsync === 'true') {
        allSelectedCodes.push('oculus_lipsync_compatible');
      }
      if (settings.aws_polly === 'true') {
        allSelectedCodes.push('animation_polly_compatible');
      }
      if (settings.profile === 'standard') {
        allSelectedCodes.push('profile_standard');
      }
      if (settings.profile === 'optimized') {
        allSelectedCodes.push('profile_optimized');
      }
      if (settings.profile === 'minimal') {
        allSelectedCodes.push('profile_minimal');
      }
      if (settings.max_texture_dimension === '512') {
        allSelectedCodes.push('texture_size_512');
      }
      if (settings.max_texture_dimension === '1024') {
        allSelectedCodes.push('texture_size_1k');
      }
      if (settings.max_texture_dimension === '2048') {
        allSelectedCodes.push('texture_size_2k');
      }
      if (settings.remove_beard === 'true') {
        allSelectedCodes.push('remove_beard_true');
      }
      if (settings.hair) {
        setSelectedHair(settings.hair);
      }
    }

    if (editData) {
      setSelectedCodes(allSelectedCodes);
    } else {
      if (
        !allSelectedCodes.includes('gltf') &&
        !allSelectedCodes.includes('fbx')
      ) {
        allSelectedCodes.push('gltf');
      }

      if (
        !allSelectedCodes.includes('garment_001') &&
        !allSelectedCodes.includes('garment_002') &&
        !allSelectedCodes.includes('garment_003') &&
        !allSelectedCodes.includes('garment_004') &&
        !allSelectedCodes.includes('garment_none')
      ) {
        allSelectedCodes.push('garment_001');
      }

      if (
        !allSelectedCodes.includes('profile_standard') &&
        !allSelectedCodes.includes('profile_optimized') &&
        !allSelectedCodes.includes('profile_minimal')
      ) {
        allSelectedCodes.push('profile_standard');
      }
      if (
        !allSelectedCodes.includes('body_pose_a') &&
        !allSelectedCodes.includes('body_pose_t')
      ) {
        allSelectedCodes.push('body_pose_a');
      }

      for (let i = 0; i < metadataStructure.attributes.length; i++) {
        const rule =
          DdmJSON.groups[
            DdmJSON.objects[metadataStructure.attributes[i].code].group
          ].rule;

        if (rule === 'one') {
          const allGroupAttributes = metadataStructure.attributes
            .filter((a) => a.group === metadataStructure.attributes[i].group)
            .map((a) => a.code);
          // If found already selected code of that group
          if (
            !allGroupAttributes.some((r) => allSelectedCodes.indexOf(r) >= 0)
          ) {
            allSelectedCodes = [
              ...allSelectedCodes,
              metadataStructure.attributes[i].code,
            ];
          }
        }
      }

      let allPoints = 0;
      for (let i = 0; i < allSelectedCodes.length; i++) {
        allPoints += getPoints(allSelectedCodes[i]);
      }
      setPoints(allPoints);
      setDisplayPoints(zipCount > 0 ? allPoints * zipCount : allPoints);
      setSelectedCodes(allSelectedCodes);
      if (disableCheckbox) {
        if (allSelectedCodes.some((f) => disableCheckbox.includes(f))) {
          setDisableButton(true);
        }
      }
    }
  };

  const thumb = files?.preview ? (
    files.name?.indexOf('.zip') > 0 ? (
      <img src={zipImage} className="" />
    ) : (
      <img src={files.preview} className="" />
    )
  ) : (
    ''
  );
  const thumb2 = fileDepth.preview ? (
    <img src={fileDepth.preview} className="" />
  ) : (
    ''
  );

  const handleUpload = () => {
    setIsUploading(true);
    uploadFile(
      files,
      state.user.accessToken,
      selectedCodes,
      tier,
      fileDepth,
      selectedTemplate
    )
      .then(() => {
        let orderBy = searchParams.get('sort_by')
          ? searchParams.get('sort_by')
          : '-created_at';
        axios
          .get(
            `/didimos?order_by=${orderBy}&status=pending&status=processing&page_size=40`
          )
          .then((res) => {
            if (files.name.indexOf('.zip') > 0) {
              setTimeout(function () {
                axios
                  .get(
                    `/didimos?order_by=${orderBy}&status=pending&status=processing&page_size=40`
                  )
                  .then((response) => {
                    dispatch(addStatusManager(response.data));
                  });
              }, 7000);
            } else {
              dispatch(addStatusManager(res.data));
            }
          })
          .catch();
        handleClose();

        if (isDDMGenerate) {
          handleLoadMore();
          setProcessingError(false);
        }
      })
      .catch((err) => {
        const newErrorMessage = err?.response?.data?.description
          ? err?.response?.data?.description
          : errorMsg;
        setProcessingErrorMsg(newErrorMessage);
        setIsUploading(false);
        setProcessingError(true);
      });
  };

  const handleSaveTemplateData = (input, init) => {
    let isHeadOnly = false;
    setErrorMessage('');

    for (let i = 0; i < selectedCodes.length; i++) {
      if (selectedCodes[i] === 'head_only') {
        isHeadOnly = true;
      }
    }

    let settings = {};
    for (let i = 0; i < selectedCodes.length; i++) {
      switch (selectedCodes[i]) {
        case 'photo':
          settings['input_type'] = 'photo';
          break;
        case 'rgbd':
          settings['input_type'] = 'rgbd';
          break;
        case 'gltf':
          if (settings['transfer_formats'] !== undefined) {
            settings['transfer_formats'] = [
              ...settings['transfer_formats'],
              'gltf',
            ];
          } else {
            settings['transfer_formats'] = ['gltf'];
          }
          break;
        case 'fbx':
          if (settings['transfer_formats'] !== undefined) {
            settings['transfer_formats'] = [
              ...settings['transfer_formats'],
              'fbx',
            ];
          } else {
            settings['transfer_formats'] = ['fbx'];
          }
          break;
        case 'head_only':
          settings['avatar_structure'] = 'head-only';
          break;
        case 'full_body':
          settings['avatar_structure'] = 'full-body';
          break;
        case 'body_pose_a':
          if (isHeadOnly === false) {
            settings['body_pose'] = 'A';
          }
          break;
        case 'body_pose_t':
          if (isHeadOnly === false) {
            settings['body_pose'] = 'T';
          }
          break;
        case 'garment_001':
          if (isHeadOnly === false) {
            settings['garment'] = 'casual';
          }
          break;
        case 'garment_002':
          if (isHeadOnly === false) {
            settings['garment'] = 'sporty';
          }
          break;
        case 'garment_003':
          if (isHeadOnly === false) {
            settings['garment'] = 'business';
          }
          break;
        case 'gender_none':
          if (isHeadOnly === false) {
            settings['gender'] = 'auto';
          }
          break;
        case 'gender_male':
          if (isHeadOnly === false) {
            settings['gender'] = 'male';
          }
          break;
        case 'gender_female':
          if (isHeadOnly === false) {
            settings['gender'] = 'female';
          }
          break;
        case 'garment_none':
          if (isHeadOnly === false) {
            settings['garment'] = 'none';
          }
          break;
        case 'oculus_lipsync_compatible':
          settings['oculus_lipsync'] = 'true';
          break;
        case 'animation_polly_compatible':
          settings['aws_polly'] = 'true';
          break;
        case 'arkit_compatible':
          settings['arkit'] = 'true';
          break;
        case 'simple_poses':
          settings['simple_poses'] = 'true';
          break;
        case 'profile_optimized':
          settings['profile'] = 'optimized';
          break;
        case 'profile_standard':
          settings['profile'] = 'standard';
          break;
        case 'profile_minimal':
          settings['profile'] = 'minimal';
          break;
        case 'remove_beard_true':
          settings['remove_beard'] = 'true';
          break;
        case 'baseball_cap':
          settings['hair'] = 'baseball_cap';
          break;
        case 'hair_001':
          settings['hair'] = 'hair_001';
          break;
        case 'hair_002':
          settings['hair'] = 'hair_002';
          break;
        case 'hair_003':
          settings['hair'] = 'hair_003';
          break;
        case 'hair_004':
          settings['hair'] = 'hair_004';
          break;
        case 'hair_005':
          settings['hair'] = 'hair_005';
          break;
        case 'hair_006':
          settings['hair'] = 'hair_006';
          break;
        case 'hair_007':
          settings['hair'] = 'hair_007';
          break;
        case 'hair_008':
          settings['hair'] = 'hair_008';
          break;
        case 'hair_009':
          settings['hair'] = 'hair_009';
          break;
        case 'hair_010':
          settings['hair'] = 'hair_010';
          break;
        case 'hair_011':
          settings['hair'] = 'hair_011';
          break;
        case 'texture_size_2k':
          settings['max_texture_dimension'] = '2048';
          break;
        case 'texture_size_1k':
          settings['max_texture_dimension'] = '1024';
          break;
        case 'texture_size_512':
          settings['max_texture_dimension'] = '512';
          break;
      }
    }

    let data = {
      template_name: name,
      description: desc,
      settings: settings,
      // pipeline_versions: [templateVersion],
    };

    if (!editData) {
      data['template_codename'] = codeName;
      data['scope'] = 'user';

      axios
        .post(`/didimo_generation_templates`, data, {
          headers: {
            'Didimo-DGP': `${versionUuid}`,
          },
        })
        .then((response) => {
          showTemplates();
          setCodeError(true);
          handleClose();
          setErrorMessage('');
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
          setCodeError(true);
        });
    } else {
      axios
        .put(`didimo_generation_templates/${editData.uuid}`, data)
        .then((response) => {
          if (response) {
            showTemplates();
          }
        })
        .catch(() => {});
      handleClose();
    }
  };

  const handleClose = () => {
    setProcessingError(false);
    setIsUploading(false);
    setFiles([]);
    onCloseModal();
  };

  const getPoints = (code) => {
    if (DdmJSON.objects[code]) {
      return DdmJSON.objects[code].points;
    }
    return 0;
  };

  useEffect(() => {
    if (!editData && !isDDMGenerate) {
      setSelectedCodes([]);
      elements;
    }
  }, [componentVersion]);

  const validateGenerateButton = (mySelection) => {
    let isFormatOK = 0;
    let isFileOK = 0;
    let isAvatarStructureOK = 0;

    let selected =
      mySelection.indexOf('fbx') >= 0 || mySelection.indexOf('gltf') >= 0;
    if (selected) {
      isFormatOK = 1;
    }

    selected =
      mySelection.indexOf('head_only') >= 0 ||
      mySelection.indexOf('full_body') >= 0;
    if (selected) {
      isAvatarStructureOK = 1;
    }

    if (files?.name != null) {
      isFileOK = 1;
    } else {
      isFileOK = -1;
    }

    let myIdx = mySelection.indexOf('rgbd');
    if (myIdx >= 0) {
      if (fileDepth?.name != null) {
        isFileOK += 1;
      } else {
        isFileOK -= 1;
      }
    }

    if (isFormatOK > 0 && isFileOK > 0) {
      if (componentVersion === 'Version2') {
        setisValidateItems(false); //enable
      } else {
        setisValidateItems(false); //enable
      }
    } else {
      setisValidateItems(true); //disable
    }
  };

  const checkGroupElementSelected = (element, rule) => {
    const getAllCodesOfSelectedGroup = getMetaDataVersion()
      .attributes.filter((f) => f.group === element.group)
      .map((c) => c.code);
    const common = selectedCodes.filter((value) =>
      getAllCodesOfSelectedGroup.includes(value)
    );
    if (rule === 'one') {
      return common.length < 1;
    } else if (rule === 'one-or-more') {
      return common.length > 1;
    } else if (rule === 'zero-or-one' || rule === 'any') {
      return true;
    }
  };

  const handleCheckboxChange = (event, element) => {
    let allSelectedCodes = selectedCodes;
    let rule = DdmJSON.groups[DdmJSON.objects[element.code].group].rule;
    const allGroupAttributes = getMetaDataVersion()
      .attributes.filter((a) => a.group === element.group)
      .map((a) => a.code);

    if (!isDDMGenerate) {
      if (
        element.group === 'input_type' ||
        element.group === 'profile' ||
        element.group === 'remove_beard' ||
        element.group === 'avatar_structure' ||
        element.group === 'body_pose' ||
        element.group === 'garment' ||
        element.group === 'gender'
      ) {
        rule = 'zero-or-one';
      } else if (
        element.group === 'transfer_formats' ||
        element.group === 'animation_capability'
      ) {
        rule = 'any';
      }
    }
    let allPoints = 0;
    if (rule === 'one' || rule === 'zero-or-one') {
      // If found already selected code of that group
      if (allGroupAttributes.some((r) => selectedCodes.indexOf(r) >= 0)) {
        allSelectedCodes = selectedCodes.filter(
          (c) => !allGroupAttributes.includes(c)
        );
      }
    }

    if (event.target.checked) {
      setSelectedCodes([...allSelectedCodes, element.code]);
      allSelectedCodes.push(element.code);

      for (let i = 0; i < allSelectedCodes.length; i++) {
        allPoints = allPoints + getPoints(allSelectedCodes[i]);
      }

      //* zipCount

      setPoints(allPoints);
      setDisplayPoints(zipCount > 0 ? allPoints * zipCount : allPoints);
      validateGenerateButton(allSelectedCodes);
    } else {
      if (checkGroupElementSelected(element, rule)) {
        const poientselected = DdmJSON.objects[element.code].points;
        const newSelectedCodes = allSelectedCodes.filter(
          (f) => f !== element.code
        );
        setSelectedCodes(newSelectedCodes);
        setisValidateItems(true); //disable
        const mySelectedCodes = newSelectedCodes;
        validateGenerateButton(mySelectedCodes);

        let myPoints = points - poientselected;

        setPoints(myPoints);
        setDisplayPoints(zipCount > 0 ? myPoints * zipCount : myPoints);
      }
    }
  };

  useEffect(() => {
    if (selectedCodes.includes('rgbd')) {
      setDropBox1Text('Drop or click to select a photo file up to 16MP');
    } else {
      //setZipCount(0);
      setDropBox1Text(
        'Drop or click to select a photo file up to 16MP or add a Zip file'
      );
    }
  }, [selectedCodes]);

  const handleSelectChange = (value) => {
    const newSelectedCode = selectedCodes.filter((f) => f !== selectedHair);
    setSelectedCodes([...newSelectedCode, value]);
    setSelectedHair(value);
  };

  const showUpgrade = (code) => {
    let chk = disableCheckbox;
    switch (code) {
      case 'arkit_compatible':
      case 'oculus_lipsync_compatible':
        if (
          tier?.name !== 'Advanced' &&
          tier?.name !== 'Enterprise' &&
          tier?.name !== undefined
        ) {
          const check = chk.some((item) => code === item);
          if (!check) {
            if (chk.length > 0) {
              setDisableCheckbox([...chk, code]);
            } else {
              setDisableCheckbox([code]);
            }
          }
        }
        return true;
      default:
        return false;
    }
  };

  const isDisabled = (code) => {
    if (DdmJSON.objects[code]?.exclude || DdmJSON.objects[code]?.tier_level) {
      if (DdmJSON.objects[code].exclude) {
        if (
          DdmJSON.objects[code].exclude.some(
            (r) => selectedCodes.indexOf(r) >= 0
          )
        ) {
          return true;
        } else if (DdmJSON.objects[code].tier_level) {
          return !DdmJSON.objects[code].tier_level.includes(tier?.code);
        }
      } else if (DdmJSON.objects[code].tier_level) {
        return !DdmJSON.objects[code].tier_level.includes(tier?.code);
      }
    }

    if (
      (code === 'garment_none' ||
        code === 'garment_001' ||
        code === 'garment_002' ||
        code === 'garment_004' ||
        code === 'garment_003') &&
      !selectedCodes.includes('full_body')
    ) {
      return true;
    }

    if (
      (code === 'gender_none' ||
        code === 'gender_male' ||
        code === 'gender_female') &&
      !selectedCodes.includes('full_body')
    ) {
      return true;
    }

    if (
      (code === 'body_pose_a' || code === 'body_pose_t') &&
      !selectedCodes.includes('full_body')
    ) {
      return true;
    }

    if (code === 'remove_beard_false' || code === 'remove_beard_true') {
      return false;
    }

    if (
      (code === 'undefined' || code === 'apple') &&
      !selectedCodes.includes('rgbd')
    ) {
      return true;
    }
    return false;
  };

  const checkTemplateBusiness = (name) => {
    if (!isDDMGenerate) {
      if (componentVersion === 'Version1' && name === 'Business') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  /******** Meta Information Data Display Logic Start *****/
  const renderedElement = (attributes) => {
    let elementDataType = attributes[0].data_type;
    if (!elementDataType) {
      elementDataType = 'string';
    }
    switch (elementDataType) {
      case 'boolean':
        return attributes.map((ele) => {
          return (
            <>
              {checkTemplateBusiness(ele.name) && (
                <div className={'element-detail-container'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleCheckboxChange(e, ele)}
                        color="primary"
                        checked={selectedCodes.includes(ele.code)}
                        disabled={
                          isDisabled(ele.code) ||
                          (editData && editData.scope === 'system') ||
                          (tier?.name !== 'Advanced' &&
                            tier?.name !== 'Enterprise' &&
                            showUpgrade(ele.code))
                        }
                      />
                    }
                    label={ele.name}
                    labelPlacement="end"
                  />

                  {tier?.name !== 'Advanced' &&
                    tier?.name !== 'Enterprise' &&
                    showUpgrade(ele.code) && (
                      <Tooltip title="Please upgrade to use this feature">
                        <span className={'upgradeBtn'}>Upgrade</span>
                      </Tooltip>
                    )}
                </div>
              )}
            </>
          );
        });
      case 'select':
        return (
          <div className={'didimo-generate-select'} style={{ marginTop: 0 }}>
            <FormControl
              fullWidth
              className="select-version-spacing"
              disabled={editData?.scope === 'system'}
              style={{ marginBottom: 0 }}
            >
              <InputLabel id="select-hair">Select Hair</InputLabel>
              <Select
                labelId="select-hair-label"
                id="hair-select"
                value={selectedHair}
                onChange={(e) => handleSelectChange(e.target.value)}
                IconComponent={(props) =>
                  selectedHair && (
                    <ClearIcon
                      className={'select-remove-icon'}
                      onClick={() => handleSelectChange('')}
                    />
                  )
                }
              >
                {attributes.map((ele) => {
                  return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
        );
      default:
        return (
          <>
            <label>{element.name}</label>
            <input type="text" value={''} />
          </>
        );
    }
  };

  const groupElements = (group) => {
    const attributes = getMetaDataVersion().attributes.filter(
      (a) => a.group === group
    );
    return <FormGroup>{renderedElement(attributes)}</FormGroup>;
  };

  const isShowInfoIcon = (group) => {
    if (group !== 'texture_size') {
      const groupElementArr = getMetaDataVersion().attributes.filter(
        (f) => f.group === group
      );
      let count = 0;
      for (let i = 0; i < groupElementArr.length; i++) {
        if (isDisabled(groupElementArr[i].code)) {
          count++;
        }
      }
      return count !== groupElementArr.length;
    }
    return true;
  };

  const handleRedirectLinks = (group) => {
    switch (group) {
      case 'input_type':
        return 'https://developer.didimo.co/docs/best-practice-for-image-capture-quality';
      case 'transfer_formats':
        return 'https://developer.didimo.co/docs/mesh#transfer-formats';
      case 'baseball':
        return 'https://developer.didimo.co/docs/hairstyles';
      case 'body_pose':
        return 'https://developer.didimo.co/docs/body-bind-poses';
      case 'garment':
        return 'https://developer.didimo.co/docs/garments';
      case 'animation_capability':
        return 'https://developer.didimo.co/docs/animation';
      case 'profile':
        return 'https://developer.didimo.co/docs/package-profiles';
      case 'texture_size':
        return 'https://developer.didimo.co/docs/textures';
      default:
        return '';
    }
  };

  const checkBody = (name) => {
    if (!isDDMGenerate) {
      if (componentVersion === 'Version1' && name === 'Body Pose ') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  let elements = (getMetaDataVersion().groups || []).map((group, key) => {
    return (
      isShowInfoIcon(group.code) &&
      checkBody(group.name) && (
        <div className="input-detail" key={key}>
          <div className="character-text">
            <h4 className="m0 custom-font-family group-title">
              {group.name}
              {isDDMGenerate
                ? (group.name === 'Input type' ||
                    group.name === 'Transfer formats' ||
                    group.name === 'Avatar Structure') &&
                  ' *'
                : null}
              {handleRedirectLinks(group.code) && (
                <span className="generate-didimo-info-icon">
                  <HtmlTooltip
                    placement="right-start"
                    enterTouchDelay={0}
                    title={
                      <a
                        target={'_blank'}
                        href={handleRedirectLinks(group.code)}
                        className={'learn-more-link'}
                      >
                        <React.Fragment>{'Learn More'}</React.Fragment>
                      </a>
                    }
                  >
                    <IconButton variant="contained">
                      <InfoIcon />
                    </IconButton>
                  </HtmlTooltip>
                </span>
              )}
            </h4>
          </div>
          <div className={'didimo-code'}>{groupElements(group.code)}</div>
        </div>
      )
    );
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleOpenModel = (e, type = 2) => {
    e.stopPropagation();
    setIsOpen(true);
    setModelType(type);
  };
  return (
    <div className="ddm-generate-modal-container">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} style={{ paddingLeft: '8px' }}>
            {getMetaDataVersion() ? elements : null}
          </Grid>

          {isDDMGenerate && (
            <>
              <Grid item xs={12} md={5} style={{ paddingLeft: '8px' }}>
                <div
                  style={{
                    position: 'absolute',
                    padding: '5px',
                    right: '40px',
                    display:
                      files?.length > 0 || files?.name ? 'block' : 'none',
                  }}
                >
                  <ClearIcon
                    style={{
                      fontSize: '24px',
                      color: 'gray',
                      cursor: 'pointer',
                    }}
                    onClick={() => setFiles([])}
                  />
                </div>

                <div
                  className={`ddmCreate-Modal${isDragOver ? ' drag-over' : ''}${
                    files?.length > 0
                      ? ' has-files'
                      : files?.name
                      ? ' has-files'
                      : ''
                  }`}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <p style={{ opacity: 1 }}>
                    {dropBox1Text}
                    {!selectedCodes.includes('rgbd') && (
                      <>
                        <p>OR</p>
                        <p style={{ opacity: 1 }}>
                          {/*  <Button variant="contained" sx={{ mb: 2 }} size="small">
                          {' '}
                          Upload From Computer
                        </Button> */}
                          <Button
                            variant="contained"
                            onClick={(e) => handleOpenModel(e, 1)}
                            size="small"
                          >
                            Take a Picture
                          </Button>
                        </p>
                      </>
                    )}
                  </p>

                  {thumb}
                </div>
                {/*<Typography>*/}
                {/*  <Button*/}
                {/*    variant="outlined"*/}
                {/*    onClick={() => setIsOpen(true)}*/}
                {/*    style={{textTransform:'capitalize'}}*/}
                {/*  >*/}
                {/*    Capture Photo*/}
                {/*  </Button>*/}
                {/*</Typography>*/}

                {selectedCodes.includes('rgbd') && (
                  <div
                    className={`ddmCreate-Modal${
                      isDragOver ? ' drag-over' : ''
                    }${fileDepth.preview ? ' has-files' : ''}`}
                    {...getRootDropProps()}
                  >
                    <input {...getInputDropProps()} />
                    <p>Drop or click to select a depth file</p>
                    {thumb2}
                  </div>
                )}

                <div className={'custom-font-family didimoTotalCost'}>
                  {zipCount === 0 && <p>This didimo will cost</p>}
                  {zipCount > 0 && <p>This operation will cost</p>}
                  <h4 className={'poientsTab'}>{displayPoints} Points</h4>
                  {zipCount > 0 && (
                    <p>Photo Count : {zipCount > 0 ? zipCount : 0}</p>
                  )}
                  <p>Current Tier : {tier?.name}</p>
                  <p>Balance : {accountbalance} points</p>
                </div>
              </Grid>
              {processingError && (
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    paddingTop: '10px',
                  }}
                >
                  <span
                    style={{
                      color: 'red',
                      fontWeight: '500',
                      fontSize: '12px',
                    }}
                  >
                    {processingErrorMsg}
                  </span>
                </div>
              )}
              {accountbalance < displayPoints && (
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    paddingTop: '10px',
                  }}
                >
                  <span
                    style={{
                      color: 'red',
                      fontWeight: '500',
                      fontSize: '12px',
                    }}
                  >
                    You don't have enough points to generate didimo
                  </span>
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid>
          {codeError && (
            <Alert
              severity="warning"
              style={{
                border: '1px solid #ff9800',
                backgroundColor: 'white',
                marginTop: '10px',
              }}
            >
              {errorMessage}
            </Alert>
          )}
        </Grid>
      </DialogContent>
      <CaptureModal
        isOpen={isOpen}
        setFiles={setFiles}
        files={files}
        closeModal={closeModal}
        handleNext={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        getInputProps={getInputProps}
        modelType={modelType}
        src={src}
        setSrc={setSrc}
        handleChangefile={handleChangefile}
      />

      <div className="footer custom-font-family">
        <h4>* Mandatory Fields</h4>
        <div className="btn-group">
          {isDDMGenerate ? (
            <Button
              onClick={handleUpload}
              disabled={
                disableButton ||
                isUploading ||
                isValidateItems ||
                accountbalance < displayPoints
              }
              color="primary"
            >
              {isUploading ? <CircularProgress /> : 'Generate'}
            </Button>
          ) : (
            <Button
              onClick={handleSaveTemplateData}
              disabled={
                !codeName ||
                !name ||
                !desc ||
                !selectedCodes.length ||
                (editData && editData.scope === 'system')
              }
              color="primary"
            >
              {isUploading ? (
                <CircularProgress />
              ) : editData ? (
                'Save'
              ) : (
                'Create'
              )}
            </Button>
          )}

          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DDMElements;
