import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  applicationDetail: {
    flexDirection: 'column',
  },
  submitButtonAreaStyles: {
    textAlign: 'right',
    paddingTop: 10,
  },
  mainActionButtons: {
    marginLeft: '10px',
  },
  mainActionButtonsDivider: {
    marginTop: '20px',
  },
  deleteButton: {
    color: 'red',
  },
  /* applicationRoot: {
    margin: "2px 0"
  }, */
  apiSecret: {
    cursor: 'pointer',
  },
});

const ConfigureAPIKey = (props) => {
  // const [open, setOpen] = React.useState(props.isOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }; */

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="configure-api-key-title"
    >
      <DialogTitle id="configure-api-key-title " style={{ paddingBottom: '0' }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {(props.actions || []).map((action, index) => (
          <Button
            autoFocus
            onClick={action.eventHandler}
            disabled={action.disabled}
            color="primary"
          >
            {action.description}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ConfigureAPIKey;
