import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React, { useState, useContext, Fragment, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  Alert,
} from '@material-ui/core';
import FacebookIcon from '../../icons/Facebook';
import GoogleIcon from '../../icons/GoogleIcon.svg';
import AppleIcon from '../../icons/Apple';

import Amplify, { Auth, Hub } from 'aws-amplify';
import { login, isUserAuthenticated } from 'src/context/actions';
import { GlobalContext } from 'src/context/store';
import { CircularProgress } from '@mui/material';
import './layout/Login.css';
import axios from 'axios';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
    storage: localStorage,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  },
});

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(GlobalContext);
  const [error, setError] = useState(null);

  //const [policies, setPolicies] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const redirectUrL = location.hash.substr(14);

  useEffect(() => {
    if (state?.message !== undefined) {
      setIsAuthenticated(false);

      if (state.message === 'User is not confirmed.') {
        navigate(
          `${process.env.REACT_APP_ROOT_PATH}/confirm?username=` +
            encodeURIComponent(userData.email)
        );
      }

      if (state.message === 'User does not exist.') {
        state.message = 'Incorrect username or password.';
      }

      setError(state);
    } else {
      if (state?.isAuth) {
        (async () => {
          //check data policy
          const resPro = await axios.get(`profiles/me`, {});
          const missingPolicies = resPro?.data?.accounts[0]?.policies.filter(
            (missingPolicy) =>
              missingPolicy.status !== 'accepted' &&
              missingPolicy.data_policy.is_acceptance_required === true
          );

          //setPolicies(resPro?.data?.accounts[0]?.policies)

          if (missingPolicies.length > 0) {
            navigate(`${process.env.REACT_APP_ROOT_PATH}/profile/login/`);
          } else {
            for (const idx in state?.user?.settings) {
              if (
                state.user.settings[idx].key ===
                'didimo.customerportal.is_welcome_screen_active'
              ) {
                if (state.user.settings[idx].value == true) {
                  navigate(
                    `${process.env.REACT_APP_ROOT_PATH}/${
                      redirectUrL ? redirectUrL : 'mydidimos'
                    }`
                  );
                } else {
                  navigate(`${process.env.REACT_APP_ROOT_PATH}/welcome`);
                }
              }
            }
          }
        })();
      } else {
        (async () => {
          try {
            let user = await Auth.currentAuthenticatedUser();
            if (user.username != '') {
              dispatch(await isUserAuthenticated(navigate));
            }
          } catch (err) {
            console.log(err);
          }
        })();
      }
    }

    if (state?.isAuth === false) {
      setIsAuthenticated(false);
    }
  }, [state]);

  const signIn = async (values) => {
    setUserData(values);
    setError('');
    setIsAuthenticated(true);
    try {
      let res = await login(values, navigate);
      dispatch(res);
    } catch (error) {
      setIsAuthenticated(false);
      setError(error);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Login | Didimo</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="xs" className={'Login-container'}>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email address is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={signIn}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ textAlign: 'center' }} className={'main-header'}>
                  <Typography variant="h2" className={'main-header'}>
                    Sign in With Didimo
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pt: 1,
                  }}
                >
                  {error && <Alert severity="error">{error.message}</Alert>}
                </Box>

                <TextField
                  autoComplete="off"
                  autoFocus
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  defaultValue={values.email}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  defaultValue={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }} className={'SpinnerContainer'}>
                  <Button
                    className={'signIn-btn'}
                    disabled={isAuthenticated}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {isAuthenticated ? (
                      <CircularProgress />
                    ) : (
                      <span style={{ fontWeight: 'bold' }}>Sign in</span>
                    )}
                  </Button>
                </Box>
                <Typography
                  style={{
                    color: '#2A0651',
                    fontSize: '14px',
                    marginBottom: '10px',
                  }}
                  align="center"
                  color="textSecondary"
                  variant="body1"
                >
                  or Sign in with
                </Typography>
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item>
                    <Button
                      className={'iconStyleBtn'}
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Facebook' })
                      }
                    >
                      {' '}
                      <FacebookIcon />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={'iconStyleBtn'}
                      size="large"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Google' })
                      }
                    >
                      {' '}
                      <img src={GoogleIcon} alt={'google-icon'} />
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      className={'iconStyleBtn'}
                      size="large"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'SignInWithApple' })
                      }
                    >
                      <AppleIcon />{' '}
                    </Button>
                  </Grid>
                </Grid>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="center"
                  sx={{
                    py: 3,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to="/forgot"
                    variant="h6"
                    underline="hover"
                  >
                    Forgot your password?
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="center"
                  gutterBottom={true}
                >
                  Don&apos;t have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                    underline="hover"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Login;
