import React from 'react';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';

export default function ConfirmDialog(props) {
  const { open, onContinue, onCancel, onClose, applicationData } = props;

  return (
    <ConfirmDeleteDialog
      title="Delete application"
      message={`This action will delete the application "${applicationData.name}" and all its API keys. Are you sure you want to continue?`}
      open={open}
      onContinue={() => {
        onContinue(applicationData);
      }}
      onCancel={onCancel}
      onClose={onClose}
      applicationData
    />
  );
}
