import React from 'react';
import { FormGroup, Grid } from '@mui/material';

const MetaInfoVersion250 = (props) => {
  const { isEnableElement } = props;
  return (
    <div>
      <Grid container>
        <Grid item container style={{ marginTop: 10 }}>
          <Grid item lg={5} xs={4}>
            <div className="info-text">
              <h4>Input Type</h4>
            </div>
          </Grid>
          <Grid item lg={7} xs={8}>
            <div className="elementContainer" style={{ textAlign: 'right' }}>
              <FormGroup>
                {isEnableElement('input_type', 'photo') && 'Photo'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('input_type', 'rgbd') && 'Photo + Depth'}
              </FormGroup>
            </div>
          </Grid>
        </Grid>

        <Grid item container style={{ marginTop: 10 }}>
          <Grid item lg={5} xs={4}>
            <div className="info-text">
              <h4>Transfer Formats</h4>
            </div>
          </Grid>
          <Grid item lg={7} xs={8}>
            <div className="elementContainer" style={{ textAlign: 'right' }}>
              <FormGroup>
                {isEnableElement('transfer_formats', 'fbx') && 'FBX'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('transfer_formats', 'gltf') && 'glTF'}
              </FormGroup>
            </div>
          </Grid>
        </Grid>

        {(isEnableElement('simple_poses', 'true') ||
          isEnableElement('arkit', 'true') ||
          isEnableElement('oculus_lipsync', 'true') ||
          isEnableElement('aws_polly', 'true')) && (
          <Grid item container style={{ marginTop: 10 }}>
            <Grid item lg={5} xs={4}>
              <div className="info-text">
                <h4>Animation Capability</h4>
              </div>
            </Grid>
            <Grid item lg={7} xs={8}>
              <div className="elementContainer" style={{ textAlign: 'right' }}>
                <FormGroup>
                  {isEnableElement('simple_poses', 'true') &&
                    'Simple Poses: blink/expressions'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('arkit', 'true') && 'ARKit compatible'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('oculus_lipsync', 'true') &&
                    'Oculus Lipsync compatible'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('aws_polly', 'true') &&
                    'Animation Polly compatible'}
                </FormGroup>
              </div>
            </Grid>
          </Grid>
        )}

        {(isEnableElement('max_texture_dimension', '2048') ||
          isEnableElement('max_texture_dimension', '1024') ||
          isEnableElement('max_texture_dimension', '512')) && (
          <Grid item container style={{ marginTop: 10 }}>
            <Grid item lg={5} xs={4}>
              <div className="info-text">
                <h4>Max Texture Dimension</h4>
              </div>
            </Grid>
            <Grid item lg={7} xs={8}>
              <div className="elementContainer" style={{ textAlign: 'right' }}>
                <FormGroup>
                  {isEnableElement('max_texture_dimension', '2048') && '2048'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('max_texture_dimension', '1024') && '1024'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('max_texture_dimension', '512') && '512'}
                </FormGroup>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MetaInfoVersion250;
