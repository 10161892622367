import React from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';

export default function SuccessSnack(props) {
  const open = props.open || false;
  return (
    <Snackbar
      onClose={props.onClose}
      open={open}
      autoHideDuration={6000}
      TransitionComponent={props.transition || Fade}
    >
      <Alert severity="success" elevation={6} variant="filled" {...props}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
