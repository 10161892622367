import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

const styles = (theme) => ({
  panelRoot: {
    padding: '15px',
    marginBottom: '10px',
    '& h2': {
      marginTop: '1px',
    },
  },
});

const TopLevelPanel = (props) => {
  let classes = [props?.classes?.panelRoot];
  let propsClassName = props?.className;

  if (propsClassName) {
    if (!Array.isArray(propsClassName)) {
      propsClassName = [propsClassName];
    }
    classes = classes.concat(propsClassName);
  }
  return <Paper className={classes}>{props?.children}</Paper>;
};

export default TopLevelPanel;
