import React, {
  createContext,
  useReducer,
  useEffect,
  useCallback,
} from 'react';
import { Auth } from 'aws-amplify';
import { reducer } from './reducer';

const initialState = {
  user: {},
  loading: false,
  isAuth: false,
  statusManager: [],
  downloadStatusManager: {},
  cartData: [],
  marketPlaceData: [],
  notificationData: [],
};

export const GlobalContext = createContext(initialState);

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function isUserAuthenticated() {
    return await Auth.currentAuthenticatedUser()
      .then((data) => {
        // if (data?.username) {
        // navigate(`${process.env.REACT_APP_ROOT_PATH}/dashboard`, {
        //   replace: true,
        // });
        // }
      })
      .catch((err) => {
        navigate(`${process.env.REACT_APP_ROOT_PATH}/login`, { replace: true });
      });
  }

  const value = { state, dispatch };

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
}
