import React, { useContext, useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

function CookieBase() {
  const cookieMessageSignature = '20200402';
  const cookieMessage =
    'We use necessary cookies and local storage data to make our site work. We won\'t set optional cookies unless you enable them. Using this tool will set a cookie and some local storage data on your device to remember your preferences. For more detailed information about the cookies we use and local storage data we save on your device, see our <a href="https://www.didimo.co/policies" target="_blank">Cookie policy</a>.';
  const cookieConsentSignatureName = 'DDCookieConsentSig';
  const cookieConsentName = 'DDCookieConsent';

  const handleonAccept = () => {
    document.cookie = `${cookieConsentSignatureName}=${cookieMessageSignature}`;
  };

  return (
    <div>
      <CookieConsent
        cookieName={cookieConsentName}
        onAccept={handleonAccept}
        expires={365 * 10}
        style={{
          alignItems: 'baseline',
          background: '#353535',
          color: 'white',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          left: '0',
          position: 'fixed',
          width: '100%',
          zIndex: '1101',
        }}
        debug={false}
        enableDeclineButton={false}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: cookieMessage,
          }}
        />
      </CookieConsent>
    </div>
  );
}

export default CookieBase;
