import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TierIcon from '../../../components/TierIcon';
import { formatPoints } from '../../../helpers/formaters';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '900px',
    padding: theme.spacing(3),
    margin: '10px 0',
  },
  media: {
    height: 140,
  },
  icon: {
    height: 80,
    width: 80,
    objectFit: 'scale-down',
  },
  trial: {
    backgroundColor: theme.packages.trial.mainColor,
  },
  Trial: {
    backgroundColor: theme.packages.trial.mainColor,
  },
  Basic: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  basic: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  Starter: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  starter: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  Advanced: {
    backgroundColor: theme.packages.advanced.mainColor,
  },
  advanced: {
    backgroundColor: theme.packages.advanced.mainColor,
  },
  Pro: {
    backgroundColor: theme.packages.pro.mainColor,
  },
  pro: {
    backgroundColor: theme.packages.pro.mainColor,
  },
  Enterprise: {
    backgroundColor: theme.packages.enterprise.mainColor,
  },
  enterprise: {
    backgroundColor: theme.packages.enterprise.mainColor,
  },
}));

export default function CreateOrderPackageCard(props) {
  const classes = useStyles();

  const getPrice = () => {
    if (props.currentProductPrice) {
      if (props.currentProductPrice.description === 'Black Friday') {
        return parseFloat(
          props.currentProductPrice.base_price
        ).toLocaleString();
      } else if (props.currentProductPrice.price > 0) {
        return parseFloat(props.currentProductPrice.price).toLocaleString();
      }
    } else {
      return parseFloat(props.price).toLocaleString();
    }
  };

  return (
    <Card
      className={`${classes.root} ${
        props.selectedPlan === props.tierCode ? classes[props.tierCode] : ''
      } ${props.classes}`}
      onClick={() => props.handleSelectPlan(props.tierCode)}
    >
      <Grid
        container
        display="flex"
        style={{ justifyContent: 'flex-end' }}
        spacing={2}
      >
        <Grid
          item
          container
          justify="center"
          style={{ width: '80px', padding: '8px' }}
        >
          <TierIcon tierCode={props.title} className={classes.icon} />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            padding: '8px',
            flexGrow: '1',
            flexShrink: '1',
            flexBasis: '170px',
            // width: "auto",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography gutterBottom variant="h3" style={{ fontSize: '22px' }}>
              {props.title}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {props.points > 0 && (
              <Grid item>
                <Typography
                  variant="h4"
                  style={{
                    textAlign: 'center',
                    display: 'inline-block',
                    fontSize: '18px',
                  }}
                >
                  {formatPoints(props.points, 0)}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    textAlign: 'center',
                    display: 'inline-block',
                    marginLeft: '5px',
                  }}
                >
                  points
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{ width: 'auto', gap: '1rem', marginTop: '3%' }}
        >
          {props.current_price && props.current_price != props.price && (
            <Grid item style={{}}>
              <Typography
                variant="h3"
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                  textDecoration: 'line-through',
                }}
              >
                ${parseFloat(props.price).toLocaleString()}
              </Typography>
            </Grid>
          )}
          {props.price > 0 && (
            <Grid item style={{}}>
              <Typography
                variant="h3"
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                }}
              >
                $
                {props?.currentProductPrice?.price > 0
                  ? parseFloat(
                      props?.currentProductPrice?.price
                    ).toLocaleString()
                  : parseFloat(props.current_price).toLocaleString()}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  textAlign: 'center',
                }}
              >
                plus taxes
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
