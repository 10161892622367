import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import './Notification.css';

const NotificationDialog = (props) => {
  const { open, onArchive, onClose, notification, notificationsData } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby="configure-api-key-title"
      style={{ minHeight: '500px', minWidth: '300px' }}
    >
      {notification && notification.title && (
        <React.Fragment>
          <DialogTitle id="configure-api-key-title">
            {notification.title}
          </DialogTitle>
          <DialogContent style={{ padding: theme.spacing(3) }}>
            <Grid container spacing={2}>
              <div
                id={'notificationData'}
                dangerouslySetInnerHTML={{
                  __html: notification.content.replaceAll('\n', '<br/>'),
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={onArchive}
              disabled={notification.status === 2}
            >
              Archive
            </Button>
            <Button color="primary" variant="contained" onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

NotificationDialog.propTypes = {
  className: PropTypes.string,
};

export default NotificationDialog;
