import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Card,
  CardContent,
  InputAdornment,
  Autocomplete,
  List,
  ListItem,
} from '@material-ui/core';
import EmailField from '../../components/EmailField';
import USCityField from '../../components/USCityField';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/store';
import './CreateOrder.css';
import axios from 'axios';
import { isUserAuthenticated, addToCart } from 'src/context/actions';
import CookieBase from '../../components/CookieBase';
import { useNavigate } from 'react-router-dom';
import SuccessSnack from '../../components/SuccessSnack';
import { getData } from '../../helpers/dataFetching';
import USStatesField from '../../components/USStatesField';
import CreateOrderPackageCard from '../Packages/PackageCard/CreateOrderPackageCard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { v4 } from 'uuid';
import { postData } from '../../helpers/dataFetching';
import ErrorSnack from '../../components/ErrorSnack';
import queryString from 'query-string';
import { CircularProgress } from '@mui/material';
import { Slider } from '@material-ui/core';
import PackageCard from '../Packages/PackageCard/PackageCard';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardHeaderTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backArrow: {
    color: '#2a0651',
    fontSize: '23px',
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
  rangeSlider: {
    width: '40%',
    display: 'block',
    margin: '0 auto',
  },
}));

const sliderOptions = [
  {
    value: 0,
    label: 'User Information',
  },
  {
    value: 100,
    label: 'Review',
  },
];
const CreateOrder = (props) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(GlobalContext);

  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');

  const [countrySelectedCode, setcountrySelectedCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryList, setCountryList] = useState('');
  const [countryError, setCountryError] = useState('');

  const [streetAddressLn1, setStreetAddressLn1] = useState('');
  const [streetAddressLn2, setStreetAddressLn2] = useState('');
  const [states, setStates] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [taxId, setTaxId] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountEmailAddress, setAccountEmailAddress] = useState('');
  const [isCompanyAccount, setIsCompanyAccount] = useState(false);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [touched, setTouched] = useState({});
  const navigate = useNavigate();
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [allPolicy, setAllPolicy] = useState([]);
  const [missingPolicies, setMissingPolicies] = useState([]);
  const [pendingOrders, setDdmoStatus] = useState();
  const [isMissingPoliciesMessage, setIsMissingPoliciesMessage] = useState(
    false
  );
  const [isMissingPackageSelect, setIsMissingPackageSelect] = useState(false);
  const [isOrderCreating, setIsOrderCreating] = useState(false);

  useEffect(() => {
    if (countryCode != '') {
      axios.get('geo/countries', {}).then((res) => {
        setCountryList(res.data);
        let selectedCountry = res.data.find(
          (country) => country.iso_code2 === countryCode
        ) || { __type: '', iso_code2: '', name: '' };
        setcountrySelectedCode(selectedCountry);
        setisDataLoaded(true);
      });
    }
  }, [countryCode]);

  useEffect(() => {
    let plan = queryString.parse(location.hash).plan;

    axios.get('profiles/me').then((res) => {
      let requiredFields = ['givenName', 'familyName', 'country'];
      if (res.data.accounts[0].address.country === 'US') {
        requiredFields.push('state');
      }
      setGivenName(res.data.given_name);
      setFamilyName(res.data.family_name);
      setEmail(res.data.email_address);
      setCountryCode(res.data.accounts[0].address.country);
      setStreetAddressLn1(res.data.accounts[0].address.street_address_line1);
      setStreetAddressLn2(res.data.accounts[0].address.street_address_line2);
      setStates(res.data.accounts[0].address.state);
      setCity(res.data.accounts[0].address.city);
      setZipCode(res.data.accounts[0].address.zip_code);
      setTaxId(res.data.accounts[0].tax_id);
      setAccountName(res.data.accounts[0].company_name);
      setAccountEmailAddress(res.data.accounts[0].email_address);
      setIsCompanyAccount(res.data.accounts[0].is_company);
      setSelectedPlan(plan);

      (async () => {
        try {
          if (state.isAuth === false) {
            dispatch(await isUserAuthenticated(navigate, 'order/create'));
          }
          let uuid = res.data.accounts[0].uuid;
          const allPolicy = await getData(`accounts/${uuid}`);

          const allMissingPolicies =
            allPolicy.policies && allPolicy.policies.length > 0
              ? [...allPolicy.policies].filter(
                  (policy) =>
                    (policy.status !== 'accepted' ||
                      policy.__updated === true) &&
                    policy.data_policy.target_users.length === 1 &&
                    policy.data_policy.target_users[0] === 'customers'
                )
              : [];
          setAllPolicy(allPolicy.policies);
          setMissingPolicies(allMissingPolicies);
        } catch (err) {
          console.log(err);
        }
      })();
    });
  }, []);

  useEffect(() => {
    getData('accounts/default/status').then((res) => {
      setDdmoStatus(res?.pending_orders.length);
    });

    getData(
      `billing/products?active=true&purchasable=true&product_type=standard`
    ).then((res) => {
      setProducts(res);
    });
  }, []);

  const handleSaveData = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOrderCreating(true);
    if (!selectedPlan) {
      setIsMissingPackageSelect(true);
      setIsOrderCreating(false);
    } else {
      let data = [
        {
          op: 'replace',
          path: '/address/street_address_line1',
          value: streetAddressLn1,
        },
        {
          op: 'replace',
          path: '/address/street_address_line2',
          value: streetAddressLn2,
        },
        {
          op: 'replace',
          path: '/address/state',
          value: states,
        },
        {
          op: 'replace',
          path: '/address/city',
          value: city,
        },
        {
          op: 'replace',
          path: '/address/country',
          value: countryCode,
        },
        {
          op: 'replace',
          path: '/address/zip_code',
          value: zipCode,
        },
        {
          op: 'replace',
          path: '/tax_id',
          value: isCompanyAccount ? taxId : '',
        },
        {
          op: 'replace',
          path: '/email_address',
          value: isCompanyAccount ? accountEmailAddress : '',
        },
        {
          op: 'replace',
          path: '/is_company',
          value: isCompanyAccount,
        },
        {
          op: 'replace',
          path: '/company_name',
          value: isCompanyAccount ? accountName : '',
        },
      ];

      let payloadNew = {
        family_name: familyName,
        given_name: givenName,
      };

      // Make first two requests
      await Promise.all([
        axios.patch('accounts/' + state.user.accounts.uuid, data, {
          headers: { Authorization: `Bearer ${state.user.accessToken}` },
        }),
        axios.put('profiles/' + state.user.profile_uuid, payloadNew, {
          headers: { Authorization: `Bearer ${state.user.accessToken}` },
        }),
      ]);

      await updatePolicies();
    }
  };

  const updatePolicies = async () => {
    let payloadItem = [];

    allPolicy.forEach((policy) => {
      payloadItem.push({
        data_policy_uuid: policy.data_policy.uuid,
        status: policy.status,
      });
    });

    let payload = [
      {
        op: 'replace',
        path: '/policies',
        value: payloadItem,
      },
    ];
    await axios
      .patch('accounts/' + state.user.accounts.uuid, payload, {})
      .then((res) => {
        let missingPolicies = [];
        missingPolicies = res.data.policies.filter(
          (policy) =>
            policy.status !== 'accepted' &&
            policy.data_policy.target_users.length === 1 &&
            policy.data_policy.target_users[0] === 'customers'
        );

        if (missingPolicies.length === 0) {
          let productId = products.find((f) => f.name === selectedPlan).uuid;
          const payload = { uuid: productId };
          postData(
            'services/payments/stripe/orders',
            payload,
            props.session
          )
            .then((response) => {
              if (response.paymentIntentId) {
                setIsOrderCreating(false);
                axios.get(`billing/basket`).then((res) => {
                  dispatch(addToCart(res.data.accountProductsBasket));
                });
                navigate(`/orders#checkout=${response.uuid}`);
              } else {
                setSuccessMessageOpen(true);
              }
            })
            .catch((error) => {
              setIsOrderCreating(false);
              console.log('There was an error while creating an order');
            });
        } else {
          setIsOrderCreating(false);
          setIsMissingPoliciesMessage(true);
        }
      });
  };

  const onCountryChange = (e, selected) => {
    let requiredFields = ['givenName', 'familyName', 'country'];
    if (selected && countryCode !== selected.iso_code2) {
      setCountryCode(selected.iso_code2);
      setStates('');
      setCity('');
      setZipCode('');

      if (selected.iso_code2 === 'US') {
        requiredFields.push('state');
      }
    }
  };

  const onStateChange = (e, selected) => {
    if (selected && states !== selected.code) {
      setStates(selected.code);
      setCity('');
      setZipCode('');
    }
  };

  const onCityChange = (e, selected) => {
    if (selected && city !== selected.code) {
      setCity(selected.code);
      setZipCode('');
    }
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleOnPolicyChange = async (policyId, decision) => {
    const updatedPolicies = allPolicy.map((policy) => {
      if (policy.data_policy.uuid === policyId) {
        if (decision === true) {
          policy.status = 'accepted';
          policy.__updated = true;
        } else {
          policy.status = 'rejected';
          policy.__updated = false;
        }
      }
      return policy;
    });
    setAllPolicy(updatedPolicies);
  };

  return (
    <div className={'createOrderContainer'}>
      <Helmet>
        <title>Make Order | Didimo</title>
      </Helmet>

      {isDataLoaded === true && (
        <div className={classes.root}>
          <Grid container spacing={2} style={{ marginTop: '0px' }}>
            <Grid item xs={12}>
              <Card style={{ padding: '16px' }}>
                <Slider
                  className={classes.rangeSlider}
                  value={[0, 100]}
                  marks={sliderOptions}
                  valueLabelDisplay="auto"
                />
                <CardContent className="user_detail">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <div style={{ display: 'flex' }}>
                        <ArrowBackIcon
                          className={classes.backArrow}
                          onClick={() => navigate(-1)}
                        />
                        <Typography
                          variant="h4"
                          sx={{ fontWeight: 'bold', paddingBottom: '16px' }}
                        >
                          User data
                        </Typography>
                      </div>
                      <CardContent style={{ padding: 0 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <FormControlLabel
                              value="end"
                              control={
                                <Checkbox
                                  checked={isCompanyAccount}
                                  onChange={() => {
                                    setIsCompanyAccount(!isCompanyAccount);
                                  }}
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'isCompanyAccount',
                                    'data-id': 'isCompanyAccount',
                                  }}
                                />
                              }
                              label="This is a company account"
                              labelPlacement="end"
                            />
                          </Grid>

                          {!isCompanyAccount && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  value={givenName}
                                  onChange={(e) => {
                                    setGivenName(e.target.value);
                                  }}
                                  required
                                  fullWidth
                                  id="user-given-name"
                                  label="Given name"
                                  variant="outlined"
                                  error={
                                    touched['givenName'] && !givenName?.trim()
                                  }
                                  helperText={
                                    touched['givenName'] &&
                                    !givenName?.trim() &&
                                    'This field is required'
                                  }
                                  onBlur={(e) => {
                                    setTouched({ ...touched, givenName: true });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  value={familyName}
                                  onChange={(e) => {
                                    setFamilyName(e.target.value);
                                  }}
                                  required
                                  fullWidth
                                  id="user-family-name"
                                  label="Family name"
                                  variant="outlined"
                                  error={
                                    touched['familyName'] && !familyName?.trim()
                                  }
                                  helperText={
                                    touched['familyName'] &&
                                    !familyName?.trim() &&
                                    'This field is required'
                                  }
                                  onBlur={(e) => {
                                    setTouched({
                                      ...touched,
                                      familyName: true,
                                    });
                                  }}
                                />
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12}>
                            <Autocomplete
                              id="Country"
                              required
                              options={countryList}
                              getOptionLabel={(option) => `${option?.name}`}
                              onChange={onCountryChange}
                              onInputChange={(e, value, operation) => {
                                if (operation == 'clear') {
                                  onCountryChange(e, { iso_code2: '' });
                                }
                              }}
                              defaultValue={countrySelectedCode}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  helperText={countryError}
                                  label="Country *"
                                  name="countrycode"
                                  variant="outlined"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          {countryCode?.toUpperCase() === 'US' && (
                            <Grid item xs={12}>
                              <USStatesField
                                required
                                fullWidth
                                onChange={onStateChange}
                                value={states}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              value={streetAddressLn1}
                              onChange={(e) => {
                                setStreetAddressLn1(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              label="Address (line 1)"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'streetAddressLn1',
                                'data-id': 'streetAddressLn1',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={streetAddressLn2}
                              onChange={(e) => {
                                setStreetAddressLn2(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              label="Address (line 2)"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'streetAddressLn2',
                                'data-id': 'streetAddressLn2',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <USCityField
                              fullWidth
                              countryState={state}
                              onChange={onCityChange}
                              value={city}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={zipCode}
                              onChange={(e) => {
                                setZipCode(e.target.value);
                              }}
                              fullWidth
                              label="ZIP Code"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'zipCode',
                                'data-id': 'zipCode',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={accountName}
                              onChange={(e) => {
                                setAccountName(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              disabled={!isCompanyAccount}
                              label="Company name"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'accountName',
                                'data-id': 'accountName',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <EmailField
                              value={accountEmailAddress}
                              onChange={(e) => {
                                setAccountEmailAddress(e.target.value);
                              }}
                              disabled={!isCompanyAccount}
                              fullWidth
                              label="Company email"
                              inputProps={{
                                'aria-label': 'accountEmailAddress',
                                'data-id': 'accountEmailAddress',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={taxId}
                              onChange={(e) => {
                                setTaxId(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              disabled={!isCompanyAccount}
                              label="Tax ID"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'taxid',
                                'data-id': 'taxId',
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {countryCode && countryCode.toUpperCase()}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CardContent>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: 0, cursor: 'pointer' }}
                        >
                          {products
                            .filter((product) => product.price > 0)
                            .map((product) => (
                              <React.Fragment key={product.uuid}>
                                <Grid item xs={12}>
                                  <CreateOrderPackageCard
                                    title={product.name}
                                    tierCode={product.name}
                                    productId={product.uuid}
                                    isBuyable={false}
                                    isActive={
                                      product.current_product_price.active
                                    }
                                    current_price={
                                      product.current_product_price.price
                                    }
                                    price={product.price}
                                    points={product.points}
                                    description={product.shortDescription}
                                    handleSelectPlan={handleSelectPlan}
                                    selectedPlan={selectedPlan}
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                        </Grid>
                      </CardContent>

                      {missingPolicies &&
                        missingPolicies.length > 0 &&
                        missingPolicies.map((policy) => (
                          <Card style={{ marginTop: '15px' }}>
                            <List>
                              <ListItem key={v4()}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography variant="h5">
                                      {policy.data_policy.title}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <p
                                      style={{ margin: 0 }}
                                      dangerouslySetInnerHTML={{
                                        __html: policy.data_policy.contents,
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    direction="row"
                                    xs={12}
                                    justify="flex-end"
                                  >
                                    <Grid item>
                                      <FormControlLabel
                                        value="checkedA"
                                        control={
                                          <Checkbox
                                            color="primary"
                                            inputProps={{
                                              'aria-label': 'Checkbox A',
                                              'data-datapolicyid':
                                                policy.data_policy.uuid,
                                            }}
                                            style={{
                                              color: 'red',
                                            }}
                                            checked={
                                              policy.status === 'accepted'
                                            }
                                            onClick={(e) =>
                                              handleOnPolicyChange(
                                                policy.data_policy.uuid,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        required
                                        label={
                                          policy.data_policy
                                            .acceptance_action_title
                                        }
                                        labelStyle={{
                                          color: 'red',
                                        }}
                                        labelPlacement="start"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            </List>
                          </Card>
                        ))}
                    </Grid>
                  </Grid>

                  <form onSubmit={handleSaveData}>
                    <div className={'createOrderFooter'}>
                      <Button
                        sx={{ textTransform: 'none' }}
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        onClick={() => navigate(-1)}
                      >
                        Close
                      </Button>
                      <Button
                        sx={{ textTransform: 'none' }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px' }}
                        disabled={
                          !givenName ||
                          !familyName ||
                          !countryCode ||
                          (countryCode === 'US' && !states)
                        }
                      >
                        {isOrderCreating ? (
                          <CircularProgress />
                        ) : (
                          <span style={{ fontWeight: 'bold' }}>Next</span>
                        )}
                      </Button>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <SuccessSnack
            open={successMessageOpen}
            onClose={(e) => {
              setSuccessMessageOpen(false);
            }}
            message={
              'Almost done! Please check the billing area for instructions on how to complete the payment.'
            }
          />
          <CookieBase></CookieBase>
        </div>
      )}
      <SuccessSnack
        open={successMessageOpen}
        onClose={(e) => {
          setSuccessMessageOpen(false);
        }}
        message={'Your user data was updated!'}
      />
      <ErrorSnack
        open={isMissingPoliciesMessage}
        onClose={(e) => {
          setIsMissingPoliciesMessage(false);
        }}
        message="Please, check the pending terms and conditions acceptance requests."
      />
      <ErrorSnack
        open={isMissingPackageSelect}
        onClose={(e) => {
          setIsMissingPackageSelect(false);
        }}
        message="Please select any package to continue"
      />
    </div>
  );
};

export default CreateOrder;
