import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Card,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import PackageCard from '../../pages/Packages/PackageCard/PackageCard';
import ErrorSnack from '../../components/ErrorSnack';
import { v4 } from 'uuid';

const Products = ({
  open,
  onClose,
  products,
  productData,
  accountData,
  session,
  postDataFunction,
  missingPolicies,
  onPolicyChange,
  onPoliciesSubmit,
  onBuySuccess,
  onBuyError,
}) => {
  const theme = useTheme();
  const [isBuying, setIsBuying] = useState(false);
  const [markMissingPolicies, setMarkMissingPolicies] = useState(false);
  const [isMissingPoliciesMessage, setIsMissingPoliciesMessage] = useState(
    false
  );
  const [isMissingAccountAttributes, setIsMissingAccountAttributes] = useState(
    false
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onBuy = (productId) => {
    if (!isBuying) {
      setIsBuying(true);
      if (
        !accountData.address.country ||
        (accountData.address.country === 'US' && !accountData.address.state) ||
        !accountData.owner_profile.given_name ||
        !accountData.owner_profile.family_name
      ) {
        setIsMissingAccountAttributes(true);
        setIsBuying(false);
      } else {
        onPoliciesSubmit()
          .then((_missingPolicies) => {
            const payload = { uuid: productId };
            if (_missingPolicies.length === 0) {
              postDataFunction(
                'services/payments/stripe/orders',
                payload,
                session
              )
                .then((response) => {
                  setIsBuying(false);
                  onBuySuccess({
                    orderId: response.uuid,
                    paymentIntentId: response.paymentIntentId,
                    ...payload,
                  });
                })
                .catch((error) => {
                  setIsBuying(false);
                  onBuyError(payload);
                });
            } else {
              setMarkMissingPolicies(true);
              setIsBuying(false);
              setIsMissingPoliciesMessage(true);
            }
          })
          .catch((error) => {
            setIsBuying(false);
          });
      }
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        closeAfterTransition
        aria-labelledby="choose-a-package-title"
      >
        <DialogTitle id="choose-a-package-title">Choose a package</DialogTitle>
        <DialogContent>
          {missingPolicies && missingPolicies.length > 0 && (
            <Card>
              <List>
                {missingPolicies.map((policy) => (
                  <ListItem key={v4()}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {policy.data_policy.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <p
                          style={{ margin: 0 }}
                          dangerouslySetInnerHTML={{
                            __html: policy.data_policy.contents,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        xs={12}
                        justify="flex-end"
                      >
                        <Grid item>
                          <FormControlLabel
                            value="checkedA"
                            control={
                              <Checkbox
                                color="primary"
                                inputProps={{
                                  'aria-label': 'Checkbox A',
                                  'data-datapolicyid': policy.data_policy.uuid,
                                }}
                                style={{
                                  color: markMissingPolicies ? 'red' : 'auto',
                                }}
                                checked={policy.status === 'accepted'}
                                onClick={(e) =>
                                  onPolicyChange(
                                    policy.data_policy.uuid,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            required
                            label={policy.data_policy.acceptance_action_title}
                            labelStyle={{
                              color: markMissingPolicies ? 'red' : 'auto',
                            }}
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Card>
          )}
          {productData &&
            productData.map((product, index) => (
              <PackageCard
                key={v4()}
                title={product.name}
                tierCode={product.name}
                productId={product.uuid}
                isBuyable={true}
                isBuying={isBuying}
                isActive={product.current_product_price.active}
                current_price={product.current_product_price.price}
                price={product.price}
                points={product.points}
                onBuy={onBuy}
                description={product.shortDescription}
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorSnack
        open={isMissingPoliciesMessage}
        onClose={(e) => {
          setIsMissingPoliciesMessage(false);
        }}
        message="Please, check the pending terms and conditions acceptance requests."
      />
      <ErrorSnack
        open={isMissingAccountAttributes}
        onClose={(e) => {
          setIsMissingAccountAttributes(false);
        }}
        message="Some required billing information is missing. Please, visit your settings and fill your address and tax data."
      />
    </>
  );
};

export default Products;
