import React, { useContext, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { getData, postData } from '../../helpers/dataFetching';
import { formatDateTime } from '../../helpers/formaters';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { GlobalContext } from '../../context/store';
import { useNavigate } from 'react-router-dom';
import NotificationDialog from './NotificationDailog';
import ErrorSnack from '../../components/ErrorSnack';
import { useLocation } from 'react-router-dom';

const Notification = () => {
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationsData, setNotificationsData] = React.useState([]);
  const [selectedNotification, setSelectedNotification] = React.useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = React.useState(
    false
  );
  const [selectedModel, setSelectedModel] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [btnClicked, setBtnClicked] = React.useState(false);

  useEffect(() => {
    if (location.hash && !btnClicked) {
      mytest();
    }
  }, [location.hash]);

  useEffect(() => {
    defaultApiCall();
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'notifications'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const mytest = () => {
    const key = location.hash.substr(14);
    getData(
      'accounts/' + state?.user.accounts.uuid + '/notifications?page=1'
    ).then((response) => {
      setNotificationsData(response);
      setSelectedNotificationId(key);
      setSelectedModel(response.notifications.find((not) => not.uuid === key));
      setSelectedNotification(true);
    });
  };

  const defaultApiCall = async () => {
    const notificaionData = await getData(
      'accounts/' + state?.user.accounts.uuid + '/notifications?page=1'
    );
    setNotificationsData(notificaionData);
  };

  const onNotificationSelect = (notificationId) => {
    setBtnClicked(true);
    navigate(`#notification=${notificationId}`);
    setSelectedNotificationId(notificationId);
    setSelectedNotification(true);
    setSelectedModel(
      notificationsData.notifications.find((not) => not.uuid === notificationId)
    );
  };

  const onNotificationClose = () => {
    navigate(``);
    setSelectedNotification(false);
  };

  const onNotificationArchive = async () => {
    postData('notifications/' + selectedNotificationId + '/actions/archive')
      .then((response) => {
        defaultApiCall();
        onNotificationClose();
      })
      .catch((err) => {
        setErrorMessage('An error occurred while archiving the notification.');
      });
  };

  const myNotification = notificationsData && notificationsData.notifications;

  return (
    <Box sx={{ py: 2, px: 2, backgroundColor: '#eeeeee' }}>
      <Card>
        <CardHeader title="Active notifications" />
        <CardContent>
          {(myNotification && myNotification.length === 0 && (
            <div style={{ textAlign: 'center' }}>
              <NotificationsNoneIcon style={{ fontSize: 200 }} />
              <p style={{ fontWeight: 'bold', fontSize: 20 }}>
                You have no active notifications.
              </p>
            </div>
          )) || (
            <List>
              {myNotification &&
                myNotification
                  .filter(
                    (myNotification) => myNotification.status !== 'archive'
                  )
                  .map((notification, index) => {
                    return (
                      <>
                        <ListItem
                          data-id={notification.uuid}
                          button={true}
                          divider={true}
                          onClick={() =>
                            onNotificationSelect(notification.uuid)
                          }
                        >
                          <Grid container>
                            <Grid
                              item
                              container
                              style={{ justifyContent: 'space-between' }}
                            >
                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                }}
                              >
                                {notification.status === 'new' && (
                                  <FiberNewIcon
                                    color="secondary"
                                    style={{ marginRight: '0.3em' }}
                                  />
                                )}
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  style={{
                                    display: 'inline',
                                  }}
                                >
                                  {notification.title}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body2">
                                  {formatDateTime(notification.created_at)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </>
                    );
                  })}
            </List>
          )}
        </CardContent>
      </Card>
      <NotificationDialog
        open={selectedNotification}
        onArchive={onNotificationArchive}
        onClose={() => {
          onNotificationClose();
          setBtnClicked(false);
        }}
        notification={selectedModel}
      />
      <ErrorSnack
        open={errorMessage}
        onClose={(e) => {
          setErrorMessage('');
        }}
        message={errorMessage}
      />
    </Box>
  );
};

export default Notification;
