import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import GlobalBalance from '../components/dashboard/GlobalBalance/GlobalBalance';
import CurrentTier from '../components/dashboard/CurrentTier/CurrentTier';
import ActiveDidimoProcesses from '../components/dashboard/ActiveDidimoProcesses/ActiveDidimoProcesses';
import CreditHistory from '../components/dashboard/CreditHistory/CreditHistory';
import ActivePackages from '../components/dashboard/ActivePackages/ActivePackages';
import { GlobalContext } from 'src/context/store';
//import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
//import { isExpired } from 'react-jwt';
//import { Auth } from 'aws-amplify';
import { isUserAuthenticated } from 'src/context/actions';
import { getData } from '../helpers/dataFetching';
import CookieBase from 'src/components/CookieBase';
import { Alert } from '@mui/material';

const Dashboard = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const [accountStatus, setAccountStatus] = useState();
  const [creditHistoryCurrentPage, setCreditHistoryCurrentPage] = useState(0);
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [creditHistoryIsLoading, setCreditHistoryIsLoading] = useState(false);
  const [creditBalanceIsLoading, setCreditBalanceIsLoading] = useState(false);
  const [activePackagesIsLoading, setActivePackagesIsLoading] = useState(false);
  const [characterPackagesIsLoading, setCharacterPackagesIsLoading] = useState(
    false
  );
  const [activePackagesData, setActivePackagesData] = useState();
  const [characterPackagesData, setCharacterPackagesData] = useState();
  const [pendingOrders, setDdmoStatus] = useState();
  const activePackagesCurrentPage = 0;

  useEffect(() => {
    if (state?.isAuth === true) {
      fetchAccountStatusUUID();
      fetchTransactions(creditHistoryCurrentPage);
      fetchActivePackages(activePackagesCurrentPage);
      fetchCharacterPackages();
      fetchAccountStatus();
    }
    return () => {
      setAccountStatus();
      setActivePackagesData();
      setDdmoStatus();
    };
  }, [state]);

  //  Cleanup function which cleans up the useEffect when component renders
  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'welcome'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const fetchAccountStatusUUID = async () => {
    try {
      const res = await getData(
        `accounts/${state?.user?.accounts?.uuid}/status`
      );
      setCreditBalanceIsLoading(false);
      setAccountStatus(res);
    } catch (err) {
      console.log('fetchProfileUUID:', err);
    }
  };

  const fetchAccountStatus = async () => {
    try {
      getData('accounts/default/status').then((res) => {
        setDdmoStatus(res?.pending_orders.length);
      });
    } catch (err) {
      console.log('fetchAccountStatus:', err);
    }
  };

  const fetchTransactions = async (page) => {
    setCreditHistoryData(null);

    try {
      setCreditHistoryIsLoading(true);
      const res = await getData(
        `accounts/${
          state?.user?.accounts.uuid
        }/transactions?order_by=-created_at&page_size=20&page=${parseInt(
          page + 1
        )}`
      );

      setCreditHistoryCurrentPage(page);
      setCreditHistoryIsLoading(false);
      setCreditHistoryData(res);
    } catch (err) {
      console.log('fetchTransactions:error', err);
    }
  };

  const fetchActivePackages = async () => {
    try {
      setActivePackagesIsLoading(true);
      const res = await getData(
        `accounts/${state?.user?.accounts.uuid}/packages?product_type=standard&status=active`
      );
      setActivePackagesIsLoading(false);
      setActivePackagesData(res.packages);
    } catch (err) {
      console.log('fetchActivePackages', err);
    }
  };

  const fetchCharacterPackages = async () => {
    try {
      setCharacterPackagesIsLoading(true);
      const res = await getData(
        `accounts/${state?.user?.accounts.uuid}/packages?product_type=character_pack&status=active`
      );
      setCharacterPackagesIsLoading(false);
      setCharacterPackagesData(res.packages);
    } catch (err) {
      console.log('fetchActivePackages', err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Didimo</title>
      </Helmet>
      {!creditBalanceIsLoading &&
      !activePackagesIsLoading &&
      !characterPackagesIsLoading &&
      !creditHistoryIsLoading ? (
        <Box
          sx={{
            backgroundColor: 'transparent',
            py: 3,
            display: 'flex',
          }}
        >
          <Container maxWidth={false}>
            {pendingOrders > 0 && (
              <Alert
                severity="warning"
                style={{
                  border: '1px solid #ff9800',
                  backgroundColor: 'white',
                  marginBottom: '10px',
                }}
              >
                You have pending invoices. Please visit{' '}
                <Link to="/orders">Orders</Link> to proceed with the payment.
              </Alert>
            )}

            <Grid container justify="left" spacing={2}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <GlobalBalance
                  value={accountStatus?.balance}
                  expiringNext={{
                    date: accountStatus?.next_expiration_date,
                    balance: accountStatus?.next_expiration_points,
                  }}
                />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <CurrentTier
                  code={accountStatus?.tier.code}
                  name={accountStatus?.tier.name}
                />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <ActiveDidimoProcesses
                  activeProcesses={'0'}
                  lockedPoints={accountStatus?.running_operations_locks}
                  executingActionsCount={accountStatus?.running_operations}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="stretch" direction="row">
              <Grid item xs={12} sm={7}>
                <CreditHistory
                  onPageChange={(event, page) => {
                    fetchTransactions(page);
                  }}
                  creditHistoryData={creditHistoryData}
                  page={creditHistoryCurrentPage}
                  maxRowsPerPage={5}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                {activePackagesData && (
                  <ActivePackages
                    PackagesData={activePackagesData}
                    title="Active Packages"
                    isShowPointsLeft={true}
                  />
                )}
                {characterPackagesData && state.marketPlaceData?.length > 0 && (
                  <ActivePackages
                    PackagesData={characterPackagesData}
                    title="Character Packages"
                    isShowDownload={true}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : (
        <div style={{ position: 'relative' }}>
          <div style={{ height: 'calc(100vh - 169px)' }}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => 0 }}
              open={
                creditBalanceIsLoading ||
                activePackagesIsLoading ||
                characterPackagesIsLoading ||
                creditHistoryIsLoading
              }
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      )}

      <CookieBase></CookieBase>
    </>
  );
};

export default Dashboard;
