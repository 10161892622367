import React from 'react';
import {
  IconButton,
  Button,
  Divider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  Tooltip,
  TableContainer,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import red from '@material-ui/core/colors/red';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TopLevelPanel from '../TopLevelPanel';
import { ConfigureAPIKey } from '.';
import ConfimDeleteAPIKeyDialog from './ConfimDeleteAPIKeyDialog';
import { formatDate } from '../../helpers/formaters';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  applicationDetail: {
    flexDirection: 'column',
  },
  submitButtonAreaStyles: {
    textAlign: 'right',
    paddingTop: 10,
  },
  mainActionButtons: {
    marginLeft: '10px',
  },
  mainActionButtonsDivider: {
    marginTop: '20px',
  },
  deleteButton: {
    color: 'red',
  },
  /* applicationRoot: {
    margin: "2px 0"
  }, */
  apiKeyInfo: {
    // cursor: "pointer",
  },
  apiKeyDescription: {
    width: '400px',
  },
});

const ApplicationListItem = (props) => {
  const [configureAPIKeyOpen, setConfigureAPIKeyOpen] = React.useState(false);
  const [deleteApiKeyData, setDeleteApiKeyData] = React.useState({});
  const [configureAPIKey, setConfigureAPIKey] = React.useState({});
  const [
    configureAPIKeyProcessing,
    setConfigureAPIKeyProcessing,
  ] = React.useState(false);

  return (
    <TopLevelPanel>
      <Typography variant="h5" style={{ marginBottom: '3px' }}>
        {props.applicationName}
      </Typography>
      <div width="1">
        <Typography
          style={{
            border: '1px dashed #f5f5f5',
            backgroundColor: '#f8f8f8',
            padding: '1em',
          }}
        >
          {props.applicationDescription || <em>No description</em>}
        </Typography>

        <Typography variant="h6" style={{ marginTop: '2em' }}>
          WebHook
        </Typography>

        <TableContainer>
          <Table aria-label="API Keys" maxWidth={1}>
            <TableHead>
              <TableRow>
                <TableCell>WebHook URL</TableCell>
                <TableCell>WebHook Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={1}
                on={1 % 2}
                data-id={1}
                data-application-id={props.applicationId}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ position: 'relative' }}
                >
                  {props.applicationWebHook || <em />}
                </TableCell>
                <TableCell> {props.applicationWebHookCode || <em />}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          variant="h5"
          style={{ marginTop: '2em', marginBottom: '3px' }}
        >
          DGP Release
        </Typography>

        <Typography
          style={{
            border: '1px dashed #f5f5f5',
            backgroundColor: '#f8f8f8',
            padding: '1em',
          }}
        >
          {props.applicationchannelRelease || <em />}
        </Typography>

        <Typography variant="h6" style={{ marginTop: '2em' }}>
          API Keys
        </Typography>
        <TableContainer>
          <Table aria-label="API Keys" maxWidth={1}>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Expires on</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.applicationKeys.map((row, index) => {
                if (row.active === true) {
                  return (
                    <TableRow
                      key={row.name}
                      on={index % 2}
                      // className={props.classes.apiKeyInfo}
                      data-id={row.uuid}
                      data-application-id={props.applicationId}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ position: 'relative' }}
                      >
                        {row.key}
                      </TableCell>
                      <TableCell align="right">{row.description}</TableCell>
                      <TableCell align="right">
                        {formatDate(row.expires_on)}
                      </TableCell>
                      <TableCell align="right">
                        {/* <IconButton
          size="small"
          onClick={e => {
            setConfigureAPIKeyOpen(true);m
          }}
          //disabled={page === 0}
          aria-label="edit api key"
          data-id={row.id}
          data-application-id={props.applicationId}
        >
        {<GetAppIcon />
        </IconButton> */}
                        <Tooltip title="Copy API key information to clipboard">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              props.onApiKeyClick(e);
                            }}
                            // disabled={page === 0}
                            aria-label="edit api key"
                            data-id={row.uuid}
                            data-application-id={props.applicationId}
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download API key information">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              props.onDownloadApiKey(e, {
                                keyId: row.uuid,
                                key: row.key,
                                secret: row.secret,
                              });
                            }}
                            // disabled={page === 0}
                            aria-label="edit api key"
                            data-id={row.uuid}
                            data-application-id={props.applicationId}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Configure API key">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setConfigureAPIKey({
                                id: row.uuid,
                                applicationId: props.applicationId,
                                key: row.key,
                                secret: row.secret,
                                description: row.description,
                              });
                              setConfigureAPIKeyOpen(true);
                            }}
                            // disabled={page === 0}
                            aria-label="edit api key"
                            data-id={row.uuid}
                            data-application-id={props.applicationId}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete API key">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDeleteApiKeyData(row);
                            }}
                            // onClick={props.onDeactivateApiKey}
                            // disabled={page === 0}
                            // aria-label="remove api key"
                            data-id={row.uuid}
                            data-application-id={props.applicationId}
                            style={{ color: red[500], marginLeft: '10px' }}
                            type="submit"
                          >
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Divider style={{ marginTop: 20 }} />
      <div style={{ textAlign: 'right', paddingTop: 10 }}>
        <form
          // onSubmit={props.onCreateApiKey}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setConfigureAPIKey({
              applicationId: props.applicationId,
            });
            setConfigureAPIKeyOpen(true);
          }}
          data-id={props.applicationId}
          style={{ display: 'inline' }}
        >
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            color="primary"
            style={{ marginLeft: '10px' }}
            // className={props.classes.mainActionButtons}
            type="submit"
            size="small"
          >
            Create API Key
          </Button>
        </form>
        <Button
          sx={{ textTransform: 'none' }}
          variant="outlined"
          color="primary"
          onClick={props.onEditApplication}
          size="small"
          style={{ marginLeft: '1em' }}
        >
          Edit Application
        </Button>
        <form
          onSubmit={props.onDelete}
          data-id={props.applicationId}
          style={{ display: 'inline' }}
        >
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            color="primary"
            type="submit"
            size="small"
            style={{ color: 'red', marginLeft: 10 }}
          >
            Delete Application
          </Button>
        </form>
      </div>
      <ConfigureAPIKey
        isOpen={configureAPIKeyOpen}
        title="Configure API Key"
        onClose={(e) => setConfigureAPIKeyOpen(false)}
        actions={[
          {
            eventHandler: (e) => {
              setConfigureAPIKeyProcessing(true);
              if (configureAPIKey.id) {
                props.onAPIKeySave(e, configureAPIKey).then(() => {
                  setConfigureAPIKeyOpen(false);
                  setTimeout(() => {
                    setConfigureAPIKeyProcessing(false);
                  }, 500);
                });
              } else {
                props.onCreateApiKey(e, configureAPIKey).then(() => {
                  setConfigureAPIKeyOpen(false);
                  setTimeout(() => {
                    setConfigureAPIKeyProcessing(false);
                  }, 500);
                });
              }
            },
            description: 'Save',
            disabled:
              configureAPIKeyProcessing ||
              !configureAPIKey.description ||
              !configureAPIKey.description.trim(),
          },
          {
            eventHandler: (e) => {
              setConfigureAPIKeyOpen(false);
            },
            description: 'Discard',
          },
        ]}
      >
        <Grid container spacing={1} style={{ paddingTop: '20px' }}>
          <Grid item xs={12}>
            <TextField
              required
              id="standard-required"
              label="Description"
              variant="outlined"
              fullWidth
              value={configureAPIKey.description}
              onChange={(e) => {
                const { id, applicationId } = configureAPIKey;
                setConfigureAPIKey({
                  id,
                  applicationId,
                  description: e.currentTarget.value,
                });
              }}
              inputProps={{
                'aria-label': 'description',
                'data-id': 'description',
                maxLength: 100,
              }}
            />
          </Grid>
        </Grid>
      </ConfigureAPIKey>
      <ConfimDeleteAPIKeyDialog
        open={deleteApiKeyData.uuid}
        apiKeyData={deleteApiKeyData}
        onContinue={(apiKey) => {
          props.deactivateApiKey(apiKey);
        }}
        onCancel={() => {}}
        onClose={() => {
          setDeleteApiKeyData({});
        }}
      />
    </TopLevelPanel>
  );
};

export default ApplicationListItem;
