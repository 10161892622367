import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import InformationDialog from '../../components/InformationDialog';
import { GlobalContext } from '../../context/store';
import { withStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import { isUserAuthenticated } from 'src/context/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { config } from 'aws-sdk';
import { Alert, Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

const DataUsagePolicy = () => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const firstTime = new URLSearchParams(search).get('firstTime');

  const [
    originalGeneralAcceptanceStatus,
    setoriginalGeneralAcceptanceStatus,
  ] = useState();
  const [dataPoliciesFetched, setdataPoliciesFetched] = useState(false);
  const [consentWithdraw, setconsentWithdraw] = useState(false);
  const [submitable, setsubmitable] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [
    dataUsagePolicyInformations,
    setdataUsagePolicyInformations,
  ] = useState();

  const [
    dataPoliciesAcceptanceStatus,
    setdataPoliciesAcceptanceStatus,
  ] = useState({});

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);

  const RequiredCheckbox = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  useEffect(() => {
    let responseAcceptanceStatuses = {};

    try {
      if (
        Object.keys(dataPoliciesAcceptanceStatus).length == 0 &&
        state.isAuth === true
      ) {
        for (let i = 0; i < state.user.accounts.policies.length; i++) {
          responseAcceptanceStatuses[
            state.user.accounts.policies[i].data_policy.uuid
          ] = state.user.accounts.policies[i].status === 'accepted';
        }
        setdataPoliciesAcceptanceStatus(responseAcceptanceStatuses);
        setdataPoliciesFetched(true);
      }
    } catch (err) {
      console.log('DataPolicy:', err);
    }
  }, [state]);

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const createMarkup = (value) => {
    return { __html: value };
  };

  const handleChange = (e) => {
    const checked = e.target.checked;
    const attributeItemID = e.target.getAttribute('data-datapolicyid');

    let currentStates = dataPoliciesAcceptanceStatus;
    currentStates[attributeItemID] = checked;

    setdataPoliciesAcceptanceStatus(currentStates);

    setdataPoliciesAcceptanceStatus({
      ...dataPoliciesAcceptanceStatus,
    });

    setdataPoliciesFetched(true);
    //console.log('currentStates', currentStates);

    setsubmitable(true);
  };

  const createPolicies = () => {
    let items = [];

    state?.user?.accounts?.policies?.forEach((policy, index) => {
      const labelText = policy.data_policy.acceptance_action_title;
      const CheckboxComponent = policy.data_policy.is_acceptance_required
        ? RequiredCheckbox
        : Checkbox;

      items.push(
        <Card
          style={{ marginTop: index === 0 ? 'auto' : '10px' }}
          key={policy.data_policy.uuid}
        >
          {index > 0 && <Divider />}
          <CardHeader title={policy.data_policy.title} />
          <CardContent>
            <p
              dangerouslySetInnerHTML={createMarkup(
                policy.data_policy.contents
              )}
            />
            {policy.data_policy.acceptance_action_title !== '' && (
              <FormControlLabel
                value="checkedA"
                control={
                  <CheckboxComponent
                    color="primary"
                    inputProps={{
                      'aria-label': 'Checkbox A',
                      'data-datapolicyid': policy.data_policy.uuid,
                    }}
                    checked={
                      dataPoliciesAcceptanceStatus[policy.data_policy.uuid]
                    }
                    disabled={
                      dataPoliciesAcceptanceStatus[policy.data_policy.uuid] &&
                      !policy.data_policy.is_withdrawable
                    }
                    onChange={handleChange}
                  />
                }
                label={labelText}
                labelPlacement="start"
              />
            )}
          </CardContent>
        </Card>
      );
    });
    return items;
  };

  const handleSignOut = async (e) => {
    setconsentWithdraw(false);
    await Auth.signOut();
    navigate(`${process.env.REACT_APP_ROOT_PATH}/login`);
  };

  const handleShowsnack = () => {
    setOpenSnack(!openSnack);
  };

  const handleAcceptAndContinueSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    let payloadItem = [];
    let ShouldRedirect = false;

    const dataCurrentUser = await Auth.currentSession();

    Object.keys(dataPoliciesAcceptanceStatus).forEach((key) => {
      payloadItem.push({
        data_policy_uuid: key,
        status:
          dataPoliciesAcceptanceStatus[key] === true ? 'accepted' : 'rejected',
      });
    });

    let payload = [
      {
        op: 'replace',
        path: '/policies',
        value: payloadItem,
      },
    ];

    try {
      // setconsentWithdraw(true);
      axios
        .patch('accounts/' + state.user.accounts.uuid, payload, {})
        .then((response) => {
          handleShowsnack();
          for (let i = 0; i < response?.data?.policies?.length; i++) {
            if (
              response.data?.policies[i].data_policy.is_acceptance_required ===
                true &&
              response.data?.policies[i].data_policy.is_withdrawable === true
            ) {
              if (response.data?.policies[i].status === 'rejected') {
                setoriginalGeneralAcceptanceStatus(false);
                setconsentWithdraw(true);
              } else {
                ShouldRedirect = true;
              }
            } else {
              ShouldRedirect = true;
            }
          }

          if (ShouldRedirect === true) {
            if (firstTime === '1') {
              navigate(`${process.env.REACT_APP_ROOT_PATH}/welcome`, {
                replace: true,
              });
            }
          }
        });
    } catch (err) {
      // console.log('DataPolicy:', err);
    }

    //console.log('DataPolicy:', err);
  };

  const submitButtonAreaStyles = {
    textAlign: 'right',
    paddingTop: 10,
  };

  const handleClick = (newStat) => () => {
    setState({ open: true, ...newState });
  };
  return (
    <Box
      sx={{
        backgroundColor: '#eeeeee',
        py: 2,
        px: 2,
      }}
    >
      <Helmet>
        <title>Data Policy | Didimo</title>
      </Helmet>
      <Card>
        <CardContent>
          {dataPoliciesFetched === true && (
            <form>
              {createPolicies()}

              <Divider />
              <div style={submitButtonAreaStyles}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ textTransform: 'none' }}
                  disabled={!submitable}
                  onClick={handleAcceptAndContinueSubmit}
                >
                  Accept and continue
                </Button>
              </div>
            </form>
          )}
          <InformationDialog
            open={consentWithdraw}
            title={'Consent withdrawal'}
            message={
              <Typography>
                Sorry you have decided to withdraw your consent for Didimo to
                use your Likeness Data, without this we cannot provide our
                services and your account will be on hold. We hope to see you
                back soon!
              </Typography>
            }
            onClose={handleSignOut}
          />
        </CardContent>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={openSnack}
          key={'bottom' + 'center'}
          onClose={handleShowsnack}
        >
          <Alert severity="success" elevation={6} variant="filled">
            Your terms have been saved
          </Alert>
        </Snackbar>
      </Card>
    </Box>
  );
};

export default DataUsagePolicy;
