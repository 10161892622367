import {
  CHANGE_DEVELOPER,
  GET_DATA_AFTER_REFRESH,
  INITIAL_STATE,
  SAVE_USER_LOGIN,
  USER_LOGOUT,
  SET_ERROR_MESSAGE,
  UPDATE_STATUS_MANAGER,
  ADD_STATUS_MANAGER,
  REMOVE_STATUS_MANAGER,
  INIT_STATUS_MANAGER,
  GOTO_USER_AUTHENTICATED_PAGE,
  ADD_DOWNLOAD_STATUS_MANAGER,
  REMOVE_DOWNLOAD_STATUS_MANAGER,
  ADD_TO_CART,
  MARKET_PLACE,
  SET_NOTIFICATION_DATA,
} from './constants';

export const reducer = (state, action) => {
  const { type, payload } = action;
  let newData = [];
  switch (type) {
    case SAVE_USER_LOGIN:
      return {
        ...state,
        loading: false,
        user: payload,
        isAuth: true,
        isDeveloper: payload.isDeveloper,
        cartData: payload.cartData,
        marketPlaceData: payload.marketPlaceData,
        notificationData: payload.notificationData,
      };
    case INITIAL_STATE:
      return payload;
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        user: {},
        isAuth: false,
      };
    case GOTO_USER_AUTHENTICATED_PAGE:
      return {
        ...state,
        loading: false,
        user: payload,
        isAuth: true,
        isDeveloper: payload.isDeveloper,
      };
    case CHANGE_DEVELOPER:
      return {
        ...state,
        loading: false,
        isDeveloper: payload.isDeveloper,
        isAuth: true,
      };
    case SET_ERROR_MESSAGE:
      return payload;

    case UPDATE_STATUS_MANAGER:
      try {
        newData = [...state.statusManager];
        const findIndex = newData.findIndex((p) => p.id === payload.id);
        if (findIndex > -1) {
          newData[findIndex] = payload;
          return {
            ...state,
            statusManager: newData ? newData : [],
          };
        } else {
          return {
            ...state,
          };
        }
      } catch (e) {
        return {
          ...state,
        };
      }

    case ADD_STATUS_MANAGER:
      try {
        return {
          ...state,
          statusManager: Object.keys(state.downloadStatusManager).length
            ? [...payload, ...state.downloadStatusManager]
            : payload,
        };
      } catch (e) {
        return {
          ...state,
        };
      }

    case ADD_DOWNLOAD_STATUS_MANAGER:
      try {
        return {
          ...state,
          statusManager: [...state.statusManager, payload],
          downloadStatusManager: payload,
        };
      } catch (e) {
        return {
          ...state,
        };
      }

    case REMOVE_STATUS_MANAGER:
      try {
        newData = [...state.statusManager];
        newData = newData.filter((p) => p.id !== payload.id);
        return {
          ...state,
          statusManager: newData ? newData : [],
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    case REMOVE_DOWNLOAD_STATUS_MANAGER:
      try {
        return {
          ...state,
          downloadStatusManager: {},
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    case INIT_STATUS_MANAGER:
      try {
        return {
          ...state,
          statusManager: payload,
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    case ADD_TO_CART:
      try {
        return {
          ...state,
          cartData: payload,
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    case MARKET_PLACE:
      try {
        return {
          ...state,
          marketPlaceData: payload,
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    case SET_NOTIFICATION_DATA:
      try {
        return {
          ...state,
          notificationData: payload,
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    default:
      return state;
  }
};
