import React, { useContext, useEffect, useCallback } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import './App.css';
import routes from './routes';
import theme from './theme';
import GlobalStyles from './components/GlobalStyles';

const App = () => {
  const content = useRoutes(routes);
  axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;
  axios.interceptors.request.use(async (config) => {
    const authData = await Auth.currentSession();

    if (config.method === 'post' && config.url === 'accounts') {
      config.headers = {
        Authorization: `Bearer ${authData.accessToken.jwtToken}`,
        'DIDIMO-ID-TOKEN': `${authData.idToken.jwtToken}`,
        'Didimo-Platform': `${process.env.REACT_APP_PLATFORM}`,
        'Didimo-Platform-Version': `${process.env.REACT_APP_VERSION}`,
      };
    } else {
      if (
        (config.method === 'get' && config.url.includes('front_png')) ||
        config.url.includes('results')
      ) {
        config.headers = {
          Authorization: `Bearer ${authData.accessToken.jwtToken}`,
          'DIDIMO-FOLLOW-URI': 'false',
          'Didimo-Platform': `${process.env.REACT_APP_PLATFORM}`,
          'Didimo-Platform-Version': `${process.env.REACT_APP_VERSION}`,
        };
      } else {
        switch (config.url) {
          case '/didimo_generation_templates':
            config.headers = {
              Authorization: `Bearer ${authData.accessToken.jwtToken}`,
              'Didimo-Platform': `${process.env.REACT_APP_PLATFORM}`,
              'Didimo-Platform-Version': `${process.env.REACT_APP_VERSION}`,
              'Didimo-DGP': `${config.headers['Didimo-DGP']}`,
            };
            break;

          default:
            config.headers = {
              Authorization: `Bearer ${authData.accessToken.jwtToken}`,
              'Didimo-Platform': `${process.env.REACT_APP_PLATFORM}`,
              'Didimo-Platform-Version': `${process.env.REACT_APP_VERSION}`,
            };
        }
      }
    }

    return config;
  });

  return (
    <StyledEngineProvider injectFirst>
      <StyledThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {content}
        </ThemeProvider>
      </StyledThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
