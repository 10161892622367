import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import {
  Backdrop,
  Card,
  CircularProgress,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import {
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import axios from 'axios';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import queryString from 'query-string';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InvoiceTable from '../../components/InvoiceTable';
import SuccessSnack from '../../components/SuccessSnack';
import CheckoutDialog from './CheckoutDialog';
import ErrorSnack from '../../components/ErrorSnack';
import ConfirmDialog from '../../components/ConfirmDialog';
import { GlobalContext } from 'src/context/store';
import { getData, postData } from '../../helpers/dataFetching';
import CookieBase from 'src/components/CookieBase';
import { Alert } from '@mui/lab';
import { Box, Link } from '@mui/material';
import { isUserAuthenticated } from '../../context/actions';

const noDataArea = {
    textAlign: 'center',
  },
  noDataText = {
    fontWeight: 'bold',
    fontSize: 20,
  };
const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPUBLISHABLEKEY);

const Orders = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [checkoutClientSecret, setCheckoutClientSecret] = useState(null);
  const [invoicesLoaded, setInvoicesLoaded] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState({
    open: false,
    message: '',
  });
  const [checkoutOrder, setCheckoutOrder] = useState(null);
  const [checkoutOpen, setCheckoutOpen] = useState(false);

  const [paymentSuccessMessage, setPaymentSuccessMessage] = useState({
    open: false,
    message: '',
  });
  const [invoiceCanceled, setInvoiceCanceled] = useState('');
  const [cancelOrder, setCancelOrder] = useState({});
  const [cancelingOrder, setCancelingOrder] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [pendingOrders, setDdmoStatus] = useState();
  const [isCheckoutError, setIsCheckoutError] = useState(false);

  let ordersRefresher = null;

  const setOrderRefresher = () =>
    (ordersRefresher = setInterval(() => {
      loadOrders(true);
    }, 3000));

  const loadOrders = useCallback(
    async (silently) => {
      setInvoicesLoaded(silently);
      const invoiceStates = {
        0: 'pending',
        1: 'pending',
        2: 'paid',
        3: 'pending',
        4: 'canceled',
      };

      try {
        const data = await getData(
          `accounts/${state.user.accounts.uuid}/orders`
        );
        setTimeout((e) => {
          setInvoicesLoaded(true);
        }, 1000);
        setInvoiceData(data.orders);
        if (
          !data ||
          (data.orders.some((item) => item.status !== 2) && !ordersRefresher)
        ) {
          setOrderRefresher();
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    [state]
  );

  const handleHashChange = async (e) => {
    let checkoutId = queryString.parse(location.hash).checkout;
    if (checkoutId) {
      getData(`services/payments/stripe/InvoicesDetails/${checkoutId}`)
        .then((response) => {
          if (response) {
            setCheckoutOpen(true);
            setCheckoutClientSecret(response.paymentClientSecret);
            setCheckoutOrder(checkoutId);
          } else {
            setIsCheckoutError(true);
          }
        })
        .catch((e) => {
          setIsCheckoutError(true);
        });
    } else {
      setCheckoutClientSecret({ checkoutOpen: false, checkoutOrder: null });
    }
  };

  const handleCheckout = (uuid) => {
    setOrderId(uuid);
    navigate(`/orders#checkout=${uuid}`, { replace: true });
    handleHashChange();
  };

  const addToHash = (values) => {
    let hashItems = queryString.parse(location.hash);
    Object.keys(values).forEach((key) => (hashItems[key] = values[key]));
    return queryString.stringify(hashItems);
  };

  const removeFromHash = (keys) => {
    let hashItems = queryString.parse(location.hash);
    const removableKeys = Array.isArray(keys) ? keys : [keys];

    removableKeys.map((key) => delete hashItems[key]);

    return queryString.stringify(hashItems);
  };

  const handleCheckoutClose = () => {
    setCheckoutOpen(false);
    navigate(`/orders`, { replace: true });
  };
  const routeToCheckout = (orderId) => {
    setTimeout(() => {
      navigate(`#${addToHash({ checkout: orderId })}`);
    }, 100);
  };

  let pageContents;
  if (invoicesLoaded) {
    if (invoiceData?.length === 0) {
      pageContents = (
        <Fragment>
          <Card sx={{ margin: 2 }}>
            <CardHeader title="Orders" />
            <CardContent>
              <div style={noDataArea}>
                <AttachMoneyIcon style={{ fontSize: 200 }} />
                <p style={noDataText}>There are no orders!</p>
              </div>
            </CardContent>
          </Card>
        </Fragment>
      );
    } else {
      pageContents = (
        <Card sx={{ margin: 2 }}>
          <CardHeader title="Orders" />
          <CardContent>
            <InvoiceTable
              invoiceData={invoiceData}
              onCancelOrder={(orderId) => {
                setCancelOrder({ id: orderId });
              }}
              onCheckOut={handleCheckout}
            />
          </CardContent>
        </Card>
      );
    }
  }

  useEffect(() => {
    if (state?.isAuth === true) {
      loadOrders().then(() => handleHashChange());
      return () => {
        if (ordersRefresher) {
          clearInterval(ordersRefresher);
        }
      };
    }
  }, [state]);

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'orders'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    getData('accounts/default/status').then((res) => {
      setDdmoStatus(res?.pending_orders?.length);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Orders | Didimo</title>
      </Helmet>

      <Fragment>
        <Box
          sx={{
            px: 2,
            pt: 0,
          }}
        ></Box>
        <Elements stripe={stripePromise} options={{ locale: 'en_US' }}>
          {pageContents}
          <CheckoutDialog
            open={checkoutOpen}
            onClose={handleCheckoutClose}
            orderId={checkoutOrder}
            checkoutClientSecret={checkoutClientSecret}
            ordersData={invoiceData}
            onCheckoutSuccess={(order) => {
              removeFromHash('checkout');
              handleCheckoutClose();
              setPaymentSuccessMessage({ open: true });
            }}
            onCheckoutError={(error) =>
              setPaymentErrorMessage({ open: true, message: error.message })
            }
            onCancel={(orderId) => {
              setCancelOrder({ id: orderId });
              handleCheckoutClose();
            }}
          />
          <ConfirmDialog
            open={cancelOrder.id}
            title={'Delete Order'}
            message={
              <Typography style={{ color: 'red' }}>
                This action will cancel your order. Do you want to continue?
              </Typography>
            }
            continueEnabled={!cancelingOrder}
            onClose={() => {
              setCancelOrder({});
              setCancelingOrder(false);
            }}
            onContinue={async (item) => {
              setCancelingOrder(true);
              const response = await postData(
                `${process.env.REACT_APP_API_ROOT}services/payments/stripe/CancelOrder`,
                { order_id: cancelOrder.id }
              );
              setCancelOrder({});
              setCancelingOrder(false);
              setInvoiceCanceled(
                'Invoice canceling request succeeded. The status should be updated soon.'
              );
            }}
            onCancel={() => {
              setCancelOrder({});
              setCancelingOrder(false);
              setCheckoutOpen(true);
              navigate(`/orders#checkout=${orderId}`, { replace: true });
            }}
          />
        </Elements>
        <ErrorSnack
          open={isCheckoutError}
          onClose={() => setIsCheckoutError(false)}
          message={'There is an error on Checkout process'}
        />
        <ErrorSnack
          open={paymentErrorMessage.open}
          onClose={(e) => {
            setPaymentErrorMessage({ open: false, message: '' });
          }}
          message={paymentErrorMessage.message}
        />
        <SuccessSnack
          open={paymentSuccessMessage.open}
          onClose={(e) => {
            setPaymentSuccessMessage({ open: false, message: '' });
          }}
          message={
            'Your payment is beeing processed. Your new package will be available soon!'
          }
        />
        <SuccessSnack
          open={invoiceCanceled}
          onClose={(e) => {
            setInvoiceCanceled('');
          }}
          message={invoiceCanceled}
        />
        <Backdrop open={!invoicesLoaded} className={`this should be className`}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CookieBase />
      </Fragment>
    </>
  );
};
export default Orders;
