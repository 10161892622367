import React, { useContext, useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Button } from '@material-ui/core';
import TemplateItem from '../../components/Template/TemplateItem';
import { Helmet } from 'react-helmet';
import ManageTemplate from '../../components/Template/ManageTemplate';
import axios from 'axios';
import { isUserAuthenticated } from '../../context/actions';
import { GlobalContext } from '../../context/store';
import { useNavigate } from 'react-router-dom';

const Templates = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [editData, setEditData] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const fetchTemplates = async (page = currentPage) => {
    setPageData(null);
    setCurrentPage(page);
    setIsLoading(true);
    try {
      await axios.get(`/didimos/releases`).then((res) => {
        if (res.data.length) {
          let UUID = '';
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].dgp_version_uuid) {
              UUID += '&dgp_uuid=' + res.data[i].dgp_version_uuid;
            }
          }

          axios
            .get(
              `didimo_generation_templates?is_active=true&page_size=10&page=${parseInt(
                page + 1
              )}${UUID}`
            )
            .then((response) => {
              setPageData(response.data);
              setIsLoading(false);
            });
        }
      });
    } catch (e) {}
  };
  useEffect(() => {
    if (state?.isAuth === true) {
      fetchTemplates(currentPage);
    }
  }, [state]);

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'templates'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleEditTemplateData = (uuid) => {
    const editData = pageData?.didimo_generation_templates?.find(
      (f) => f.uuid === uuid
    );
    setOpen(true);
    setEditData(editData);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Manage Templates | Didimo</title>
      </Helmet>
      <div className="cardTemplate" style={{ padding: '20px 20px 40px' }}>
        <Card style={{ marginTop: '2em' }} width="1">
          <CardHeader
            title="Templates"
            action={
              <Button
                sx={{ textTransform: 'none' }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
                size="small"
              >
                Create Template
              </Button>
            }
          />
          <CardContent>
            <TemplateItem
              showTemplates={fetchTemplates}
              isLoading={isLoading}
              maxRowPerPage={10}
              onPageChange={(event, page) => {
                fetchTemplates(page);
              }}
              page={currentPage}
              pageData={pageData}
              onEdit={handleEditTemplateData}
            />
          </CardContent>
        </Card>

        <ManageTemplate
          isOpen={open}
          onClose={handleClose}
          showTemplates={fetchTemplates}
          editData={editData}
        />
      </div>
    </React.Fragment>
  );
};

export default Templates;
