import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Card, CardHeader, CardContent } from '@mui/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router';
import { GlobalContext } from '../../context/store';
import { encode as base64_encode } from 'base-64';
import { useState } from 'react';
import Iframe from 'react-iframe';
import { Backdrop, CircularProgress } from '@material-ui/core';
import './PreviewDidimo.css';
import PersonIcon from '@mui/icons-material/Person';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import LoopIcon from '@mui/icons-material/Loop';


const useStyles = makeStyles(() => ({
  media: {
    cursor: 'pointer',
    height: 225,
    maxWidth: '190px',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    margin: '10px',
    width: '100%',
  },
  btn: {
    marginRight: '5px',
  },
  backArrow: {
    color: '#2a0651',
    fontSize: '23px',
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
}));

const PreviewDidimo = () => {
  const { state } = useContext(GlobalContext);
  const classes = useStyles();

  const { key } = useParams();
  const [didimoDetails, setDidimoDetails] = useState({});
  const [avatarStructure, setAvatarStructure] = useState(null);
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const iFrameRef = useRef();
  const [didimoFrame, setDidimoFrame] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    loadDidimoDetails();
    Preview();
  }, []);


  useEffect(async () => {
    let { DidimoFrame } = await import(/* webpackIgnore: true */ process.env.REACT_APP_DIDIMO_FRAME);
    setDidimoFrame(new DidimoFrame(iFrameRef.current.contentWindow));
  }, [iFrameRef]);

  

  const setHeadCamera = async () => {
    didimoFrame.setCamera("head_camera");
  }
  const setBodyCamera = async () => {
    didimoFrame.setCamera("body_camera");
  }

  const loadDidimoDetails = () => {
    axios
      .get(`didimos/${key}`)
      .then((response) => {
        setAvatarStructure(response.data["meta_data"]?.find((meta) => meta["name"] === "avatar_structure")["value"]);
        setDidimoDetails(response);
      });
  }

  const Preview = () => {
    axios
      .get('didimos/' + key + '/results/gltf', {
        headers: {
          Authorization: `Bearer ${state.user?.accessToken}`,
          'didimo-follow-uri': 'false',
          'access-control-allow-origin': 'https://app.didimo.co',
          'content-type': 'application/json',
        },
      })
      .then(async (response) => {
        let encodedURL =
          process.env.REACT_APP_PREVIEW +
          '#didimo=' +
          base64_encode(response.data.uri);
        await setUrl(encodedURL);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>didimo Preview | Didimo</title>
      </Helmet>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Box
        className={'didimo-detail-container'}
        sx={{
          backgroundColor: 'transparent',
          py: 3,
          px: 3,
        }}
        style={{ height: '100%' }}
      >
        <Card style={{ height: '100%' }}>
          <CardHeader
            title={
              <div>
                <ArrowBackIcon
                  className={classes.backArrow}
                  onClick={() => navigate(-1)}
                />
                Didimo Preview
                {key ? ' - ' + key : 'N/A'}
              </div>
            }
          />
          <CardContent style={{ height: '95%' }} className={"viewer-container"}>
            <div>
              <div className="camera-face" title="Head Camera">{(avatarStructure === "full-body" && <PersonIcon onClick={setHeadCamera} />) || <LoopIcon onClick={setHeadCamera} />}</div>
              {avatarStructure === "full-body" && <div className="camera-body" title="Body Camera"><AccessibilityIcon onClick={setBodyCamera} /></div>}
            </div>
            <iframe
              src={url}
              //url={url}
              ref={iFrameRef}
              width="100%"
              height="100%"
              display="block"
              position="relative"
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default PreviewDidimo;
