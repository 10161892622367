import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Grid,
} from '@material-ui/core';
import WebCam from '../DidimosGenerate/ReactWebcam';
import ImageCroper from '../ImageCroper';

const CaptureModal = (props) => {
  const {
    isOpen,
    setFiles,
    closeModal,
    setSrc,
    setIsOpen,
    modelType,
    files,
    src,
    handleChangefile,
  } = props;

  const onClose = () => {
    closeModal();
    setSrc(null);
    setFiles(null);
  };

  return (
    <div>
      <Dialog
        className="capturePhoto"
        open={isOpen}
        closeAfterTransition
        maxWidth="xxl"
        aria-labelledby="configure-api-key-title"
      >
        <DialogTitle
          id="capture-title"
          style={{ padding: '0 24px 20px 0', fontSize: '16px' }}
        >
          {' '}
          Capture Photo
        </DialogTitle>
        <stack className="p-0">
          <Grid container className="p-0">
            <Grid item>
              <WebCam
                setFiles={setFiles}
                closeModal={closeModal}
                setIsOpen={setIsOpen}
                setSrc={setSrc}
                className="p-0"
                handleChangefile={handleChangefile}
              />
            </Grid>
            {/* {(src && modelType === 2) && (
              <Grid item>
                <ImageCroper
                  file={files.preview}
                  callback={(file) => {
                    setFiles(file);
                    setIsOpen(false);
                  }}
                />
              </Grid>
            )} */}
          </Grid>
        </stack>
        <DialogActions>
          <Button onClick={onClose} style={{ textTransform: 'capitalize' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CaptureModal;
