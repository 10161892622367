import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import { formatCurrency } from '../../components/Helpers/formaters';
import { useNavigate } from 'react-router-dom';
const CheckoutDialog = (props) => {
  const {
    open,
    onClose,
    onCancel,
    onCheckoutError,
    onCheckoutSuccess,
    orderId,
    ordersData,
    checkoutClientSecret,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const checkoutOrder = orderId
    ? ordersData.find((invoice) => invoice.uuid === orderId)
    : null;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);

    const result = await stripe.confirmCardPayment(checkoutClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      onCheckoutError({ message: result.error.message });
      setProcessing(false);
      //console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        onCheckoutSuccess(orderId);
        setProcessing(false);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  const getProductName = (product) => {
    const name = product[0]?.name.split('-');
    return name[1];
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby="configure-api-key-title"
    >
      {orderId && (
        <React.Fragment>
          <DialogTitle
            id="configure-api-key-title"
            style={{ fontSize: '16px' }}
          >
            Checkout{' '}
            {checkoutOrder.product
              ? checkoutOrder.product.name
              : checkoutOrder.invoice_number}{' '}
            package
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant={'standard'}
                  fullWidth
                  disabled={true}
                  value={
                    checkoutOrder.products.length > 0
                      ? checkoutOrder.products.length > 1
                        ? getProductName(checkoutOrder.products)
                        : checkoutOrder.products[0]?.name
                      : checkoutOrder.product?.name
                  }
                  label="Description"
                />
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    variant={'standard'}
                    fullWidth
                    disabled={true}
                    value={`${formatCurrency(checkoutOrder.base_value)}`}
                    label="Price"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant={'standard'}
                    fullWidth
                    disabled={true}
                    value={`${formatCurrency(checkoutOrder.tax_value || 0)}`}
                    label="Tax value"
                  />
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant={'standard'}
                  fullWidth
                  disabled={true}
                  value={`${formatCurrency(checkoutOrder.value)}`}
                  label="Final price"
                />
              </Grid>
              <Grid item xs={12}>
                <CardSection />
              </Grid>
            </Grid>
          </DialogContent>
        </React.Fragment>
      )}
      <DialogActions>
        <Button
          onClick={() => onCancel(orderId)}
          color="primary"
          style={{
            textTransform: 'capitalize',
            position: 'absolute',
            left: '30px',
          }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          disabled={!stripe || processing}
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          style={{ textTransform: 'capitalize' }}
        >
          Confirm order
        </Button>
        <Button
          onClick={onClose}
          color="primary"
          style={{ textTransform: 'capitalize' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CheckoutDialog.propTypes = {
  className: PropTypes.string,
};

export default CheckoutDialog;
