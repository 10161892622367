import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Alert,
  Grid,
} from '@material-ui/core';
import Amplify, { Auth } from 'aws-amplify';
import './layout/Register.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '../../icons/Facebook';
import GoogleIcon from '../../icons/GoogleIcon.svg';
import AppleIcon from '../../icons/Apple';

const Register = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState('');
  const [termsData, setTermsData] = useState([]);
  const [seletectTerm, setseletectTerm] = useState({
    policy1: false,
    policy2: false,
    policy3: false,
    policy4: false,
  });

  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
      storage: localStorage,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGNOUT,
        responseType: 'code',
      },
    },
  });

  // signInUserSession:null if user isn't verified
  async function signUp(values) {
    if (values) {
      try {
        let terms = [];
        for (var i = 0; i < termsData.length; i++) {
          switch (i) {
            case 0:
              if (seletectTerm.policy1 === true) {
                terms.push(termsData[i].uuid);
              }
              break;
            case 1:
              if (seletectTerm.policy2 === true) {
                terms.push(termsData[i].uuid);
              }
              break;
            case 2:
              if (seletectTerm.policy3 === true) {
                terms.push(termsData[i].uuid);
              }
              break;
            case 3:
              if (seletectTerm.policy4 === true) {
                terms.push(termsData[i].uuid);
              }
              break;
          }
        }
        const username = values.email;
        const { password, firstName, lastName } = values;
        const { user } = await Auth.signUp({
          username,
          password,
          attributes: {
            email: values.email,
            given_name: values.firstName,
            family_name: values.lastName,
            'custom:terms': terms.join(),
          },
        });
        if (user) {
          const hashParam = window.location.hash;
          if (hashParam) {
            const key = hashParam.split('=')[1];
            localStorage.setItem('isSignInWithKey', 1);
            localStorage.setItem('setUpKey', key);
          }
          /*  const formData = new FormData();
          formData.append('email_address', username); */

          var raw = JSON.stringify({
            email_address: username,
          });

          let myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append('Didimo-Platform', process.env.REACT_APP_PLATFORM);
          myHeaders.append(
            'Didimo-Platform-Version',
            process.env.REACT_APP_VERSION
          );

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
          };

          var createCodeURL =
            process.env.REACT_APP_API_ROOT + 'accounts/verifying';

          const res = await fetch(createCodeURL, requestOptions);
          const data = await res.json();

          navigate(`/confirm?username=${encodeURIComponent(username)}`, {
            replace: true,
          });
        }
      } catch (err) {
        setError(err);
      }
    }
  }

  const handleChangeAccordian = (panel) => {
    if (expanded === panel) {
      setExpanded('');
    } else {
      setExpanded(panel);
    }
  };

  const onChangeOption = (e, key) => {
    setseletectTerm((prev) => {
      return { ...prev, [key]: e.target.checked };
    });
  };

  useEffect(() => {


    const hashParam = window.location.hash;
    if (hashParam) {
      const key = hashParam.split('=')[1];
      localStorage.setItem('isSignInWithKey', 1);
      localStorage.setItem('setUpKey', key);
    }


    fetch(`${process.env.REACT_APP_API_ROOT}data_usage_policies`)
      .then((response) => response.json())
      .then((data) =>
        setTermsData(
          data.filter((item) => {
            return (
              item.is_acceptance_required === true ||
              item.title === 'Marketing' ||
              item.is_acceptance_required === false
            );
          })
        )
      );
  }, []);

  const styles = {
    SummeryText: {
      width: '10%',
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
    },
    summeryTitle: {
      color: '#2A0651',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    customCheckBox: {
      padding: '0',
      color: '#2A0651',
    },
  };

  const createAccountWithIcon = () => {
    navigate('/register/finalizeSignup');
  };

  console.log('termsData', termsData);
  return (
    <>
      <Helmet>
        <title>Register | Didimo</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        <Container maxWidth="xs" className={'signup-container'}>
          <Formik
            initialValues={{
              emial: '',
              firstName: '',
              lastName: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email address is required'),
              firstName: Yup.string()
                .max(255)
                .required('First name is required'),
              lastName: Yup.string().max(255).required('Last name is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = 'Password is required';
              } else if (
                !RegExp(
                  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,]).{8,}$'
                ).test(values.password)
              ) {
                errors.password =
                  'Invalid password - Password must contain a lower case letter, an upper case letter, a special character, a number and at least 8 characters';
              }
              if (!values.email) {
                errors.email = 'Email is required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (
                !seletectTerm.policy1 ||
                !seletectTerm.policy3 ||
                !seletectTerm.policy4
              ) {
                errors.policy = '* All fields must be checked';
              }
              return errors;
            }}
            onSubmit={signUp}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h2" className={'main-header'}>
                    {isSubmitting}
                    Create new account
                  </Typography>
                </Box>

                {error && <Alert severity="error">{error.message}</Alert>}

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <TextField
                    style={{ width: '49%' }}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label="First name"
                    margin="normal"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                  <TextField
                    style={{ width: '49%' }}
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label="Last name"
                    margin="normal"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                  />
                </div>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box
                  sx={{ alignItems: 'center', display: 'flex', ml: -1, mt: 3 }}
                >
                  <Typography color="textSecondary" variant="body1">
                    {termsData.map((terms, index) => {
                      const panel = `panel${index + 1}`;
                      return (
                        <Accordion expanded={expanded === panel}>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                onClick={() => handleChangeAccordian(panel)}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={styles.SummeryText}>
                              {terms.type != 'information' && (
                                <Checkbox
                                  required={false}
                                  style={styles.customCheckBox}
                                  name={panel}
                                  value={seletectTerm}
                                  onChange={(e) =>
                                    onChangeOption(e, `policy${index + 1}`)
                                  }
                                />
                              )}
                            </Typography>

                            <Typography
                              sx={styles.summeryTitle}
                              onClick={() => handleChangeAccordian(panel)}
                            >
                              {terms?.title}{' '}
                              {terms?.type === 'requirement' ? ' *' : null}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ borderBottom: '1px solid #2a0651' }}
                          >
                            <Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: terms?.contents,
                                }}
                              />
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Typography>
                </Box>
                {Boolean(errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}
                <Box sx={{ py: 2 }} className={'SpinnerContainer'}>
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className={'signIn-btn'}
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  style={{
                    color: '#2A0651',
                    fontSize: '14px',
                    marginBottom: '10px',
                  }}
                  align="center"
                  color="textSecondary"
                  variant="body1"
                >
                  or Sign in with
                </Typography>
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item sx={{ py: 2 }}>
                    <Button
                      className={'iconStyleBtn'}
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Facebook' })
                      }
                    >
                      {' '}
                      <FacebookIcon />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={'iconStyleBtn'}
                      size="large"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Google' })
                      }
                    >
                      {' '}
                      <img src={GoogleIcon} alt={'google-icon'} />
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      className={'iconStyleBtn'}
                      size="large"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'SignInWithApple' })
                      }
                    >
                      <AppleIcon />{' '}
                    </Button>
                  </Grid>
                </Grid>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="center"
                >
                  Have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                    underline="hover"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Register;
