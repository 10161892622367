import React from 'react';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';

export default function ConfimDeleteAPIKeyDialog(props) {
  const { open, onContinue, onCancel, onClose, apiKeyData } = props;

  return (
    <ConfirmDeleteDialog
      title="Delete API key"
      message={`This action will delete the API key "${apiKeyData.description}". Are you sure you want to continue?`}
      open={open}
      onContinue={() => {
        onContinue(apiKeyData);
      }}
      onCancel={onCancel}
      onClose={onClose}
      applicationData
    />
  );
}
