import React, { useContext, useEffect, useState } from 'react';
import CodeIcon from '@material-ui/icons/Code';
// import { Redirect } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  CardHeader,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
//import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import appConfig from '../../components/AppConfig';
import { ApplicationListItem } from '../../components/Application';
import SuccessSnack from '../../components/SuccessSnack';
import ConfirmDeletApplicationDialog from '../../components/Application/ConfimDeleteApplicationDialog';
import ConfigureApplication from '../../components/Application/ConfigureApplication';
import { GlobalContext } from 'src/context/store';
import axios from 'axios';
import { getData } from '../../helpers/dataFetching';
import { Link } from '@mui/material';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isUserAuthenticated } from '../../context/actions';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  applicationDetail: {
    flexDirection: 'column',
  },
  deleteButton: {
    backgroundColor: 'red',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  didimoApiArea: {
    marginBottom: theme.spacing(2),
  },
});

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const Applications = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [applicationsIsLoading, setApplicationsIsLoading] = useState(false);
  const [releasesIsLoading, setReleasesIsLoading] = useState(false);
  const [redirectToRoot, setRedirectToRoot] = useState(false);
  const [secretOnClipboard, setSecretOnClipboard] = useState(false);
  const [applicationDeleted, setApplicationDeleted] = useState(false);
  const [apiKeyDeleted, setApiKeyDeleted] = useState(false);
  const [creatingApplication, setCreatingApplication] = useState(false);
  const [applications, setApplications] = useState([]);
  const [editorFields, setEditorFields] = useState({});
  const [deleteApplicationData, setDeleteApplicationData] = useState({});
  const [configureApplication, setConfigureApplication] = useState({});
  const [releaseCollection, setReleaseCollection] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'welcome'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (state?.isAuth === true) {
      if (!state.user.accessToken) {
        setRedirectToRoot(true);
        return;
      }

      setApplicationsIsLoading(true);
      setReleasesIsLoading(true);

      try {
        axios
          .get('accounts/' + state.user.accounts.uuid + '/applications', {
            headers: { Authorization: `Bearer ${state.user.accessToken}` },
          })
          .then((response) => {
            // console.log('response --> ', response, state.user.accounts.uuid);
            return response.data;
          })
          .then((response) => {
            // console.log('setApplications', response);
            setApplicationsIsLoading(false);
            setApplications(response.applications);
          });
      } catch (err) {
        console.log('DataPolicy:', err);
      }

      try {
        axios
          .get('didimos/releases', {
            headers: { Authorization: `Bearer ${state.user.accessToken}` },
          })
          .then((response) => {
            return response.data;
          })
          .then((response) => {
            console.log('setApplications', response);
            setReleasesIsLoading(false);
            setReleaseCollection(response);
          });
      } catch (error) {
        console.log('fetchProfileUUID:', error);
      }
      (async () => {
        try {
          if (state.isAuth === false) {
            dispatch(
              await isUserAuthenticated(navigate, 'developers/applications')
            );
          }
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [state]);

  const handleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setCreatingApplication(false);
    setConfigureApplication({});
    setOpen(false);
  };

  const deleteApplication = (application) => {
    // const credentials = session.credentials;
    try {
      axios
        .delete('applications/' + application.uuid, {
          headers: { Authorization: `Bearer ${state.user.accessToken}` },
          Accept: 'application/json',
          'Content-Type': 'application/json',
        })
        .then((response) => {
          setApplicationsIsLoading(true);
          setReleasesIsLoading(true);

          try {
            axios
              .get('accounts/' + state.user.accounts.uuid + '/applications', {
                headers: { Authorization: `Bearer ${state.user.accessToken}` },
              })
              .then((response) => {
                // console.log('response --> ', response, state.user.accounts.uuid);
                return response.data;
              })
              .then((response) => {
                // console.log('setApplications', response);
                setApplicationsIsLoading(false);
                setApplications(response.applications);
                setReleasesIsLoading(false);
              });
          } catch (err) {
            console.log('DataPolicy:', err);
          }

          /* let applications = applications;
          applications.splice(
            applications.findIndex((elem) => elem.id === application.uuid),
            1
          );
          setApplications(applications); */

          handleClose();
        });
    } catch (error) {
      console.log('fetchProfileUUID:', error);
    }
  };

  const handleOnDelete = (e) => {
    let selectedApp = applications.find(
      (app) => app.uuid === e.target.getAttribute('data-id')
    );

    e.preventDefault();
    e.stopPropagation();
    setDeleteApplicationData(selectedApp);
  };

  const getApiKeyInfo = (apiKeyId, applicationId) => {
    for (const key in applications) {
      if (applications[key].uuid === applicationId) {
        for (const apiIdx in applications[key].api_keys) {
          if (applications[key].api_keys[apiIdx].uuid == apiKeyId) {
            return `Application: ${applications[key].name}\n\nAPI Key "${applications[key].api_keys[apiIdx].description}"\nKey: "${applications[key].api_keys[apiIdx].key}"\nSecret: "${applications[key].api_keys[apiIdx].secret}"`;
          }
        }
      }
    }
  };

  const handleOnDownloadApiKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const apiKeyId = e.currentTarget.getAttribute('data-id');
    const applicationId = e.currentTarget.getAttribute('data-application-id');
    let apiKeyDescription = '';
    let apiKeyData = '';
    for (const key in applications) {
      if (applications[key].uuid == applicationId) {
        for (const apiIdx in applications[key].api_keys) {
          if (applications[key].api_keys[apiIdx].uuid == apiKeyId) {
            apiKeyDescription = applications[key].api_keys[apiIdx].description;
            apiKeyData = `Application: ${applications[key].name}\n\nAPI Key "${applications[key].api_keys[apiIdx].description}"\nKey: "${applications[key].api_keys[apiIdx].key}"\nSecret: "${applications[key].api_keys[apiIdx].secret}"`;
          }
        }
      }
    }
    download(apiKeyDescription, apiKeyData);
  };

  const handleOnApiKeyClick = (e) => {
    const apiKeyId = e.currentTarget.getAttribute('data-id');
    const applicationId = e.currentTarget.getAttribute('data-application-id');
    const apiKeyInfo = getApiKeyInfo(apiKeyId, applicationId);
    navigator.clipboard.writeText(apiKeyInfo);
    setSecretOnClipboard(true);
  };

  const deactivateApiKey = (apiKey) => {
    const apiKeyId = apiKey.uuid;
    try {
      return axios
        .delete(
          'api_keys/' + apiKeyId,
          {},
          {
            headers: { Authorization: `Bearer ${state.user.accessToken}` },
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        )
        .then((response) => {
          setApplicationsIsLoading(true);

          axios
            .get(
              'accounts/' + state.user.accounts.uuid + '/applications',
              {},
              {
                headers: { Authorization: `Bearer ${state.user.accessToken}` },
                Accept: 'application/json',
                'Content-Type': 'application/json',
              }
            )
            .then((response) => {
              //console.log('response--->', response);
              return response.data;
            })
            .then((response) => {
              setApplicationsIsLoading(false);
              setApplications(response.applications);
            });
        });
    } catch (error) {
      //console.log('fetchProfileUUID:', error);
    }
  };

  const handleOnDeactivateApiKey = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const apiKeyId = parseInt(e.currentTarget.getAttribute('data-id'));
    const applicationId = parseInt(
      e.currentTarget.getAttribute('data-application-id')
    );

    try {
      axios
        .post(
          appConfig.apiUris.deactivateApplicationApiKey
            .replace('$appId', applicationId)
            .replace('$apiKeyId', apiKeyId),
          {},
          {
            headers: { Authorization: `Bearer ${state.user.accessToken}` },
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        )
        .then((response) => {
          const applications = applications;
          let application = applications.filter(
            (application) => application.id === applicationId
          )[0];
          const apiKeys = application.apiKeys;

          application.apiKeys = apiKeys.filter(
            (apiKey) => apiKey.id !== apiKeyId
          );
          setApplications(applications);
        });
    } catch (error) {
      console.log('fetchProfileUUID:', error);
    }
  };

  const handleOnCreateApiKey = (e, apiKey) => {
    e.preventDefault();
    e.stopPropagation();

    const creator = axios
      .post(
        'applications/' + apiKey.applicationId + '/api_keys',
        { description: apiKey.description },
        {
          headers: { Authorization: `Bearer ${state.user.accessToken}` },
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      )
      .then((response) => {
        setApplicationsIsLoading(true);
        axios
          .get(
            'accounts/' + state.user.accounts.uuid + '/applications',
            {},
            {
              headers: { Authorization: `Bearer ${state.user.accessToken}` },
            }
          )
          .then((response) => {
            return response.data;
          })
          .then((response) => {
            setApplicationsIsLoading(false);
            setApplications(response.applications);
          });
      });
    return creator;
  };

  const handleOnAPIKeySave = (e, apiKey) => {
    const updater = axios
      .put(
        'api_keys/' + apiKey.id,
        { description: apiKey.description },
        {
          headers: { Authorization: `Bearer ${state.user.accessToken}` },
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      )
      .then((response) => {
        setApplicationsIsLoading(true);

        axios
          .get('accounts/' + state.user.accounts.uuid + '/applications', {
            headers: { Authorization: `Bearer ${state.user.accessToken}` },
            Accept: 'application/json',
            'Content-Type': 'application/json',
          })
          .then((response) => {
            return response.data;
          })
          .then((response) => {
            setApplicationsIsLoading(false);
            setApplications(response.applications);
          })
          .catch(function (error) {
            console.log(error);
          });

        return response.data;
      });
    return updater;
  };

  const handleOnSaveApplication = (application) => {
    let baseUrl;
    let methodType = 'POST';
    let url = '';

    if (application.webhookURL === 'https://') {
      application.webhookURL = null;
    }

    let releaseCode = null;
    if (application.releaseName != '') {
      for (const idx in releaseCollection) {
        if (releaseCollection[idx].name == application.releaseName) {
          releaseCode = releaseCollection[idx].code;
        }
      }
    }

    if (application.id) {
      url = axios.put(
        'applications/' + application.id,
        {
          name: application.name,
          description: application.description,
          webhook_url: application.webhookURL,
          release_code: application.releaseCode,
        },
        {
          headers: { Authorization: `Bearer ${state.user.accessToken}` },
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      );
      // methodType = "PUT";
    } else {
      url = axios.post(
        'accounts/' + state.user.accounts.uuid + '/applications',
        {
          name: application.name,
          description: application.description,
          webhook_url: application.webhookURL,
          release_code: application.releaseCode,
        },
        {
          headers: { Authorization: `Bearer ${state.user.accessToken}` },
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      );
    }

    return url
      .then((response) => {
        // this.setState({ applicationsIsLoading: true });
        setApplicationsIsLoading(true);
        axios
          .get('accounts/' + state.user.accounts.uuid + '/applications', {
            headers: { Authorization: `Bearer ${state.user.accessToken}` },
            Accept: 'application/json',
            'Content-Type': 'application/json',
          })
          .then((response) => {
            return response.data;
          })
          .then((response) => {
            setApplicationsIsLoading(false);
            setApplications(response.applications);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let pageContents = [];
  const noDataArea = {
      textAlign: 'center',
    },
    noDataText = {
      fontWeight: 'bold',
      fontSize: 20,
    };

  if ((!applicationsIsLoading && !releasesIsLoading) || true) {
    if (applications.length === 0) {
      pageContents = (
        <React.Fragment>
          <div style={noDataArea}>
            <CodeIcon style={{ fontSize: 200 }} />
            <p style={noDataText}>
              There are no applications configured for this account. Go ahead
              and add one!
            </p>
          </div>
        </React.Fragment>
      );
    } else {
      for (let i = 0; i < applications.length; i++) {
        var applicationRelease = applications[i].release;
        var confRelease = applicationRelease
          ? releaseCollection.find((r) => r.code === applicationRelease.code)
          : null;

        pageContents.push(
          <ApplicationListItem
            applicationId={applications[i].uuid}
            applicationName={applications[i].name}
            applicationDescription={applications[i].description}
            applicationKeys={applications[i].api_keys || []}
            applicationWebHook={applications[i].webhook_url || []}
            applicationWebHookCode={applications[i].webhook_code || []}
            applicationchannelRelease={
              (applicationRelease &&
                applicationRelease.name +
                  (confRelease?.status === 'coming-soon'
                    ? ' - Coming soon'
                    : confRelease?.status === 'obsolete'
                    ? ' - Obsolete'
                    : '')) ||
              []
            }
            onDelete={handleOnDelete}
            onCreateApiKey={handleOnCreateApiKey}
            onDownloadApiKey={handleOnDownloadApiKey}
            onDeactivateApiKey={handleOnDeactivateApiKey}
            onEditApplication={() => {
              setConfigureApplication(applications[i]);
              setOpen(true);
            }}
            deactivateApiKey={(apiKey) => {
              deactivateApiKey(apiKey).then((response) => {
                setApiKeyDeleted(true);
                // this.setState({ apiKeyDeleted: true });
              });
            }}
            onAPIKeySave={handleOnAPIKeySave}
            onApiKeyClick={(e) => handleOnApiKeyClick(e)}
          />
        );
      }
    }
  }

  const [pendingOrders, setDdmoStatus] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    getData('accounts/default/status').then((res) => {
      setDdmoStatus(res?.pending_orders.length);
    });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Applications | Didimo</title>
      </Helmet>
      <div
        style={{
          background: '#eeeeee',
          paddingLeft: '20px',
          display: 'inline-block',
          width: '100%',
          paddingTop: '20px',
          paddingRight: '20px',
          minHeight: '100vh',
          paddingBottom: '40px',
        }}
      >
        {pendingOrders > 0 && (
          <Alert
            severity="warning"
            style={{ border: '1px solid #ff9800', backgroundColor: 'white' }}
          >
            You have pending invoices. Please visit{' '}
            <Link to="/orders">Orders</Link> to proceed with the payment.
          </Alert>
        )}
        <Card style={{ marginBottom: '16px', marginTop: 2 }}>
          <CardHeader title="Didimo API" />
          <CardContent>
            To start integrating with Didimo, point your calls to the available
            endpoints at{' '}
            <a
              href={`${process.env.REACT_APP_API_ROOT}health/didimos`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {process.env.REACT_APP_API_ROOT}
            </a>
            .<br /> You can find our documentation at{' '}
            <a
              href={process.env.REACT_APP_DEV_PORTAL_ROOT}
              target="_blank"
              rel="noopener noreferrer"
            >
              {process.env.REACT_APP_DEV_PORTAL_ROOT}
            </a>
            .
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title="Applications"
            action={
              <Button
                sx={{ textTransform: 'none' }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setConfigureApplication({
                    id: '',
                    name: '',
                    description: '',
                    webhook_url: '',
                  });
                  setOpen(true);
                }}
                size="small"
              >
                Create Application
              </Button>
            }
          />
          {console.log('pageContents', pageContents)}
          <CardContent className="cardContentBox">{pageContents}</CardContent>
        </Card>

        <ConfigureApplication
          isOpen={open}
          applicationData={configureApplication}
          releaseCollection={releaseCollection}
          onClose={handleClose}
          onSave={(application) => {
            return handleOnSaveApplication(application).then(() => {
              return handleClose();
            });
          }}
          onDiscard={handleClose}
        />

        <SuccessSnack
          open={secretOnClipboard}
          onClose={(e) => {
            setSecretOnClipboard(false);
            // this.setState({
            //   secretOnClipboard: false,
            // });
          }}
          message={'The secret was copied to clipboard.'}
        ></SuccessSnack>
        <Backdrop open={applicationsIsLoading || releasesIsLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <SuccessSnack
          open={applicationDeleted}
          onClose={(e) => {
            setApplicationDeleted(false);
            // this.setState({
            //   applicationDeleted: false,
            // });
          }}
          message={'The application was deleted.'}
        ></SuccessSnack>
        <SuccessSnack
          open={apiKeyDeleted}
          onClose={(e) => {
            setApiKeyDeleted(false);
            // this.setState({
            //   apiKeyDeleted: false,
            // });
          }}
          message={'The API key was deleted.'}
        ></SuccessSnack>
        <ConfirmDeletApplicationDialog
          open={deleteApplicationData.uuid}
          applicationData={deleteApplicationData}
          onContinue={(application) => {
            deleteApplication(application);
            setApplicationDeleted(true);
            // this.setState({ applicationDeleted: true });
          }}
          onCancel={() => {
            //this.setState({ deleteApplicationData: {} });
          }}
          onClose={() => {
            setDeleteApplicationData({});
            // this.setState({ deleteApplicationData: {} });
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default Applications;
