import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const DeleteTemplateModel = (props) => {
  const { isOpen, onClose, handleDeleteTemplate } = props;
  return (
    <Dialog
      className="edit_modal delete_modal"
      open={isOpen}
      aria-labelledby="configure-api-key-title"
    >
      <DialogTitle id="configure-api-key-title" className="font_fam">
        Delete Template
      </DialogTitle>
      <DialogContent>
        <Typography style={{ color: 'red' }}>
          This action will delete the selected template. Do you want to
          continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className="font_fam"
          onClick={handleDeleteTemplate}
        >
          Yes
        </Button>
        <Button color="primary" className="font_fam" onClick={onClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTemplateModel;
