import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const Version257 = (props) => {
  const { isEnableElement } = props;
  return (
    <div className="version_2_5_7_container">
      <div className="element-wrapper">
        <div className="element-title">
          <h4>Input Type</h4>
        </div>
        <div className="ddm-elements input-type-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('input_type', 'photo')}
                />
              }
              label={'Photo'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('input_type', 'rgbd')}
                />
              }
              label={'Photo + Depth'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Transfer Formats</h4>
        </div>
        <div className="ddm-elements transfer-formats-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('transfer_formats', 'fbx')}
                />
              }
              label={'FBX'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('transfer_formats', 'gltf')}
                />
              }
              label={'glTF'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Animation Capability</h4>
        </div>
        <div className="ddm-elements animation-capability-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'simple_poses')}
                />
              }
              label={'Simple Poses: blink/expressions'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'arkit')}
                />
              }
              label={'ARKit compatible'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'oculus_lipsync')}
                />
              }
              label={'Oculus Lipsync compatible'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'aws_polly')}
                />
              }
              label={'Animation Polly compatible'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Max texture dimension</h4>
        </div>
        <div className="ddm-elements max-tex-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('max_texture_dimension', '2048')}
                />
              }
              label={'2k'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('max_texture_dimension', '1024')}
                />
              }
              label={'1k'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('max_texture_dimension', '512')}
                />
              }
              label={'512'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Avatar Structure</h4>
        </div>
        <div className="ddm-elements avatar-structure-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('avatar_structure', 'head-only')}
                />
              }
              label={'Head Only'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('avatar_structure', 'full-body')}
                />
              }
              label={'Full Body'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Garment</h4>
        </div>
        <div className="ddm-elements garment-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('garment', 'none')}
                />
              }
              label={'None'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('garment', 'casual')}
                />
              }
              label={'Casual'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('garment', 'sporty')}
                />
              }
              label={'Sporty'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Gender</h4>
        </div>
        <div className="ddm-elements gender-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('gender', 'auto')}
                />
              }
              label={'Auto'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('gender', 'male')}
                />
              }
              label={'Male'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('gender', 'female')}
                />
              }
              label={'Female'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Options</h4>
        </div>
        <div className="ddm-elements">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('remove_beard', 'true')}
                />
              }
              label={'Remove Beard (experimental)'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default Version257;
