import React from 'react';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './layout/Login.css';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, Container } from '@mui/material';

const FinalizeSignup = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState('');
  const [termsData, setTermsData] = React.useState([]);
  const [seletectTerm, setseletectTerm] = React.useState({
    policy1: false,
    policy2: false,
    policy3: false,
  });

  React.useEffect(() => {
    fetch('https://testing-api.didimo.co/v3/data_usage_policies')
      .then((response) => response.json())
      .then((data) =>
        setTermsData(
          data.filter((item) => item.is_acceptance_required === true)
        )
      );
  }, []);

  const onChangeOption = (e, key) => {
    setseletectTerm((prev) => {
      return { ...prev, [key]: e.target.checked };
    });
  };

  const handleChangeAccordian = (panel) => {
    if (expanded === panel) {
      setExpanded('');
    } else {
      setExpanded(panel);
    }
  };

  const handleCreateAccount = () => {
    navigate(`${process.env.REACT_APP_ROOT_PATH}/profile/login`, {
      replace: true,
    });
  };

  const styles = {
    SummeryText: {
      width: '10%',
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
    },
    summeryTitle: {
      color: '#2A0651',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    customCheckBox: {
      padding: '0',
      color: '#2A0651',
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="xs" className={'signup-container'}>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
          })}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email is required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if (
              !seletectTerm.policy1 ||
              !seletectTerm.policy2 ||
              !seletectTerm.policy3
            ) {
              errors.policy = 'All fields must be checked';
            }
            return errors;
          }}
          onSubmit={handleCreateAccount}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h2" className={'main-header'}>
                  {isSubmitting}
                  Finalize Sign up
                </Typography>
              </Box>
              <Typography variant={'h5'} className={'email-text'}>
                Email
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="standard"
                />
              </div>
              <Box
                sx={{ alignItems: 'center', display: 'flex', ml: -1, mt: 3 }}
              >
                <Typography color="textSecondary" variant="body1">
                  {termsData.map((terms, index) => {
                    const panel = `panel${index + 1}`;
                    return (
                      <Accordion expanded={expanded === panel}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              onClick={() => handleChangeAccordian(panel)}
                            />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={styles.SummeryText}>
                            <Checkbox
                              style={styles.customCheckBox}
                              name={panel}
                              value={seletectTerm}
                              onChange={(e) =>
                                onChangeOption(e, `policy${index + 1}`)
                              }
                            />
                          </Typography>
                          <Typography
                            sx={styles.summeryTitle}
                            onClick={() => handleChangeAccordian(panel)}
                          >
                            {terms?.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ borderBottom: '1px solid #2a0651' }}
                        >
                          <Typography>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: terms?.contents,
                              }}
                            />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Typography>
              </Box>
              {Boolean(errors.policy) && (
                <FormHelperText error>{errors.policy}</FormHelperText>
              )}
              <Box sx={{ py: 2 }} className={'SpinnerContainer'}>
                <Button
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  className={'Create-account-btn'}
                >
                  Create Account
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default FinalizeSignup;
