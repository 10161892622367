import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import zip from 'zip-js-esm';
import { fs } from './vendor/zip-fs.js';
import { Viewer } from './vendor/viewer.js';
import { useParams } from 'react-router';
import { GlobalContext } from '../../context/store';
import axios from 'axios';
import './viewgltf.css';
import { Backdrop } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import fetchProgress from 'fetch-progress';

zip.useWebWorkers = false;
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          style={{ color: 'white', fontSize: '14px' }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function _loadZip(file, setGltfObjectMap) {
  var traverse;
  const pending = [];
  const fileMap = new Map();
  const archive = new fs.FS();

  traverse = (node) => {
    if (node.directory) {
      node.children.forEach(traverse);
    } else if (node.name[0] !== '.') {
      pending.push(
        new Promise((resolve) => {
          node.getData(new zip.BlobWriter(), (blob) => {
            blob.name = node.name;
            fileMap.set(node.getFullname(), blob);
            resolve();
          });
        })
      );
    }
  };
  archive.importBlob(file, () => {
    traverse(archive.root);
    Promise.all(pending).then(() => {
      setGltfObjectMap(fileMap);
      document.querySelector('.viewer').style.height = 'calc(100% - 81px)';
    });
  });
}

function ViewerComponent({ gltfObjectMap }) {
  const componentRef = useRef(null);

  useEffect(() => {
    let viewer = new Viewer(componentRef.current, { kiosk: false });

    function load(fileMap) {
      let rootFile;
      let rootPath;
      Array.from(fileMap).forEach(([path, file]) => {
        if (file.name.match(/\.(gltf|glb)$/)) {
          rootFile = file;
          rootPath = path.replace(file.name, '');
        }
      });

      /*if (!rootFile) {
        this.onError('No .gltf or .glb asset found.');
      }*/

      view(rootFile, rootPath, fileMap);
    }

    /**
     * Passes a model to the viewer, given file and resources.
     * @param  {File|string} rootFile
     * @param  {string} rootPath
     * @param  {Map<string, File>} fileMap
     */
    function view(rootFile, rootPath, fileMap) {
      //if (this.viewer) this.viewer.clear();

      //const viewer = this.viewer || this.createViewer();

      const fileURL =
        typeof rootFile === 'string' ? rootFile : URL.createObjectURL(rootFile);

      const cleanup = () => {
        this.hideSpinner();
        if (typeof rootFile === 'object') URL.revokeObjectURL(fileURL);
      };

      viewer
        .load(fileURL, rootPath, fileMap)
        .catch((e) => this.onError(e))
        .then((gltf) => {
          /*if (!this.options.kiosk) {
            this.validationCtrl.validate(fileURL, rootPath, fileMap, gltf);
          }
          cleanup();*/
        });
      window.viewer = viewer;
    }

    if (gltfObjectMap !== null) load(gltfObjectMap);
  });

  return (
    <div ref={componentRef} className="viewer" style={{ height: '90%' }} />
  );
}

function ViewGltf() {
  const { state } = useContext(GlobalContext);
  const { key } = useParams();
  const [gltfObjectMap, setGltfObjectMap] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    setError(false);
    axios
      .get('didimos/' + key + '/results/gltf', {
        headers: {
          Authorization: `Bearer ${state.user?.accessToken}`,
          'didimo-follow-uri': 'false',
          'access-control-allow-origin': 'https://app.didimo.co',
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        fetch(res.data.uri)
          .then(
            fetchProgress({
              onProgress(progress) {
                setProgress(progress.percentage);
              },
            })
          )
          .then((r) => r.blob())
          .then((blob) => {
            let metadata = {
              type: blob.type,
            };
            if (!gltfObjectMap) {
              let file = new File([blob], 'didimo.zip', metadata);
              _loadZip(file, setGltfObjectMap);
            }
          });
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  if (!gltfObjectMap) {
    return (
      <div className={'view_didimo_model'}>
        <Backdrop open={true} style={{ flexDirection: 'column' }}>
          {!error ? (
            <>
              <CircularProgressWithLabel value={progress} />
              <span style={{ color: 'white', marginTop: '5px' }}>
                Please Wait While Loading...
              </span>
            </>
          ) : (
            <span style={{ color: 'red', marginTop: '5px' }}>
              Error while Featching Image!
            </span>
          )}
        </Backdrop>
      </div>
    );
  }
  return gltfObjectMap && <ViewerComponent gltfObjectMap={gltfObjectMap} />;
}

export default ViewGltf;
