import React from 'react';
import Webcam from 'react-webcam';
import { Button, Grid } from '@material-ui/core';
import { dataURLtoFile } from '../../../utils/base64ToFile';
import selfie_template from '../../../assets/Images/mask-webcam.png';

const videoConstraints = {
  width: 720,
  height: 720,
  facingMode: 'user'
};
const WebCam = (props) => {
  const { setFiles, setSrc, setIsOpen, handleChangefile } = props;
  const webcamRef = React.useRef(null);
  const capture = async () => {
    setSrc(null);
    let imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const date = new Date();
      const name = date.getMilliseconds();
      const imageData = {
        preview: imageSrc,
        name: name + '.jpg',
      };
      await setFiles(imageData);
      setIsOpen(false);
      //const file = dataURLtoFile(imageData.preview,imageData.name);
      // handleChangefile(file);
    }
  };
  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'black',
          position:"relative"
        }}
      >
        <Webcam
          audio={false}
          width="370px"
          height="370px"
          ref={webcamRef}
          aspectRatio={1}
          className="webcame_video"
          mirrored={true}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <div style={{ position: 'absolute',overflow:'hidden' }}>
          <img
            src={selfie_template}
            alt="webcam"
            className="webCame_image"
            style={{
              //height: '300px',
              width: '100%',
              marginLeft: '0px',
              objectFit: "contain",
            }}
          />
        </div>
      </Grid>
      <Grid
        style={{
          marginTop: '5px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="outlined"
          style={{
            display: 'flex',
            fontSize: 'small',
            padding: '2px 10px',
            textTransform: 'capitalize',
          }}
          disabled={!webcamRef?.current}
          onClick={capture}
        >
          Take a Picture
        </Button>
      </Grid>
    </>
  );
};

export default WebCam;
