import React, { useContext, useEffect, useState } from 'react';
import MarketPlaceTable from './MarketPlaceTable';
import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';
import { Backdrop } from '@material-ui/core';
import { isUserAuthenticated } from '../../context/actions';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/store';
import { marketPlace } from '../../context/actions';

const MarketPlace = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const [marketPlaceData, setMarketPlaceData] = useState([]);
  const [isShowLoader, setIsShowLoader] = useState(true);

  useEffect(() => {
    if (state?.isAuth === true) {
      axios
        .get(
          '/billing/products?product_type=character_pack&product_type=xpto&active=true&purchasable=true'
        )
        .then((response) => {
          setMarketPlaceData(response.data);
          setIsShowLoader(false);
        });
    }
  }, [state]);

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'welcome'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div>
      <Backdrop open={isShowLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ py: 5, px: 5, backgroundColor: '#eeeeee' }}>
        <MarketPlaceTable marketPlaceData={marketPlaceData} />
      </Box>
    </div>
  );
};

export default MarketPlace;
