import styled from 'styled-components';

const DidimoGrid = styled((props) => {
  const { didimos } = props;
  return (
    <div className={props.className}>
      {didimos.map((didimo) => {
        return <div>x{didimo.key}</div>;
      })}
    </div>
  );
})`
  display: grid;
  & > div {
    border: 1px solid black;
    background-color: white;
  }
  ${(props) => props.theme.breakpoints.up('xs')} {
    background-color: orange;
    grid-template-columns: repeat(1, 1fr);
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    background-color: yellow;
    grid-template-columns: repeat(2, 1fr);
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    background-color: green;
    color: black;
    grid-template-columns: repeat(4, 1fr);
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    background-color: blue;
    color: white;
    grid-template-columns: repeat(4, 1fr);
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    background-color: red;
    color: black;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export default DidimoGrid;
