import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Alert,
} from '@material-ui/core';
import Amplify, { Auth } from 'aws-amplify';
import { GlobalContext } from 'src/context/store';
import { userLogout } from 'src/context/actions';

const Confirm = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const userName = queryParams.get('username');
  const userCode = queryParams.get('code');
  const { dispatch } = useContext(GlobalContext);

  const handleReSendClick = async () => {
    var raw = JSON.stringify({
      email_address: userName,
    });

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Didimo-Platform', process.env.REACT_APP_PLATFORM);
    myHeaders.append('Didimo-Platform-Version', process.env.REACT_APP_VERSION);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    var createCodeURL = process.env.REACT_APP_API_ROOT + 'accounts/verifying';

    const res = await fetch(createCodeURL, requestOptions);
    const data = await res.json();

    setError('');
  };

  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
      storage: localStorage,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGNOUT,
        responseType: 'code',
      },
    },
  });

  async function ConfirmUser(values) {
    if (values) {
      try {
        try {
          let myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append('Didimo-Platform', process.env.REACT_APP_PLATFORM);
          myHeaders.append(
            'Didimo-Platform-Version',
            process.env.REACT_APP_VERSION
          );

          var raw = JSON.stringify({
            code: values.vCode,
          });

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
          };

          var createCodeURL =
            process.env.REACT_APP_API_ROOT +
            'accounts/verifying/' +
            encodeURIComponent(userName);

          const res = await fetch(createCodeURL, requestOptions);
          const data = await res.json();

          if (data.status === 404) {
            setError('Error Confirming Code');
          } else {
            if (Auth.currentSession()) {
              // If a user is logged in at this point, he is returning...
              await Auth.signOut();
              dispatch(userLogout());
            }
            navigate('/login');
          }
        } catch (error) {
          setError('Error Confirming Sign Up');
        }
      } catch (err) {
        setError('Error Confirming Sign Up');
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Confirm | Didimo</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              vCode: userCode,
            }}
            validationSchema={Yup.object().shape({
              vCode: Yup.string()

                .max(255)
                .required('Code is required'),
            })}
            validate={(values) => {
              const errors = {};
              if (!values.vCode) {
                errors.vCode = 'Required';
              }
              return errors;
            }}
            onSubmit={ConfirmUser}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    gutterBottom="true"
                  >
                    {isSubmitting}
                    Code Confirmation
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    gutterBottom="true"
                  >
                    We have sent a code by email to {userName}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Enter it below to confirm your account
                  </Typography>
                </Box>

                {error && (
                  <Alert
                    severity="error"
                    style={{ marginBottom: '25px', cursor: 'pointer' }}
                  >
                    {error}

                    <div onClick={() => handleReSendClick()}>
                      <span className="resend-click-link">Click</span> here to
                      re send
                    </div>
                  </Alert>
                )}

                <TextField
                  error={Boolean(touched.vCode && errors.vCode)}
                  fullWidth
                  helperText={touched.vCode && errors.vCode}
                  label="Verification Code"
                  name="vCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vCode}
                  variant="outlined"
                />

                <Box sx={{ py: 2 }}>
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className={'confirm-pwd-btn'}
                  >
                    Confirm Account
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Confirm;
