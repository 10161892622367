import axios from 'axios';

export const getData = async (url) => {
  try {
    const { data } = await axios.get(url);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteData = async (url) => {
  const { data } = await axios.delete(url);
  return data;
};

export const postData = async (url, body) => {
  try {
    const { data } = await axios.post(url, body);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const putData = async (url, body) => {
  try {
    const { data } = await axios.put(url, body);

    return data;
  } catch (err) {
    console.log(err);
  }
};
