import React, { useEffect, useState, useContext, Fragment } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import {
  Link,
  Navigate,
  Redirect,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SuccessSnack from '../SuccessSnack';
import ErrorSnack from '../ErrorSnack';
import queryString from 'query-string';
import Products from '../products/Products';
import { GlobalContext } from 'src/context/store';
import PackageTable from '../../pages/Packages/PackageTable/PackageTable';
import { getData, postData } from '../../helpers/dataFetching';
import CookieBase from 'src/components/CookieBase';
import { Alert } from '@mui/material';
import { Box } from '@mui/material';
import { isUserAuthenticated } from '../../context/actions';
import axios from 'axios';

const Packages = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [packagesLoaded, setPackagesLoaded] = useState(false);
  const [bucketData, setBucketData] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [redirectToCheckout, setRedirectToCheckout] = useState(null);
  const [missingPolicies, setMissingPolicies] = useState([]);
  const [allPolicy, setAllPolicy] = useState({});
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [statusData, setStatusData] = useState();
  const [accountData, setAccountData] = useState();
  const [selectYourPlan, setselectYourPlan] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const [pendingOrders, setDdmoStatus] = useState();
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    if (location.hash === '#selectNewPackage=true') {
      setselectYourPlan(true);
    } else {
      setselectYourPlan(false);
    }
  }, [location.hash]);

  useEffect(() => {
    if (state?.isAuth === true) {
      callApiesAll();
    }
  }, [state]);

  useEffect(() => {
    const missingPolicies =
      allPolicy && allPolicy.length > 0
        ? [...allPolicy].filter(
            (policy) =>
              (policy.status !== 'accepted' || policy.__updated === true) &&
              policy.data_policy.target_users.length === 1 &&
              policy.data_policy.target_users[0] === 'customers'
          )
        : [];
    setMissingPolicies(missingPolicies);
  }, [allPolicy]);

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'welcome'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const callApiesAll = async () => {
    try {
      const account = await getData(`accounts/${state.user.accounts.uuid}`);
      setAccountData(account);
      const accountStatus = await getData(
        `accounts/${state.user.accounts.uuid}/status`
      );
      setStatusData(accountStatus);

      const accountPackageListSet = await getData(
        // `accounts/${state.user.accounts.uuid}/packages?status=active`
        `accounts/${state?.user?.accounts.uuid}/packages?product_type=standard&status=active`
      );
      setPackagesLoaded(true);
      setBucketData(accountPackageListSet);
      setLoadingProducts(true);

      //const productList = await getData(`services/payments/stripe/ProductList`);
      const product = await getData(
        `billing/products?active=true&purchasable=true&product_type=standard`
      );
      setProductData(product);
      setLoadingProducts(true);
      setProductsLoaded(true);

      const accounts = await getData(`accounts/${state.user.accounts.uuid}`);
      const acceptedPolicies = accounts.policies.filter(
        (policyAcceptance) => policyAcceptance.status === 'accepted'
      );
      /*const missingPolicies = accounts.policies.filter(
        (missingPolicy) =>
          missingPolicy.status !== 'accepted' &&
          missingPolicy.data_policy.target_users[0] === 'customers'
      );
      //setPolicyDecisions(acceptedPolicies);
      setMissingPoliciesMessage(missingPolicies);*/
      setPackagesLoaded(true);
      setAllPolicy(accounts.policies);
    } catch (error) {
      // console.log(error);
    }
  };

  const addToHash = (values) => {
    const hashItems = queryString.parse(props.location.hash);
    Object.keys(values).forEach((key) => (hashItems[key] = values[key]));
    return queryString.stringify(hashItems);
  };

  const removeFromHash = (keys) => {
    const hashItems = queryString.parse(props.location.hash);
    const removableKeys = Array.isArray(keys) ? keys : [keys];
    removableKeys.map((key) => delete hashItems[key]);
    return queryString.stringify(hashItems);
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // *  Open should be true here
  };

  const handleClose = () => {
    setTimeout(() => {
      // props.history.push(
      //   `#${removeFromHash(['selectNewPackage', 'buyPackage'])}`
      // );
    }, 100);
  };

  const handleOnPoliciesSubmit = async () => {
    let missingPolicies = [];
    if (
      allPolicy.filter(
        (policy) =>
          policy.status === 'accepted' &&
          policy.data_policy.target_users.length === 1 &&
          policy.data_policy.target_users[0] === 'customers' &&
          policy.__updated === true
      ).length > 0
    ) {
      await updatePolicies();
      const response = await getData(
        `accounts/${state.user.accounts.uuid}`,
        true
      );
      missingPolicies = response.policies.filter(
        (policy) =>
          policy.status !== 'accepted' &&
          policy.data_policy.target_users.length === 1 &&
          policy.data_policy.target_users[0] === 'customers'
      );
    } else {
      missingPolicies = allPolicy.filter(
        (policy) =>
          policy.status !== 'accepted' &&
          policy.data_policy.target_users.length === 1 &&
          policy.data_policy.target_users[0] === 'customers'
      );
    }
    //let policyDecisions = {};
    /*const newMissingPolicies = response.policies.filter(
      (policyMissing) =>
        policyMissing.status !== 'accepted' &&
        policyMissing.data_policy.target_users[0] === 'customers'
    );
    //policyDecisions = response.policies;
    //setPolicyDecisions(policyDecisions);

    setMissingPoliciesMessage(newMissingPolicies);*/
    return missingPolicies;
  };

  const buyProduct = async (productId, product) => {
    try {
      routeToNewPackage(productId, product);
    } catch (error) {
      setErrorMessageOpen(true);
      setIsBuying(false);
    }
  };

  const updatePolicies = async () => {
    let payloadItem = [];

    allPolicy.forEach((policy) => {
      payloadItem.push({
        data_policy_uuid: policy.data_policy.uuid,
        status: policy.status,
      });
    });

    let payload = [
      {
        op: 'replace',
        path: '/policies',
        value: payloadItem,
      },
    ];
    const response = await axios.patch(
      'accounts/' + state.user.accounts.uuid,
      payload,
      {}
    );
  };

  const handleOnPolicyChange = async (policyId, decision) => {
    const updatedPolicies = allPolicy.map((policy) => {
      if (policy.data_policy.uuid === policyId) {
        if (decision === true) {
          policy.status = 'accepted';
          policy.__updated = true;
        } else {
          policy.status = 'rejected';
          policy.__updated = false;
        }
      }
      return policy;
    });

    //let policyDecisions = allPolicy;

    //setPolicyDecisions(policyDecisions);
    setAllPolicy(updatedPolicies);

    /*Object.keys(allPolicy).forEach((key) => {
      payloadItem.push({
        data_policy_uuid: allPolicy[key].data_policy.uuid,
        status: allPolicy[key].status,
      });
    });

    let payload = [
      {
        op: 'replace',
        path: '/policies',
        value: payloadItem,
      },
    ];
    const response = axios
      .patch('accounts/' + state.user.accounts.uuid, payload, {})*/
    /* const response = await postData(
       `accounts/${state.user.accounts.uuid}`,
       payload,
       props.session,
       fetch,
       'PATCH'
     )
       .then((response) => {})
       .catch((err) => {});*/
  };

  const routeToNewPackage = (productId, product) => {
    const payload = { uuid: productId };
    if (
      missingPolicies.length > 0 ||
      !accountData.address.country ||
      (accountData.address.country === 'US' && !accountData.address.state) ||
      !accountData.owner_profile.given_name ||
      !accountData.owner_profile.family_name
    ) {
      navigate('/packages#selectNewPackage=true');
    } else {
      postData('services/payments/stripe/orders', payload, props.session)
        .then((response) => {
          setIsBuying(false);
          if (response.paymentIntentId) {
            navigate(`/orders#checkout=${response.uuid}`);
          } else {
            setSuccessMessageOpen(true);
            handleClose();
          }
        })
        .catch((error) => {
          setIsBuying(false);
          setErrorMessageOpen(true);
          handleClose();
        });
    }
  };

  let pageContents = [];
  const noDataArea = {
      textAlign: 'center',
    },
    noDataText = {
      fontWeight: 'bold',
      fontSize: 20,
    };
  if (redirectToCheckout) {
    return <Navigate to={'/' + redirectToCheckout} />;
  }

  if (packagesLoaded) {
    if (bucketData.length === 0) {
      pageContents = (
        <Fragment>
          <div style={noDataArea}>
            <ShoppingBasketIcon style={{ fontSize: 200 }} />
            <p style={noDataText}>
              There are no active packages on this account.
            </p>
          </div>
        </Fragment>
      );
    } else {
      pageContents = (
        <React.Fragment>
          {packagesLoaded && (
            <PackageTable
              bucketData={bucketData}
              currentTier={statusData}
              productData={productData}
              onBuy={buyProduct}
            />
          )}
        </React.Fragment>
      );

      pageContents = <div>{pageContents}</div>;
    }
  } else {
    pageContents = '';
  }
  const handleCloseModel = () => {
    navigate('');
  };

  useEffect(() => {
    getData('accounts/default/status').then((res) => {
      setDdmoStatus(res?.pending_orders.length);
    });
  }, []);
  return (
    <React.Fragment>
      <Box sx={{ py: 2, px: 2, backgroundColor: '#eeeeee' }}>
        {pendingOrders > 0 && (
          <Alert
            severity="warning"
            style={{
              border: '1px solid #ff9800',
              backgroundColor: 'white',
              marginBottom: '10px',
            }}
          >
            You have pending invoices. Please visit{' '}
            <Link to="/orders">Orders</Link> to proceed with the payment.
          </Alert>
        )}
        {pageContents}
        <Products
          open={selectYourPlan}
          onClose={handleCloseModel}
          productData={productData}
          accountData={accountData}
          missingPolicies={missingPolicies}
          // onSubmit={handleBucketSubmit}
          postDataFunction={postData}
          session={props.session}
          onPoliciesSubmit={handleOnPoliciesSubmit}
          onPolicyChange={handleOnPolicyChange}
          onBuySuccess={(payload) => {
            if (payload.paymentIntentId) {
              navigate(`/orders#checkout=${payload.orderId}`);
            } else {
              setSuccessMessageOpen(true);
              handleClose();
            }
          }}
          onBuyError={(e) => {
            setErrorMessageOpen(true);
            handleClose();
          }}
        />

        <SuccessSnack
          open={successMessageOpen}
          onClose={(e) => {
            setSuccessMessageOpen(false);
          }}
          message={
            'Almost done! Please check the billing area for instructions on how to complete the payment.'
          }
        />
        <ErrorSnack
          open={errorMessageOpen}
          onClose={(e) => {
            setErrorMessageOpen(false);
          }}
          message={'An error occurred while processing your order.'}
        />
        <Backdrop
          // className={props.classes.backdrop}
          open={!packagesLoaded || isBuying}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <CookieBase />
      </Box>
    </React.Fragment>
  );
};

export default Packages;
