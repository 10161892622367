import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ShoppingCart from '@material-ui/icons/ShoppingCartOutlined';
import { addToCart } from '../../context/actions';
import { GlobalContext } from '../../context/store';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import red from '@material-ui/core/colors/red';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(1),
    // marginLeft: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardHeaderTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backArrow: {
    color: '#2a0651',
    fontSize: '23px',
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
}));

export default function MarketPlace(props) {
  const classes = useStyles();
  const { marketPlaceData } = props;
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const [createSnackbar, setCreateSnackbar] = useState(false);
  const [removeSnackbar, setRemoveSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);

  useEffect(() => {
    getCartData();
  }, []);
  const getCartData = async () => {
    await axios.get(`billing/basket`).then((res) => {
      dispatch(addToCart(res.data.accountProductsBasket));
    });
  };

  const handleCheckout = async (uuid) => {
    const cData = {
      product_uuid: uuid,
    };
    await axios
      .post(`billing/basket`, cData)
      .then((res) => {
        setCreateSnackbar(true);
        getCartData();
      })
      .catch((e) => {
        setErrorSnackbar(true);
      });
  };

  const getPrice = (pkg) => {
    if (pkg.current_product_price) {
      return pkg.current_product_price.price;
    }
    return pkg.price;
  };

  const getTotalPrice = () => {
    if (state.cartData && state.cartData !== '') {
      let total = 0;
      {
        state.cartData.map((item) => {
          let price = getPrice(item.product);
          total = total + price;
        });
      }
      return total;
    }
  };

  const RemoveCart = async (uuid) => {
    await axios.delete(`billing/basket/${uuid}`);
    setRemoveSnackbar(true);
    getCartData();
  };

  const disableBtn = (uuid) => {
    const isDisabled = state.cartData?.find((f) => f.product.uuid == uuid);
    if (isDisabled) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Helmet>
        <title>Marketplace | Didimo</title>
      </Helmet>

      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Card>
                    <CardHeader title="Available Products" />
                    <CardContent>
                      {marketPlaceData.map((packageItem, index) => (
                        <React.Fragment key={packageItem.uuid}>
                          {index > 0 && <Divider />}
                          <Grid
                            container
                            style={{
                              margin: '10px 0',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sm={6}
                              lg={6}
                              direction="column"
                              justify="space-between"
                              container
                              style={{
                                maxWidth: 'auto',
                              }}
                            >
                              <Grid container justify="space-between" item>
                                <Grid item>
                                  <Typography variant="h5" component="h2">
                                    {packageItem.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              xs={4}
                              sm={2}
                              lg={2}
                              // justify="center"
                              item
                              style={{
                                minWidth: '5em',
                                alignItems: 'center',
                              }}
                            >
                              <Grid
                                xs={12}
                                sm={4}
                                lg={4}
                                item
                                style={{ marginLeft: 'auto', minWidth: '5em' }}
                              >
                                <Typography variant="h5" align="right">
                                  {'$' + getPrice(packageItem).toFixed(2)}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  align="right"
                                  style={{
                                    marginTop: '5px',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  plus taxes
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              xs={12}
                              sm={4}
                              lg={4}
                              item
                              style={{ marginLeft: 'auto', minWidth: '5em' }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  float: 'right',
                                  padding: '4px 10px',
                                  fontSize: '0.8125rem',
                                  textTransform: 'capitalize',
                                  fontFamily:
                                    '"Roboto", "Helvetica", "Arial", sans-serif',
                                }}
                                onClick={() => handleCheckout(packageItem.uuid)}
                                disabled={disableBtn(packageItem.uuid)}
                              >
                                Add to Cart
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>

                <Grid
                  item
                  md={4}
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <Card style={{ width: '100%' }}>
                    <CardHeader
                      title={
                        <Typography variant={'h4'}>
                          <IconButton style={{ color: '#263238' }}>
                            <ShoppingCart />
                          </IconButton>
                          Cart Summary
                        </Typography>
                      }
                    />
                    {state.cartData && state.cartData.length > 0 ? (
                      <CardContent>
                        {(state.cartData || []).map((item, key) => {
                          return (
                            <div>
                              <Grid
                                container
                                display="flex"
                                style={{
                                  justifyContent: 'flex-end',
                                  padding: 10,
                                }}
                                spacing={2}
                              >
                                <Grid
                                  item
                                  container
                                  direction="row"
                                  justifyContent="flex-end"
                                  style={{
                                    flexGrow: '1',
                                    flexShrink: '1',
                                    flexBasis: '170px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <Typography gutterBottom variant="h5">
                                      {item.product.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  style={{
                                    width: 'auto',
                                  }}
                                >
                                  <Grid item style={{}}>
                                    <Typography
                                      variant="h3"
                                      style={{
                                        textAlign: 'center',
                                        fontSize: '16px',
                                      }}
                                    >
                                      $
                                      {item.product.current_product_price
                                        ? item.product.current_product_price
                                            .price
                                        : item.product.price}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      align="right"
                                      style={{ fontWeight: 'normal' }}
                                    >
                                      plus taxes
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  style={{
                                    width: 'auto',
                                  }}
                                >
                                  <Grid item>
                                    <Typography
                                      variant="h3"
                                      className={'remove'}
                                      style={{
                                        textAlign: 'center',
                                        fontSize: '16px',
                                      }}
                                    >
                                      <Tooltip title="Delete">
                                        <IconButton
                                          size="small"
                                          onClick={() => RemoveCart(item.uuid)}
                                          style={{
                                            color: red[500],
                                            marginLeft: '10px',
                                          }}
                                          type="submit"
                                        >
                                          <DeleteOutlinedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Divider
                                style={{
                                  margin: '10px 0px 10px 10px',
                                  height: '1px',
                                }}
                              />
                            </div>
                          );
                        })}

                        <Grid
                          container
                          display="flex"
                          style={{
                            justifyContent: 'flex-end',
                            padding: 10,
                          }}
                          spacing={2}
                        >
                          <Grid
                            container
                            item
                            style={{
                              width: 'auto',
                              gap: '1rem',
                            }}
                          >
                            <Grid
                              item
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                Total :
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            style={{
                              width: 'auto',
                              gap: '1rem',
                            }}
                          >
                            <Grid item>
                              <Typography
                                variant="h3"
                                style={{
                                  textAlign: 'center',
                                  fontSize: '16px',
                                }}
                              >
                                {'$' + getTotalPrice().toFixed(2)}
                              </Typography>
                              <Typography
                                variant="body2"
                                align="right"
                                style={{ fontWeight: 'normal' }}
                              >
                                plus taxes
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              padding: '4px 10px',
                              fontSize: '0.8125rem',
                              textTransform: 'capitalize',
                              fontFamily:
                                '"Roboto", "Helvetica", "Arial", sans-serif',
                            }}
                            onClick={() => {
                              navigate('/checkout');
                            }}
                          >
                            Checkout
                          </Button>
                        </Grid>
                      </CardContent>
                    ) : (
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '60%',
                          alignItems: 'center',
                        }}
                      >
                        <Grid>
                          <Typography variant="h3">
                            No Item available in Cart
                          </Typography>
                        </Grid>
                      </CardContent>
                    )}
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={createSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setCreateSnackbar(false);
          }}
          severity="success"
        >
          Item is added to Cart
        </Alert>
      </Snackbar>
      <Snackbar
        open={removeSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setRemoveSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setRemoveSnackbar(false);
          }}
          severity="error"
        >
          Item is removed from Cart
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setErrorSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setErrorSnackbar(false);
          }}
          severity="error"
        >
          There was an error while adding item in Cart
        </Alert>
      </Snackbar>
    </>
  );
}
