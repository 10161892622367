export default {
  gutterBottom: {
    marginBottom: 8,
  },
  h1: {
    fontSize: '26px',
  },
  h2: {
    fontSize: '24px',
  },
};
