import React from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';

export default function ErrorSnack(props) {
  // const classes = useStyles();
  return (
    <Snackbar
      onClose={props.onClose}
      open={props.open}
      autoHideDuration={6000}
      TransitionComponent={props.transition || Fade}
      {...props}
    >
      <Alert severity="error" variant="filled" elevation={6} {...props}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
