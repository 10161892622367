import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Button,
  Card,
  CardMedia,
  Checkbox,
  Grid,
  IconButton,
} from '@mui/material';
import { Tooltip } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { getData } from '../../helpers/dataFetching';
import ddmoFullBody from './assets/Icon_FullBody.png';
import headOnly from './assets/Icon_HeadOnly.png';
import LoadingGIF from './assets/loading.gif';
import ErrorIcon from '../../assets/Images/error-icon.png';
import { GlobalContext } from '../../context/store';

const DidimoSingle = ({
  classes,
  ddmo,
  handleClick,
  stopClick,
  onCheck,
  onUncheck,
  onFavourite,
  handleMetaInformation,
  selectedDidimos,
}) => {
  const { state } = useContext(GlobalContext);
  const [didimoImage, setDidimoImage] = useState('');
  const [isDemo, setIsDemo] = useState(false);

  /*useEffect(() => {
    GetImage();
    return () => {
      setDidimoImage();
    };
  }, []);*/

  useEffect(() => {
    setIsDemo(!!ddmo.meta_data.find((item) => item.name === 'is_sample'));
    if (ddmo.status === 'done') {
      GetImage();
    } else if (ddmo.status === 'processing' || ddmo.status === 'pending') {
      setDidimoImage(LoadingGIF);
    } else if (ddmo.status === 'error') {
      setDidimoImage(ErrorIcon);
    }
  }, [ddmo]);

  const getUpdatedName = (metadata) => {
    const nameMeta = metadata.meta_data.find((item) => item.name === 'name');
    return nameMeta && nameMeta.value
      ? nameMeta.value
      : ddmo.key.substr(0, 13) + '...';
  };

  const GetImage = () => {
    getData('/didimos/' + ddmo.key + '/artifacts/front_png')
      .then((response) => {
        setDidimoImage(response.uri);
      })
      .catch((err) => {
        console.log('Error while Featching Image!');
      });
  };
  const avatarStructure = ddmo.meta_data.find(
    (item) => item.name === 'avatar_structure'
  );
  const styles = {
    imageBody: {
      height: '21px',
      width: '21px',
    },
    imageHead: {
      height: '21px',
      width: '21px',
      marginLeft: '-7px',
    },
  };
  return (
    <Grid item>
      <Card>
        <div className={'card_content'}>
          {isDemo && (
            <div className={classes.ribbon}>
              <span>DEMO</span>
            </div>
          )}
          <CardMedia
            className={[
              classes.media,
              ddmo.percent < 100 ? classes.mediaBackground : '',
            ]}
            image={didimoImage}
            title={ddmo.key}
            component="div"
            onClick={() => handleMetaInformation(ddmo)}
          >
            {(ddmo.status === 'error' && (
              <div align="center" className={classes.error}>
                {'A processing error occurred.'}
              </div>
            )) ||
              (ddmo.status !== 'done' && (
                <div className={classes.percentage}>Processing ...</div>
              ))}
          </CardMedia>

          <div className="card_bottom">
            <div style={{ width: '10%' }}>
              <Tooltip
                title={
                  avatarStructure && avatarStructure?.value === 'full-body'
                    ? 'Full body'
                    : 'Head only'
                }
                className="people_icon"
              >
                <img
                  src={
                    avatarStructure && avatarStructure?.value === 'full-body'
                      ? ddmoFullBody
                      : headOnly
                  }
                  style={
                    avatarStructure && avatarStructure?.value == 'full-body'
                      ? styles['imageBody']
                      : styles['imageHead']
                  }
                  alt={'ddmoFullBody'}
                />
              </Tooltip>
            </div>
            <div style={{ width: '70%' }}>
              <Tooltip title={ddmo.key}>
                <p
                  style={{
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getUpdatedName(ddmo)}
                </p>
              </Tooltip>
            </div>
            <div style={{ width: '10%', position: 'relative' }}>
              <Button
                className="menu_btn"
                onClick={(e) =>
                  handleClick(
                    e,
                    ddmo.key,
                    ddmo.status,
                    ddmo.meta_data.find((item) => item.name === 'name')?.value
                  )
                }
              >
                <MoreVertIcon color={'primary'} />
              </Button>
            </div>
          </div>
          {!isDemo && (
            <>
              <Checkbox
                className="select_check"
                //checked={toDeleteDdmo.indexOf(ddmo.key) >= 0}
                checked={selectedDidimos[ddmo.key] === true}
                inputProps={{ 'aria-label': 'select didimo' }}
                onClick={stopClick}
                onChange={(e) => {
                  e.stopPropagation();
                  if (e.target.checked) {
                    onCheck(ddmo.key);
                  } else {
                    onUncheck(ddmo.key);
                  }
                }}
              />
              <Tooltip title="Favourite">
                {/*<IconButton
                  className="filter_ckeck"
                  onClick={() => onFavourite(ddmo.key)}
              >*/}
                <Checkbox
                  className="filter_ckeck"
                  icon={<FavoriteBorder />}
                  checked={ddmo.is_favorite}
                  //defaultChecked={ddmo?.is_favorite}
                  checkedIcon={<Favorite />}
                  onChange={() => onFavourite(ddmo.key)}
                />
                {/*</IconButton>*/}
              </Tooltip>
            </>
          )}
        </div>
      </Card>
    </Grid>
  );
};

export default DidimoSingle;
