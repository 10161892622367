import React, { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '../../context/store';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import './Template.css';
import axios from 'axios';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import DDMElements from '../DDMElements/DDMElements';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@mui/material/Alert';

const ManageTemplate = (props) => {
  const { isOpen, onClose, showTemplates, editData } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state } = useContext(GlobalContext);

  const [versionOptions, setVersionOptions] = useState([]);
  const [version, setVersion] = useState('');
  const [customerPortal, setCustomerPortal] = useState([]);
  const [componentVersion, setComponentVersion] = useState('');
  const [versionUuid, setVersionUuid] = useState('');
  const [defaultVersion, setDefaultVersion] = useState('');

  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [codeName, setCodeName] = useState('');
  const [createSnackbar, setCreateSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [tier, setTier] = useState('');

  useEffect(() => {
    if (!editData) {
      axios
        .get(`/didimos/releases`, {
          headers: { Authorization: `Bearer ${state?.user?.accessToken}` },
        })
        .then((res) => {
          setVersionOptions(res.data);
          axios
            .get(`/platforms/customer_portal`, {
              headers: { Authorization: `Bearer ${state?.user?.accessToken}` },
            })
            .then((response) => {
              setCustomerPortal(response.data);
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].is_default === true) {
                  setVersion(res.data[i].dgp_version);
                  setDefaultVersion(res.data[i].dgp_version);
                  setVersionUuid(res.data[i].dgp_version_uuid);
                  getMatchedVersion(response.data, res.data[i].dgp_version);
                }
              }
            });
        });
    }

    axios.get(`accounts/default/status`).then((res) => {
      setTier(res.data.tier?.name);
    });
  }, [isOpen]);

  useEffect(() => {
    if (editData) {
      setName(editData.template_name);
      setCodeName(editData.codename);
      setDesc(editData.description);
      handleChangeVersion(editData.pipeline_versions);
    }
  }, [editData]);

  const getMatchedVersion = (customerPortal, dgpVersion) => {
    if (customerPortal?.versions?.length > 0) {
      let version = process.env.REACT_APP_VERSION
        ? process.env.REACT_APP_VERSION
        : '2.5.0';
      const versionData = customerPortal.versions.find(
        (c) => c.code === version
      );
      let dgpCompatibilityRules = [];
      if (versionData) {
        dgpCompatibilityRules = versionData.dgp_compatibility_rules;
      }
      for (var i = 0; i < dgpCompatibilityRules.length; i++) {
        let regTester = new RegExp(dgpCompatibilityRules[i].pattern);
        let matchRegEx = regTester.test(dgpVersion);

        if (matchRegEx === true) {
          setComponentVersion(
            dgpCompatibilityRules[i].settings.didimo_generation_component
          );
          break;
        }
      }
    }
  };

  const handleChangeVersion = (dgpVersion) => {
    const versionUuid = versionOptions.find(
      (f) => f.dgp_version === dgpVersion
    );
    setVersionUuid(versionUuid?.dgp_version_uuid);
    setVersion(dgpVersion);
    getMatchedVersion(customerPortal, dgpVersion);
  };

  const handleChange = (keyField, value) => {
    if (keyField === 'name') {
      setName(value);
    } else if (keyField === 'description') {
      setDesc(value);
    } else if (keyField === 'codeName') {
      setCodeName(value);
    }
  };

  const handleClose = () => {
    setName('');
    setDesc('');
    setCodeName('');
    setVersion(defaultVersion);
    setComponentVersion(defaultVersion);
    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="configure-template-title"
      className={'configure-template-container'}
    >
      <DialogTitle id="configure-template-title">
        <h3>{editData ? 'Edit Template' : 'Create Template'}</h3>
      </DialogTitle>
      {editData?.scope === 'system' && (
        <Alert
          severity="warning"
          style={{
            border: '1px solid #ff9800',
            backgroundColor: 'white',
            marginBottom: '10px',
          }}
        >
          This is a system generated template so you can't edit it
        </Alert>
      )}
      <DialogContent style={{ padding: '0' }}>
        <div style={{ color: 'black' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth className="select-version-spacing">
                <InputLabel id="select-version-label">
                  Select DGP Version
                </InputLabel>
                <Select
                  labelId="select-version-label"
                  id="version-select"
                  value={version}
                  label="DGP"
                  disabled={editData?.scope === 'system'}
                  onChange={(e) => handleChangeVersion(e.target.value)}
                >
                  {versionOptions.map((option) => {
                    return option?.dgp_version != undefined ? (
                      <MenuItem value={option.dgp_version}>
                        {option.dgp_version}
                      </MenuItem>
                    ) : (
                      ''
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Code *"
                margin="normal"
                variant="outlined"
                color="primary"
                fullWidth
                value={codeName}
                onChange={(e) => {
                  handleChange('codeName', e.target.value);
                }}
                disabled={editData}
                inputProps={{
                  'aria-label': 'codeName',
                  'data-id': 'codeName',
                  maxLength: 32,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Name *"
                margin="normal"
                variant="outlined"
                color="primary"
                fullWidth
                value={name}
                onChange={(e) => {
                  handleChange('name', e.target.value);
                }}
                disabled={editData?.scope === 'system'}
                inputProps={{
                  'aria-label': 'name',
                  'data-id': 'name',
                  maxLength: 100,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Description *"
                margin="normal"
                variant="outlined"
                color="primary"
                multiline={true}
                rows={4}
                fullWidth
                value={desc}
                disabled={editData?.scope === 'system'}
                onChange={(e) => {
                  handleChange('description', e.target.value);
                }}
                inputProps={{
                  'aria-label': 'description',
                  'data-id': 'description',
                }}
              />
            </Grid>
          </Grid>

          {componentVersion && (
            <DDMElements
              onCloseModal={handleClose}
              componentVersion={componentVersion}
              name={name}
              desc={desc}
              codeName={codeName}
              showTemplates={showTemplates}
              editData={editData}
              tier={tier}
              templateVersion={version}
              versionUuid={versionUuid}
            />
          )}

          <Snackbar
            open={createSnackbar}
            autoHideDuration={3000}
            onClose={() => {
              setCreateSnackbar(false);
            }}
          >
            <Alert
              onClose={() => {
                setCreateSnackbar(false);
              }}
              severity="success"
            >
              Didimo template has been created successfully
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorSnackbar}
            autoHideDuration={3000}
            onClose={() => {
              setErrorSnackbar(false);
            }}
          >
            <Alert
              onClose={() => {
                setErrorSnackbar(false);
              }}
              severity="error"
            >
              There was an error while creating didimo template
            </Alert>
          </Snackbar>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ManageTemplate;
