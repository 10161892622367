import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Dashboard from './pages/Dashboard';
import Welcome from './pages/Welcome/Welcome';
import Login from './pages/Auth/Login';
import Confirm from './pages/Auth/Confirm';
import NotFound from './pages/NotFound';
import Register from './pages/Auth/Register';
import Settings from './pages/Profile/Settings';
import ChangePassword from './pages/Profile/ChangePassword/ChangePassword';
import MyDidimos from './pages/MyDidimos/MyDidimos';
import Orders from './pages/Orders/Orders';
import CreateOrder from './pages/Orders/CreateOrder';
import Transactions from './pages/Transactions/Transactions';
import Details from './pages/Packages/PackageDetails/PackageDetails';
import Packages from './components/packages/Packages';
import Notification from './pages/Notification/Notification';
import DataUsagePolicy from './pages/Profile/DataUsagePolicy';
import Application from './pages/Application/Applications';
import ProfileLogin from './pages/Profile/ProfileLogin';
import DidimoDetails from './components/DidimoDetails/didimoDetails';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ForgotPasswordChange from './pages/Auth/ForgotPasswordChange';
import FinalizeSignup from './pages/Auth/FinalizeSignup';
import ViewGltf from './pages/ViewGltf/ViewGltf';
import HomePage from './pages/Home';
import Templates from './pages/Templates/Templates';
import MarketPlace from './pages/Marketplace/MarketPlace';
import Checkout from './pages/Checkout/Checkout';
import PreviewDidimo from './pages/MyDidimos/PreviewDidimo';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: 'orders', element: <Orders /> },
      { path: 'order/create', element: <CreateOrder /> },
      { path: 'profile/settings', element: <Settings /> },
      { path: 'profile/changepassword', element: <ChangePassword /> },
      { path: 'profile/data-usage-policy', element: <DataUsagePolicy /> },

      { path: 'welcome', element: <Welcome /> },
      { path: 'mydidimos', element: <MyDidimos /> },
      { path: 'viewGltf/:key', element: <ViewGltf /> },
      { path: 'preview/:key', element: <PreviewDidimo /> },
      { path: 'didimo/:key/details', element: <DidimoDetails /> },
      { path: 'transactions', element: <Transactions /> },
      { path: 'packages/details', element: <Details /> },
      { path: 'notifications', element: <Notification /> },
      { path: 'packages', element: <Packages /> },
      { path: 'packages/#selectNewPackage=true', element: <Packages /> },
      { path: 'developers/applications', element: <Application /> },
      { path: 'templates', element: <Templates /> },
      { path: 'developers/market-place', element: <MarketPlace /> },
      { path: 'checkout', element: <Checkout /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Login /> },
      { path: `login`, element: <Login /> },
      { path: `register`, element: <Register /> },
      { path: `register/finalizeSignup`, element: <FinalizeSignup /> },
      { path: `404`, element: <NotFound /> },
      { path: `confirm`, element: <Confirm /> },
      { path: `forgot`, element: <ForgotPassword /> },
      { path: `forgotchange`, element: <ForgotPasswordChange /> },
      { path: 'profile/login', element: <ProfileLogin /> },
      { path: '*', element: <Login /> },
    ],
  },
];

export default routes;
