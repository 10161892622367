import React from 'react';
import { FormGroup, Grid } from '@mui/material';

const MetaInfoVersion259 = (props) => {
  const { isEnableElement } = props;
  return (
    <div>
      <Grid container>
        <Grid item container style={{ marginTop: 10 }}>
          <Grid item lg={5} xs={4}>
            <div className="info-text">
              <h4>Input Type</h4>
            </div>
          </Grid>
          <Grid item lg={7} xs={8}>
            <div className="elementContainer" style={{ textAlign: 'right' }}>
              <FormGroup>
                {isEnableElement('input_type', 'photo') && 'Photo'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('input_type', 'rgbd') && 'Photo + Depth'}
              </FormGroup>
            </div>
          </Grid>
        </Grid>

        <Grid item container style={{ marginTop: 10 }}>
          <Grid item lg={5} xs={4}>
            <div className="info-text">
              <h4>Transfer Formats</h4>
            </div>
          </Grid>
          <Grid item lg={7} xs={8}>
            <div className="elementContainer" style={{ textAlign: 'right' }}>
              <FormGroup>
                {isEnableElement('transfer_formats', 'fbx') && 'FBX'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('transfer_formats', 'gltf') && 'glTF'}
              </FormGroup>
            </div>
          </Grid>
        </Grid>

        {(isEnableElement('simple_poses', 'true') ||
          isEnableElement('arkit', 'true') ||
          isEnableElement('oculus_lipsync', 'true') ||
          isEnableElement('aws_polly', 'true')) && (
          <Grid item container style={{ marginTop: 10 }}>
            <Grid item lg={5} xs={4}>
              <div className="info-text">
                <h4>Animation Capability</h4>
              </div>
            </Grid>
            <Grid item lg={7} xs={8}>
              <div className="elementContainer" style={{ textAlign: 'right' }}>
                <FormGroup>
                  {isEnableElement('simple_poses', 'true') &&
                    'Simple Poses: blink/expressions'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('arkit', 'true') && 'ARKit compatible'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('oculus_lipsync', 'true') &&
                    'Oculus Lipsync compatible'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('aws_polly', 'true') &&
                    'Animation Polly compatible'}
                </FormGroup>
              </div>
            </Grid>
          </Grid>
        )}

        {(isEnableElement('max_texture_dimension', '2048') ||
          isEnableElement('max_texture_dimension', '1024') ||
          isEnableElement('max_texture_dimension', '512')) && (
          <Grid item container style={{ marginTop: 10 }}>
            <Grid item lg={5} xs={4}>
              <div className="info-text">
                <h4>Max Texture Dimension</h4>
              </div>
            </Grid>
            <Grid item lg={7} xs={8}>
              <div className="elementContainer" style={{ textAlign: 'right' }}>
                <FormGroup>
                  {isEnableElement('max_texture_dimension', '2048') && '2048'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('max_texture_dimension', '1024') && '1024'}
                </FormGroup>
                <FormGroup>
                  {isEnableElement('max_texture_dimension', '512') && '512'}
                </FormGroup>
              </div>
            </Grid>
          </Grid>
        )}

        <Grid item container style={{ marginTop: 10 }}>
          <Grid item lg={5} xs={4}>
            <div className="info-text">
              <h4>Avatar Structure</h4>
            </div>
          </Grid>
          <Grid item lg={7} xs={8}>
            <div className="elementContainer" style={{ textAlign: 'right' }}>
              <FormGroup>
                {isEnableElement('avatar_structure', 'head-only') &&
                  'Head Only'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('avatar_structure', 'full-body') &&
                  'Full Body'}
              </FormGroup>
            </div>
          </Grid>
        </Grid>

        {isEnableElement('avatar_structure', 'full-body') && (
          <>
            <Grid item container style={{ marginTop: 10 }}>
              <Grid item lg={5} xs={4}>
                <div className="info-text">
                  <h4>Garment</h4>
                </div>
              </Grid>
              <Grid item lg={7} xs={8}>
                <div
                  className="elementContainer"
                  style={{ textAlign: 'right' }}
                >
                  <FormGroup>
                    {isEnableElement('garment', 'none') && 'None'}
                  </FormGroup>
                  <FormGroup>
                    {isEnableElement('garment', 'casual') && 'Casual'}
                  </FormGroup>
                  <FormGroup>
                    {isEnableElement('garment', 'sporty') && 'Sporty'}
                  </FormGroup>
                  <FormGroup>
                    {isEnableElement('garment', 'business') && 'Business'}
                  </FormGroup>
                </div>
              </Grid>
            </Grid>

            <Grid item container style={{ marginTop: 10 }}>
              <Grid item lg={5} xs={4}>
                <div className="info-text">
                  <h4>Gender</h4>
                </div>
              </Grid>
              <Grid item lg={7} xs={8}>
                <div
                  className="elementContainer"
                  style={{ textAlign: 'right' }}
                >
                  <FormGroup>
                    {isEnableElement('gender', 'auto') && 'Auto'}
                  </FormGroup>
                  <FormGroup>
                    {isEnableElement('gender', 'male') && 'Male'}
                  </FormGroup>
                  <FormGroup>
                    {isEnableElement('gender', 'female') && 'Female'}
                  </FormGroup>
                </div>
              </Grid>
            </Grid>
          </>
        )}

        <Grid item container style={{ marginTop: 10 }}>
          <Grid item lg={5} xs={4}>
            <div className="info-text">
              <h4>Hair</h4>
            </div>
          </Grid>
          <Grid item lg={7} xs={8}>
            <div className="elementContainer" style={{ textAlign: 'right' }}>
              <FormGroup>{isEnableElement('hair', 'none') && 'None'}</FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_001') && 'Hair 001'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_002') && 'Hair 002'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_003') && 'Hair 003'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_004') && 'Hair 004'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_005') && 'Hair 005'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_006') && 'Hair 006'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_007') && 'Hair 007'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_008') && 'Hair 008'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_009') && 'Hair 009'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_010') && 'Hair 010'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'hair_011') && 'Hair 011'}
              </FormGroup>
              <FormGroup>
                {isEnableElement('hair', 'baseball_cap') && 'Baseball Cap'}
              </FormGroup>
            </div>
          </Grid>
        </Grid>

        {isEnableElement('remove_beard', 'true') && (
          <Grid item container style={{ marginTop: 10 }}>
            <Grid item lg={5} xs={4}>
              <div className="info-text">
                <h4>Options</h4>
              </div>
            </Grid>
            <Grid item lg={7} xs={8}>
              <div className="elementContainer" style={{ textAlign: 'right' }}>
                <FormGroup>
                  {isEnableElement('remove_beard', 'true') && 'Remove Beard'}
                </FormGroup>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MetaInfoVersion259;
