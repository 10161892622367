export const formatPoints = (value, fractionDigits) =>
  parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: fractionDigits === undefined ? 2 : fractionDigits,
    maximumFractionDigits: fractionDigits === undefined ? 2 : fractionDigits,
  });

export const formatDate = (value) => new Date(value).toLocaleDateString();

export const formatDateTime = (value) => new Date(value).toLocaleString();

export const formatCurrency = (value, fractionDigits) =>
  `$${parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: fractionDigits === undefined ? 2 : fractionDigits,
    maximumFractionDigits: fractionDigits === undefined ? 2 : fractionDigits,
  })}`;
