import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Alert,
} from '@material-ui/core';
import Amplify, { Auth } from 'aws-amplify';

const ForgotPasswordChange = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const username = new URLSearchParams(search).get('username');
  const code = new URLSearchParams(search).get('code');

  const handleReSendClick = async () => {
    var raw = JSON.stringify({
      email_address: username,
    });

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Didimo-Platform', process.env.REACT_APP_PLATFORM);
    myHeaders.append('Didimo-Platform-Version', process.env.REACT_APP_VERSION);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    var createCodeURL =
      process.env.REACT_APP_API_ROOT + 'accounts/resetting-password';

    const res = await fetch(createCodeURL, requestOptions);
    const data = await res.json();

    setError('A new code has been emailed');
  };

  async function signUp(values) {
    if (values) {
      try {
        try {
          if (username != '') {
            var raw = JSON.stringify({
              code: values.code,
              password: values.password,
            });

            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Didimo-Platform', process.env.REACT_APP_PLATFORM);
            myHeaders.append(
              'Didimo-Platform-Version',
              process.env.REACT_APP_VERSION
            );

            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
            };

            var createCodeURL =
              process.env.REACT_APP_API_ROOT +
              'accounts/resetting-password/' +
              encodeURIComponent(username);

            const res = await fetch(createCodeURL, requestOptions);

            if (res.status === 200) {
              navigate(`${process.env.REACT_APP_ROOT_PATH}/login`);
            } else {
              setError('An Error has occured please try again');
            }
          }
        } catch (error) {
          setError(error);
          console.log('error confirming sign up', error);
        }
      } catch (err) {
        setError(err);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | Didimo</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              code: code,
            }}
            validationSchema={Yup.object().shape({
              code: Yup.string()
                .max(255)
                .required('verification code is required'),
              password: Yup.string().max(255).required('password is required'),
            })}
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = 'Required';
              } else if (
                !RegExp(
                  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,]).{8,}$'
                ).test(values.password)
              ) {
                errors.password =
                  'Invalid password - Password must contain a lower case letter, an upper case letter, a special character, a number and at least 8 characters';
              } else if (
                values.new_password &&
                values.password !== values.new_password
              ) {
                errors.new_password = 'Passwords do not match';
              }

              return errors;
            }}
            onSubmit={signUp}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    gutterBottom="true"
                  >
                    {isSubmitting}
                    Change Your Password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    gutterBottom="true"
                  ></Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    A reset code has been sent to {username}. Please enter it
                    below and create a new password
                  </Typography>
                </Box>

                {error && (
                  <Alert
                    severity="error"
                    style={{ marginBottom: '5px', cursor: 'pointer' }}
                  >
                    {error}

                    {error != 'A new code has been emailed' ? (
                      <div onClick={() => handleReSendClick()}>
                        Click here to re send code
                      </div>
                    ) : null}
                  </Alert>
                )}

                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  helperText={
                    touched.code && errors.code
                      ? ' Verification code is required '
                      : ''
                  }
                  label="Verification Code"
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.code}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={
                    values.password &&
                    touched.password &&
                    errors.password &&
                    'Invalid password - Password must contain a lower case letter, an upper case letter, a special character, a number and at least 8 characters'
                  }
                  label="New Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  attributesInput={{
                    id: 'newPasswordConfirmation',
                  }}
                  name="new_password"
                  type="password"
                  label="Confirm new password *"
                  margin="normal"
                  variant="outlined"
                  value={values.new_password} // Optional.[String].Default: "".
                  onChange={handleChange}
                  error={
                    values.new_password &&
                    values.password !== values.new_password
                  }
                  helperText={
                    values.new_password &&
                    values.password !== values.new_password &&
                    "Passwords don't match."
                  }
                  validationOption={{
                    name: 'new password', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />

                <Box sx={{ py: 2 }} className={'SpinnerContainer'}>
                  <Button
                    className={'signIn-btn'}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPasswordChange;
