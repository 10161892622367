import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { addToCart, isUserAuthenticated } from '../../context/actions';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import {
  Autocomplete,
  Backdrop,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Slider,
  TextField,
  Typography,
  IconButton,
  Divider,
  CardHeader,
  Tooltip,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import USStatesField from '../../components/USStatesField';
import USCityField from '../../components/USCityField';
import EmailField from '../../components/EmailField';
import { GlobalContext } from '../../context/store';
import { CircularProgress } from '@mui/material';
import ShoppingCart from '@material-ui/icons/ShoppingBagOutlined';
import Alert from '@mui/material/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorSnack from '../../components/ErrorSnack';
import red from '@material-ui/core/colors/red';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const sliderOptions = [
  {
    value: 0,
    label: 'User Information',
  },
  {
    value: 100,
    label: 'Review',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardHeaderTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backArrow: {
    color: '#2a0651',
    fontSize: '23px',
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
  rangeSlider: {
    width: '40%',
    display: 'block',
    margin: '0 auto',
  },
}));

const Checkout = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(GlobalContext);

  const [countrySelectedCode, setCountrySelectedCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryList, setCountryList] = useState('');
  const [countryError, setCountryError] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  // const [selectedPlan, setSelectedPlan] = useState('');

  const [streetAddressLn1, setStreetAddressLn1] = useState('');
  const [streetAddressLn2, setStreetAddressLn2] = useState('');
  const [states, setStates] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [taxId, setTaxId] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountEmailAddress, setAccountEmailAddress] = useState('');
  const [isCompanyAccount, setIsCompanyAccount] = useState(false);
  const [touched, setTouched] = useState({});
  const [isOrderCreating, setIsOrderCreating] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(true);
  const [removeSnackbar, setRemoveSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (countryCode != '') {
      axios.get('geo/countries', {}).then((res) => {
        setCountryList(res.data);
        let selectedCountry = res.data.find(
          (country) => country.iso_code2 === countryCode
        ) || { __type: '', iso_code2: '', name: '' };
        setCountrySelectedCode(selectedCountry);
        setIsDataLoaded(true);
      });
    }
  }, [countryCode]);

  useEffect(async () => {
    axios.get('profiles/me').then((res) => {
      let requiredFields = ['givenName', 'familyName', 'country'];
      if (res.data.accounts[0].address.country === 'US') {
        requiredFields.push('state');
      }
      setGivenName(res.data.given_name);
      setFamilyName(res.data.family_name);
      setEmail(res.data.email_address);
      setCountryCode(res.data.accounts[0].address.country);
      setStreetAddressLn1(res.data.accounts[0].address.street_address_line1);
      setStreetAddressLn2(res.data.accounts[0].address.street_address_line2);
      setStates(res.data.accounts[0].address.state);
      setCity(res.data.accounts[0].address.city);
      setZipCode(res.data.accounts[0].address.zip_code);
      setTaxId(res.data.accounts[0].tax_id);
      setAccountName(res.data.accounts[0].company_name);
      setAccountEmailAddress(res.data.accounts[0].email_address);
      setIsCompanyAccount(res.data.accounts[0].is_company);
      // setSelectedPlan(plan);

      (async () => {
        try {
          if (state.isAuth === false) {
            dispatch(await isUserAuthenticated(navigate, 'checkout'));
          }
        } catch (err) {
          console.log(err);
        }
      })();
      setIsShowLoader(false);
    });
  }, []);

  const getCartData = async () => {
    await axios.get(`billing/basket`).then((res) => {
      dispatch(addToCart(res.data.accountProductsBasket));
    });
  };

  const RemoveCart = async (uuid) => {
    await axios.delete(`billing/basket/${uuid}`);
    setRemoveSnackbar(true);
    getCartData();
  };

  const getTotalPrice = () => {
    if (state.cartData && state.cartData !== '') {
      let total = 0;
      {
        state.cartData.map((item) => {
          let price = item.product.current_product_price
            ? item.product.current_product_price.price
            : item.product.price;
          total = total + price;
        });
      }
      return total;
    }
  };

  const onCountryChange = (e, selected) => {
    let requiredFields = ['givenName', 'familyName', 'country'];
    if (selected && countryCode !== selected.iso_code2) {
      setCountryCode(selected.iso_code2);
      setStates('');
      setCity('');
      setZipCode('');

      if (selected.iso_code2 === 'US') {
        requiredFields.push('state');
      }
    }
  };

  const onStateChange = (e, selected) => {
    if (selected && states !== selected.code) {
      setStates(selected.code);
      setCity('');
      setZipCode('');
    }
  };

  const onCityChange = (e, selected) => {
    if (selected && city !== selected.code) {
      setCity(selected.code);
      setZipCode('');
    }
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOrderCreating(true);

    let data = [
      {
        op: 'replace',
        path: '/address/street_address_line1',
        value: streetAddressLn1,
      },
      {
        op: 'replace',
        path: '/address/street_address_line2',
        value: streetAddressLn2,
      },
      {
        op: 'replace',
        path: '/address/state',
        value: states,
      },
      {
        op: 'replace',
        path: '/address/city',
        value: city,
      },
      {
        op: 'replace',
        path: '/address/country',
        value: countryCode,
      },
      {
        op: 'replace',
        path: '/address/zip_code',
        value: zipCode,
      },
      {
        op: 'replace',
        path: '/tax_id',
        value: isCompanyAccount ? taxId : '',
      },
      {
        op: 'replace',
        path: '/email_address',
        value: isCompanyAccount ? accountEmailAddress : '',
      },
      {
        op: 'replace',
        path: '/is_company',
        value: isCompanyAccount,
      },
      {
        op: 'replace',
        path: '/company_name',
        value: isCompanyAccount ? accountName : '',
      },
    ];

    let payloadNew = {
      family_name: familyName,
      given_name: givenName,
    };
    setErrorMessage(null);
    await Promise.all([
      axios.patch('accounts/' + state.user.accounts.uuid, data, {
        headers: { Authorization: `Bearer ${state.user.accessToken}` },
      }),
      axios.put('profiles/' + state.user.profile_uuid, payloadNew, {
        headers: { Authorization: `Bearer ${state.user.accessToken}` },
      }),
    ]);

    const orderPayload = { basket_checkout: true };
    axios
      .post('services/payments/stripe/orders', orderPayload, {
        headers: { Authorization: `Bearer ${state.user.accessToken}` },
      })
      .then(async (res) => {
        await axios.get(`billing/basket`).then((res) => {
          dispatch(addToCart(res.data.accountProductsBasket));
        });
        navigate(`/orders#checkout=${res.data.uuid}`);
        setIsOrderCreating(false);
      })
      .catch(async (err) => {
        setErrorSnackbar(true);
        await setErrorMessage(err.response.data.message);
        setIsOrderCreating(false);
      });
  };

  return (
    <div className={'checkoutContainer'}>
      <Backdrop open={isShowLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>Checkout | Didimo</title>
      </Helmet>

      {isDataLoaded === true && (
        <div className={classes.root}>
          <Grid container spacing={2} style={{ marginTop: '0px' }}>
            <Grid item xs={12} style={{ padding: '16px' }}>
              <Card style={{ padding: '16px' }}>
                <CardContent className="user_detail">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <div style={{ display: 'flex' }}>
                        <ArrowBackIcon
                          className={classes.backArrow}
                          onClick={() => navigate(-1)}
                        />
                        <Typography
                          variant="h4"
                          sx={{ fontWeight: 'bold', paddingBottom: '16px' }}
                        >
                          User data
                        </Typography>
                      </div>
                      <CardContent style={{ padding: 0 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <FormControlLabel
                              value="end"
                              control={
                                <Checkbox
                                  checked={isCompanyAccount}
                                  onChange={() => {
                                    setIsCompanyAccount(!isCompanyAccount);
                                  }}
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'isCompanyAccount',
                                    'data-id': 'isCompanyAccount',
                                  }}
                                />
                              }
                              label="This is a company account"
                              labelPlacement="end"
                            />
                          </Grid>

                          {!isCompanyAccount && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  value={givenName}
                                  onChange={(e) => {
                                    setGivenName(e.target.value);
                                  }}
                                  required
                                  fullWidth
                                  id="user-given-name"
                                  label="Given name"
                                  variant="outlined"
                                  error={
                                    touched['givenName'] && !givenName?.trim()
                                  }
                                  helperText={
                                    touched['givenName'] &&
                                    !givenName?.trim() &&
                                    'This field is required'
                                  }
                                  onBlur={(e) => {
                                    setTouched({ ...touched, givenName: true });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  value={familyName}
                                  onChange={(e) => {
                                    setFamilyName(e.target.value);
                                  }}
                                  required
                                  fullWidth
                                  id="user-family-name"
                                  label="Family name"
                                  variant="outlined"
                                  error={
                                    touched['familyName'] && !familyName?.trim()
                                  }
                                  helperText={
                                    touched['familyName'] &&
                                    !familyName?.trim() &&
                                    'This field is required'
                                  }
                                  onBlur={(e) => {
                                    setTouched({
                                      ...touched,
                                      familyName: true,
                                    });
                                  }}
                                />
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12}>
                            <Autocomplete
                              id="Country"
                              required
                              options={countryList}
                              getOptionLabel={(option) => `${option?.name}`}
                              onChange={onCountryChange}
                              onInputChange={(e, value, operation) => {
                                if (operation == 'clear') {
                                  onCountryChange(e, { iso_code2: '' });
                                }
                              }}
                              defaultValue={countrySelectedCode}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  helperText={countryError}
                                  label="Country *"
                                  name="countrycode"
                                  variant="outlined"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          {countryCode?.toUpperCase() === 'US' && (
                            <Grid item xs={12}>
                              <USStatesField
                                required
                                fullWidth
                                onChange={onStateChange}
                                value={states}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              value={streetAddressLn1}
                              onChange={(e) => {
                                setStreetAddressLn1(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              label="Address (line 1)"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'streetAddressLn1',
                                'data-id': 'streetAddressLn1',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={streetAddressLn2}
                              onChange={(e) => {
                                setStreetAddressLn2(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              label="Address (line 2)"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'streetAddressLn2',
                                'data-id': 'streetAddressLn2',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <USCityField
                              fullWidth
                              countryState={state}
                              onChange={onCityChange}
                              value={city}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={zipCode}
                              onChange={(e) => {
                                setZipCode(e.target.value);
                              }}
                              fullWidth
                              label="ZIP Code"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'zipCode',
                                'data-id': 'zipCode',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={accountName}
                              onChange={(e) => {
                                setAccountName(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              disabled={!isCompanyAccount}
                              label="Company name"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'accountName',
                                'data-id': 'accountName',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <EmailField
                              value={accountEmailAddress}
                              onChange={(e) => {
                                setAccountEmailAddress(e.target.value);
                              }}
                              disabled={!isCompanyAccount}
                              fullWidth
                              label="Company email"
                              inputProps={{
                                'aria-label': 'accountEmailAddress',
                                'data-id': 'accountEmailAddress',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={taxId}
                              onChange={(e) => {
                                setTaxId(e.target.value);
                              }}
                              fullWidth
                              placeholder=""
                              disabled={!isCompanyAccount}
                              label="Tax ID"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'taxid',
                                'data-id': 'taxId',
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {countryCode && countryCode.toUpperCase()}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Card style={{ width: '100%' }}>
                        <CardHeader
                          title={
                            <Typography variant={'h4'}>Cart Summary</Typography>
                          }
                        />
                        {state.cartData && state.cartData.length > 0 ? (
                          <CardContent>
                            {state.cartData.map((item) => {
                              return (
                                <div>
                                  <Grid
                                    container
                                    display="flex"
                                    style={{
                                      justifyContent: 'flex-end',
                                      padding: 10,
                                    }}
                                    spacing={2}
                                  >
                                    <Grid
                                      item
                                      container
                                      direction="row"
                                      justifyContent="flex-end"
                                      style={{
                                        flexGrow: '1',
                                        flexShrink: '1',
                                        flexBasis: '170px',
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        <Typography gutterBottom variant="h5">
                                          {item.product.name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      style={{
                                        width: 'auto',
                                      }}
                                    >
                                      <Grid item style={{}}>
                                        <Typography
                                          variant="h3"
                                          style={{
                                            textAlign: 'center',
                                            fontSize: '16px',
                                          }}
                                        >
                                          $
                                          {item.product.current_product_price
                                            ? item.product.current_product_price
                                                .price
                                            : item.product.price}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          align="right"
                                          style={{
                                            marginTop: '5px',
                                            fontWeight: 'normal',
                                          }}
                                        >
                                          plus taxes
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      style={{
                                        width: 'auto',
                                      }}
                                    >
                                      <Grid item>
                                        <Typography
                                          variant="h3"
                                          className={'remove'}
                                          style={{
                                            textAlign: 'center',
                                            fontSize: '16px',
                                          }}
                                        >
                                          <Tooltip title="Delete">
                                            <IconButton
                                              size="small"
                                              onClick={() =>
                                                RemoveCart(item.uuid)
                                              }
                                              style={{
                                                color: red[500],
                                                marginLeft: '10px',
                                              }}
                                              type="submit"
                                            >
                                              <DeleteOutlinedIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Divider
                                    style={{
                                      margin: '10px 0px 10px 10px',
                                      height: '1px',
                                    }}
                                  />
                                </div>
                              );
                            })}

                            <Grid
                              container
                              display="flex"
                              style={{
                                justifyContent: 'flex-end',
                                padding: 10,
                              }}
                              spacing={2}
                            >
                              <Grid
                                container
                                item
                                style={{
                                  width: 'auto',
                                  gap: '1rem',
                                }}
                              >
                                <Grid
                                  item
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    Total :
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                style={{
                                  width: 'auto',
                                  gap: '1rem',
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    variant="h3"
                                    style={{
                                      textAlign: 'center',
                                      fontSize: '16px',
                                    }}
                                  >
                                    {'$' + getTotalPrice().toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        ) : (
                          <CardContent
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '60%',
                              alignItems: 'center',
                            }}
                          >
                            <Grid>
                              <Typography variant="h3">
                                No Item available in Cart
                              </Typography>
                            </Grid>
                          </CardContent>
                        )}
                      </Card>
                    </Grid>
                  </Grid>

                  <form onSubmit={handlePlaceOrder}>
                    <div className={'createOrderFooter'}>
                      <Button
                        sx={{ textTransform: 'none' }}
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        onClick={() => navigate(-1)}
                      >
                        Close
                      </Button>
                      <Button
                        sx={{ textTransform: 'none' }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px' }}
                        disabled={
                          !givenName ||
                          !familyName ||
                          !countryCode ||
                          (countryCode === 'US' && !states) ||
                          !state.cartData.length
                        }
                      >
                        {isOrderCreating ? (
                          <CircularProgress />
                        ) : (
                          <span style={{ fontWeight: 'bold' }}>
                            Place Order
                          </span>
                        )}
                      </Button>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
      <Snackbar
        open={removeSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setRemoveSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setRemoveSnackbar(false);
          }}
          severity="error"
        >
          Item is removed from Cart
        </Alert>
      </Snackbar>
      <ErrorSnack
        open={errorSnackbar}
        onClose={(e) => {
          setErrorSnackbar(false);
          setErrorMessage(null);
        }}
        message={errorMessage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      />
    </div>
  );
};

export default Checkout;
