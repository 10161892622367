import { SvgIcon } from '@material-ui/core';

const Apple = (props) => (
  <SvgIcon {...props}>
    <path d="M10.17 5.37C9.96 4.24 10.37 2.91 11.27 1.82C12.09 0.82 13.45 0.09 14.65 0C14.93 -0.02 15.17 0.18 15.21 0.45C15.39 1.65 15 2.96 14.15 4.06C13.32 5.12 12 5.81 10.78 5.81C10.78 5.81 10.78 5.81 10.78 5.81C10.74 5.81 10.71 5.81 10.67 5.81C10.43 5.8 10.22 5.62 10.17 5.37ZM11.17 4.72C11.96 4.59 12.78 4.09 13.31 3.41C13.59 3.06 14.16 2.2 14.2 1.15C13.42 1.35 12.6 1.87 12.09 2.49C11.86 2.77 11.19 3.67 11.17 4.72Z" />
    <path d="M19.92 16.38C19.45 17.42 19.22 17.9 18.6 18.85C17.6 20.37 16.35 21.93 14.71 21.94L14.68 21.94C13.98 21.94 13.52 21.73 13.07 21.53C12.61 21.31 12.17 21.11 11.36 21.11L11.33 21.11C10.51 21.12 10.06 21.32 9.58 21.53C9.12 21.74 8.65 21.95 7.95 21.95L7.92 21.95C6.24 21.93 5.04 20.45 4.09 19C2.41 16.45 1.67 13.39 2.1 10.82C2.26 9.91 2.54 9.14 2.95 8.51C4.04 6.82 5.8 5.78 7.53 5.78C8.43 5.78 9.15 6.03 9.79 6.26C10.33 6.45 10.8 6.62 11.31 6.62C11.78 6.62 12.18 6.47 12.65 6.29C13.29 6.05 14.01 5.78 15.08 5.78C16.6 5.78 18.17 6.6 19.28 7.98C19.37 8.1 19.41 8.26 19.38 8.41C19.35 8.56 19.26 8.69 19.12 8.77C18.29 9.23 17.69 9.92 17.38 10.77C16.77 12.49 17.45 14.85 19.63 15.67C19.76 15.72 19.87 15.82 19.93 15.96C19.98 16.09 19.98 16.25 19.92 16.38ZM18.74 16.43C16.6 15.32 15.55 12.74 16.39 10.41C16.71 9.52 17.28 8.76 18.06 8.19C17.2 7.33 16.12 6.83 15.08 6.83C14.21 6.83 13.61 7.06 13.03 7.28C12.51 7.48 11.97 7.68 11.31 7.68C10.61 7.68 10.01 7.46 9.43 7.26C8.82 7.04 8.25 6.83 7.53 6.83C6.15 6.83 4.74 7.7 3.84 9.09C3.51 9.59 3.28 10.24 3.15 11C2.75 13.31 3.43 16.08 4.97 18.42C5.72 19.57 6.74 20.88 7.93 20.89L7.95 20.89C8.42 20.89 8.74 20.75 9.14 20.57C9.65 20.34 10.28 20.06 11.33 20.06L11.36 20.06C12.4 20.06 13.02 20.34 13.51 20.57C13.9 20.75 14.21 20.89 14.68 20.89L14.7 20.89C15.85 20.88 16.92 19.48 17.72 18.27C18.19 17.54 18.42 17.12 18.74 16.43Z" />
  </SvgIcon>
);

export default Apple;
