import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { /* makeStyles, */ useTheme } from '@material-ui/styles';
// import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  // Grid,
  // Divider,
  Button,
  // Modal,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  // DialogContentText,
  Grid,
  TextField,
  // Backdrop,
  // Fade,
  // CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
// import appConfig from "../../../components/AppConfig";
// import PackageCard from "./PackageCard";
import EmailField from './EmailField';

/* const useStyles = makeStyles(theme => ({
  modal: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    //justifyContent: "center",
    maxHeight: "100%",
    maxWidth: "100%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      //padding: "20px 20px 20px 20px"
    },
    margin: "auto"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
})); */

const ContactUs = (props) => {
  const {
    // value,
    // className,
    open,
    onSubmit,
    onClose,
    // onSendSuccess
    // ...rest
  } = props;
  const [isSending, setIsSending] = useState(false);
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const isContactDataFilled = () =>
    contactData &&
    contactData.name &&
    contactData.name.trim().length > 0 &&
    contactData.email &&
    contactData.email.trim().length > 0 &&
    contactData.message &&
    contactData.message.trim().length > 0;

  // const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* const iconClass = value
    ? {
        pro: classes.gold,
        indie: classes.silver,
        enterprise: classes.platinum,
        free: classes.bronze
      }[value.toLowerCase()]
    : classes.bronze; */

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby="configure-api-key-title"
    >
      <DialogTitle id="configure-api-key-title">Talk to us</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              onChange={(e) => {
                const { email, message } = contactData;
                setContactData({
                  name: e.target.value,
                  email,
                  message,
                });
              }}
              // value={}
              inputProps={{
                'aria-label': 'name',
                'data-id': 'name',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailField
              fullWidth
              label="Email"
              onChange={(e) => {
                const { name, message } = contactData;
                setContactData({
                  name,
                  email: e.target.value,
                  message,
                });
              }}
              inputProps={{
                'aria-label': 'email',
                'data-id': 'email',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={10}
              variant="outlined"
              aria-label="message"
              placeholder=""
              label="Message"
              defaultValue=""
              onChange={(e) => {
                const { email, name } = contactData;
                setContactData({
                  name,
                  email,
                  message: e.target.value,
                });
              }}
              inputProps={{
                'aria-label': 'message',
                'data-id': 'message',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setIsSending(true);
            onSubmit(contactData).then(() => {
              setIsSending(false);
              onClose();
            });
          }}
          disabled={!isContactDataFilled() || isSending}
          color="primary"
        >
          Send
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactUs;
