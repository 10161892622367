const packages = [
  {
    code: 'trial',
    name: 'Trial',
    id: '',
    action: 'register',
    features: {
      price: null,
      price1: null,
      points: { text: '20 points' },
      term: { text: '90 days' },
      commercial_use: { available: false },

      photo: { available: true, cost: 2 },
      rgbd: { available: true, cost: 2 },
      simple_poses: { available: true, cost: 0.25 },
      aws_polly: { available: true, cost: 0.5 },
      arkit: { available: false },
      oculus_lipsync: { available: false },
      max_texture_dimension: { available: false },
      full_body: { available: true, cost: 0.5 },

      didimo_mesh_generation: { available: true, cost: 2 },
      real_time_rig: { available: true, cost: 0.5 },
      real_time_rig_facs: { available: false },
      visemes_for_speech: { available: false },
      real_time_rig_jaw_rot_eye_blinks: { available: true },
      output_fbx: { available: true },
      on_demand_full_blend_shapes: { available: false },
      on_demand_vertex_deform: { available: true },
      gender: { available: true },
      garment: { available: true },
      hair: { available: true },
      remove_beard: { available: true },
      body_pose: { available: true },
      profile: { available: true },
    },
  },
  {
    code: 'starter',
    name: 'Starter',
    id: '',
    action: 'buy',
    features: {
      price: { price: 100 },
      price1: { price: 50 },
      points: { text: '125 points' },
      term: { text: '30 days' },
      commercial_use: { available: true },

      photo: { available: true, cost: 2 },
      rgbd: { available: true, cost: 2 },
      simple_poses: { available: true, cost: 0.25 },
      aws_polly: { available: true, cost: 0.5 },
      arkit: { available: false },
      oculus_lipsync: { available: false },
      max_texture_dimension: { available: false },
      full_body: { available: true, cost: 0.5 },

      didimo_mesh_generation: { available: true, cost: 2 },
      real_time_rig: { available: true, cost: 0.5 },
      real_time_rig_facs: { available: false },
      visemes_for_speech: { available: true, cost: 0.75 },
      real_time_rig_jaw_rot_eye_blinks: { available: true },
      output_fbx: { available: true },
      on_demand_full_blend_shapes: { available: false },
      on_demand_vertex_deform: { available: true },
      gender: { available: true },
      garment: { available: true },
      hair: { available: true },
      remove_beard: { available: true },
      body_pose: { available: true },
      profile: { available: true },
    },
  },
  {
    code: 'advanced',
    name: 'Advanced',
    id: '',
    action: 'buy',
    features: {
      price: { price: 350 },
      price1: { price: 175 },
      points: { text: '650 points' },
      term: { text: '30 days' },
      commercial_use: { available: true },

      photo: { available: true, cost: 2 },
      rgbd: { available: true, cost: 2 },
      simple_poses: { available: true, cost: 0.25 },
      aws_polly: { available: true, cost: 0.5 },
      arkit: { available: true, cost: 0.5 },
      oculus_lipsync: { available: true, cost: 0.5 },
      max_texture_dimension: { available: true },
      full_body: { available: true, cost: 0.5 },

      didimo_mesh_generation: { available: true, cost: 2 },
      real_time_rig: { available: true, cost: 0.5 },
      real_time_rig_facs: { available: true, cost: 0.75 },
      visemes_for_speech: { available: true, cost: 0.75 },
      real_time_rig_jaw_rot_eye_blinks: { available: true },
      output_fbx: { available: true },
      on_demand_full_blend_shapes: { available: false },
      on_demand_vertex_deform: { available: true },
      gender: { available: true },
      garment: { available: true },
      hair: { available: true },
      remove_beard: { available: true },
      body_pose: { available: true },
      profile: { available: true },
    },
  },
];

const services = [
  {
    name: '',
    services: [
      { code: 'price', name: 'Price' },
      { code: 'points', name: 'Points' },
      { code: 'term', name: 'Term' },
      { code: 'commercial_use', name: 'Commercial use allowed' },
    ],
  },
  {
    name: 'Didimo Base Mesh Generation Method (FBX and glTF output)',
    services: [
      {
        code: 'photo',
        name: 'Single RGB Image (Selfie)',
        apiActions: [{ name: 'photo' }],
      },
      {
        code: 'rgbd',
        name: 'Photo + Depth (iPhone)',
        apiActions: [{ name: 'rgbd' }],
      },
    ],
  },
  {
    name: 'Options',
    services: [
      {
        code: 'simple_poses',
        name: 'Simple Poses',
        apiActions: [{ name: 'simple_poses' }],
      },
      {
        code: 'aws_polly',
        name: 'AWS Polly Visemes',
        apiActions: [{ name: 'aws_polly' }],
      },
      {
        code: 'arkit',
        name: 'ARKit Poses',
        apiActions: [{ name: 'arkit' }],
      },
      {
        code: 'oculus_lipsync',
        name: 'Oculus Lipsync Poses',
        apiActions: [{ name: 'oculus_lipsync' }],
      },

      {
        code: 'full_body',
        name: 'Full Body',
        apiActions: [{ name: 'avatar_structure = full-body' }],
      },
      {
        code: 'garment',
        name: 'Garment',
        apiActions: [{ name: 'garment = none | casual | sporty | business' }],
      },
      {
        code: 'gender',
        name: 'Gender',
        apiActions: [{ name: 'gender = auto | male | female' }],
      },
      {
        code: 'hair',
        name: 'Hair',
        apiActions: [{ name: 'hair = none | basebal_cap | hair_[001-011]' }],
      },
      {
        code: 'remove_beard',
        name: 'Remove Beard',
        apiActions: [{ name: 'remove_beard  = true | false' }],
      },
      ,
      {
        code: 'body_pose',
        name: 'Body Pose ',
        apiActions: [{ name: 'body_pose  = A | T' }],
      },
      ,
      {
        code: 'profile',
        name: 'Profile',
        apiActions: [{ name: 'profile  = optimized | standard | minimal' }],
      },
    ],
  },
];

export { packages, services };
