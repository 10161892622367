import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const Version2512 = (props) => {
  const { isEnableElement } = props;

  return (
    <div className="version_2_5_10_container">
      <div className="element-wrapper">
        <div className="element-title">
          <h4>Input Type</h4>
        </div>
        <div className="ddm-elements input-type-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('input_type', 'photo')}
                />
              }
              label={'Photo'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('input_type', 'rgbd')}
                />
              }
              label={'Photo + Depth'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Transfer Formats</h4>
        </div>
        <div className="ddm-elements transfer-formats-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('transfer_formats', 'fbx')}
                />
              }
              label={'FBX'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('transfer_formats', 'gltf')}
                />
              }
              label={'glTF'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Animation Capability</h4>
        </div>
        <div className="ddm-elements animation-capability-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'simple_poses')}
                />
              }
              label={'Simple Poses: blink/expressions'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'arkit')}
                />
              }
              label={'ARKit compatible'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'oculus_lipsync')}
                />
              }
              label={'Oculus Lipsync compatible'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('animation', 'aws_polly')}
                />
              }
              label={'Animation Polly compatible'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Avatar Structure</h4>
        </div>
        <div className="ddm-elements avatar-structure-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('avatar_structure', 'head-only')}
                />
              }
              label={'Head Only'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('avatar_structure', 'full-body')}
                />
              }
              label={'Full Body'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="body-pose-profile-element-container">
        <div className="c-width m-right">
          <div className="element-wrapper">
            <div className="element-title">
              <h4 className="m0">Body Pose</h4>
            </div>
            <div className="ddm-elements body-pose-elements-container">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('body_pose', 'A')}
                    />
                  }
                  label={'A'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('body_pose', 'T')}
                    />
                  }
                  label={'T'}
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
          </div>

          <div className="element-wrapper">
            <div className="element-title">
              <h4 className="m0">Garment</h4>
            </div>
            <div className="ddm-elements garment-elements-container">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('garment', 'none')}
                    />
                  }
                  label={'None'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('garment', 'casual')}
                    />
                  }
                  label={'Casual'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('garment', 'sporty')}
                    />
                  }
                  label={'Sporty'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('garment', 'business')}
                    />
                  }
                  label={'Business'}
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div>
          <div className="element-wrapper">
            <div className="element-title">
              <h4 className="m0">Gender</h4>
            </div>
            <div className="ddm-elements gender-elements-container">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('gender', 'auto')}
                    />
                  }
                  label={'Auto'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('gender', 'male')}
                    />
                  }
                  label={'Male'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('gender', 'female')}
                    />
                  }
                  label={'Female'}
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
          </div>

          <div className="element-wrapper">
            <div className="element-title">
              <h4 className="m0">Profile</h4>
            </div>
            <div className="ddm-elements profile-elements-container">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('profile', 'standard')}
                    />
                  }
                  label={'Standard'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('profile', 'optimized')}
                    />
                  }
                  label={'Optimized'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isEnableElement('profile', 'minimal')}
                    />
                  }
                  label={'Minimal'}
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Hair</h4>
        </div>
        <div className="ddm-elements hair-elements-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'none')}
                />
              }
              label={'None'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_001')}
                />
              }
              label={'Hair 001'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_002')}
                />
              }
              label={'Hair 002'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_003')}
                />
              }
              label={'Hair 003'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_004')}
                />
              }
              label={'Hair 004'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_005')}
                />
              }
              label={'Hair 005'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_006')}
                />
              }
              label={'Hair 006'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_007')}
                />
              }
              label={'Hair 007'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_008')}
                />
              }
              label={'Hair 008'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_009')}
                />
              }
              label={'Hair 009'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_010')}
                />
              }
              label={'Hair 010'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'hair_011')}
                />
              }
              label={'Hair 011'}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('hair', 'baseball_cap')}
                />
              }
              label={'Baseball Cap'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="element-title">
          <h4 className="m0">Options</h4>
        </div>
        <div className="ddm-elements">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={isEnableElement('remove_beard', 'true')}
                />
              }
              label={'Remove Beard (experimental)'}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default Version2512;
