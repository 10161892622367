import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/styles';
//import axios from 'axios';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { isUserAuthenticated } from '../context/actions';
import { GlobalContext } from '../context/store';
import Banner from './Banner';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 240,
  },
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  backgroundColor: '#eeeeee',
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const useStyles = makeStyles((theme) => ({
  dashboardFooter: {
    textDecoration: 'none',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    display: 'flex',
    justifyContent: 'center',
    padding: '32px',
  },
}));

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  var currentTime = new Date();
  var year = currentTime.getFullYear();

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(
            await isUserAuthenticated(
              navigate,
              window.location.pathname.substr(1)
            )
          );
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  return (
    <>
      <DashboardLayoutRoot>
        <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <DashboardSidebar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              {/* <Banner /> */}
              <Outlet />
              <span className={classes.dashboardFooter}>
                ©{year}
                <a href={'https://www.didimo.co/'} style={{ color: '#2A0651' }}>
                  &nbsp;Didimo, Inc.
                </a>
              </span>
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardLayout;
