import React from 'react';
import banner from '../../assets/Images/banner3.png';
import { Grid, Stack } from '@mui/material';

import { Typography } from '@material-ui/core';

import './style.css';

const BannerText = {
  fontWeight: 'bold',
  fontSize: '40px',
  lineHeight: '0.9em',
  color: '#5bed96',
};

const drawerStyle = {
  drawerWidth: {
    width: '50%',
    ['@media (min-width:780px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '80%',
    },
  },
};
const Banner = () => {
  console.log('pathname', location.pathname);

  var now_utc = new Date().getTime();

  //console.log("now_utc",now_utc -new Date('2022-11-24').getTime() );

  if (new Date('2022-11-24').getTime() <= now_utc) {
    return (
      <Stack
        sx={{ md: { padding: '0 16px' } }}
        style={{ marginLeft: '15px', marginRight: '15px' }}
      >
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          sx={{
            md: { height: '239px' },
            sm: { height: 'auto' },
            overflow: 'hidden',
            background: '#481ca5',
          }}
          className="row"
        >
          <Grid
            md={12}
            sm={12}
            containerStyle={drawerStyle}
            justify="space-between"
            style={{
              padding: '1rem',
            }}
          >
            <div
              class="bannerWrapper"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography className="BannerText" style={{}}>
                <div className="MainHeading">Black Friday Sale!</div>
              </Typography>

              <Typography
                className="BannerText2"
                component={'span'}
                style={{ color: '#FFFFFE' }}
              >
                <div
                  className="text1"
                  style={{
                    fontSize: '18px',
                  }}
                >
                  From November 24 through December 1,{' '}
                  <span
                    style={{
                      fontWeight: '700',
                    }}
                  >
                    Starter Access Packages{' '}
                  </span>
                  and{' '}
                  <span
                    style={{
                      fontWeight: '700',
                      color: '#FFFFFE',
                    }}
                  >
                    Advanced Access Packages{' '}
                  </span>{' '}
                  are&nbsp;
                  <span
                    style={{
                      fontWeight: '700',
                      color: '#5bed96',
                    }}
                  >
                    50% off!
                  </span>
                </div>
              </Typography>
            </div>
          </Grid>
        </Stack>
      </Stack>
    );
  } else {
    return <></>;
  }
};

export default Banner;
