import React, { useState } from 'react';
// import { createCognitoUser } from '../../../helpers/cognitoUtils';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Grid,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SuccessSnack from '../../../components/SuccessSnack';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiGrid-root > .MuiTextField-root, & .MuiGrid-root > .MuiAutocomplete-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    flexGrow: 1,
  },
  actionButton: {
    textAlign: 'right',
    paddingTop: 10,
  },
  changePasswordError: {
    color: '#d32f2f',
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'left',
    marginRight: '14px',
    marginBottom: '10px',
    marginLeft: '14px',
  },
}));

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState(null);
  const [touchedFields, setTouchedFields] = useState({
    oldPassword: false,
    newPassword: false,
    newPasswordConfirmation: false,
  });

  const classes = useStyles();

  const [successMessage, setSuccessMessage] = useState({
    open: false,
    message: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasswordChangeSubmit = (e) => {
    e.preventDefault();

    if (!newPassword || !newPasswordConfirmation || !oldPassword) {
      return;
    }

    if (
      (newPassword && !RegExp(pattern).test(newPassword)) ||
      (newPasswordConfirmation && newPassword !== newPasswordConfirmation)
    ) {
      return;
    }

    Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, oldPassword, newPassword))
      .then((data) => {
        setSuccessMessage({ open: true, message: '' });
        setErrorMessage('');
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  };
  const pattern =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,]).{8,}$';
  return (
    <>
      <Card>
        <CardHeader title="Change Password" />
        <CardContent>
          <form onSubmit={handlePasswordChangeSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      attributesInput={{
                        id: 'oldPassword',
                      }}
                      type="password"
                      label="Old password"
                      variant="outlined"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.currentTarget.value)}
                      error={oldPassword && !oldPassword}
                      helperText={
                        oldPassword && !oldPassword && 'This field is required'
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      attributesInput={{
                        id: 'newPassword',
                      }}
                      type="password"
                      label="New password"
                      variant="outlined"
                      value={newPassword} // Optional.[String].Default: "".
                      onChange={(e) => setNewPassword(e.currentTarget.value)}
                      error={newPassword && !RegExp(pattern).test(newPassword)}
                      helperText={
                        newPassword && !RegExp(pattern).test(newPassword)
                          ? 'Invalid password - Password must contain a lower case letter, an upper case letter, a special character, a number and at least 8 characters'
                          : ''
                      }
                      validationOption={{
                        name: 'new password', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                        check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      attributesInput={{
                        id: 'newPasswordConfirmation',
                      }}
                      type="password"
                      label="Confirm new password *"
                      variant="outlined"
                      value={newPasswordConfirmation} // Optional.[String].Default: "".
                      onChange={(e) =>
                        setNewPasswordConfirmation(e.currentTarget.value)
                      }
                      error={
                        newPasswordConfirmation &&
                        newPassword !== newPasswordConfirmation
                      }
                      helperText={
                        newPasswordConfirmation &&
                        newPassword !== newPasswordConfirmation &&
                        "Passwords don't match."
                      }
                      validationOption={{
                        name: 'new password', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                        check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {errorMessage && (
                  <div className={classes.changePasswordError}>
                    {errorMessage}
                  </div>
                )}
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'capitalize' }}
                  >
                    Change password
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <SuccessSnack
        open={successMessage.open}
        onClose={(e) => {
          setSuccessMessage({ open: false, message: '' });
        }}
        message={'Password Updated'}
      />
    </>
  );
};

export default ChangePassword;
