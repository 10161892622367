import React, { Component, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import '../pages/MyDidimos/MyDidimos.css';
import axios from 'axios';

const EditDidimoModal = (props) => {
  const { isOpen, onCloseModal, accessToken, ddmokey, setDdmMetaInfo } = props;
  const [updateName, setupdateName] = useState('');

  useEffect(() => {
    setupdateName(props.ddmoName);
  }, [props.ddmoName]);

  const handleSave = () => {
    if (props.ddmoName === null || props.ddmoName === undefined) {
      if (!!updateName.trim()) {
        const body = { value: updateName, name: 'name' };
        axios
          .post(`didimos/${ddmokey}/meta_data`, body, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((res) => {
            onCloseModal({ key: ddmokey, metaItem: res.data });
            if (setDdmMetaInfo) {
              axios.get(`didimos/${ddmokey}`).then((response) => {
                setDdmMetaInfo(response.data);
              });
            }
          })
          .catch((err) => {
            console.log(err);
            onCloseModal();
          });
      } else {
        onCloseModal();
      }
    } else {
      if (!updateName.trim()) {
        axios
          .delete(`didimos/${ddmokey}/meta_data/name`, {
            /*headers: { Authorization: `Bearer ${accessToken}` },*/
          })
          .then((res) => {
            onCloseModal({ key: ddmokey, metaItem: res.data });
          })
          .catch((err) => {
            console.log(err);
            onCloseModal();
          });
      } else {
        const body = { value: updateName };
        axios
          .put(`didimos/${ddmokey}/meta_data/name`, body)
          .then((res) => {
            onCloseModal({ key: ddmokey, metaItem: res.data });
          })
          .catch((err) => {
            console.log(err);
            onCloseModal();
          });
      }
    }
  };

  const handleClose = () => {
    onCloseModal();
  };
  return (
    <div>
      <Dialog
        className="edit_modal"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="configure-api-key-title"
      >
        <DialogTitle id="configure-api-key-title" className="font_fam">
          Configure didimo
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: 8 }}
            label="Name"
            variant="outlined"
            fullWidth
            required
            value={updateName}
            onChange={(e) => setupdateName(e.target.value)}
            inputProps={{
              'aria-label': 'name',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" className="font_fam" onClick={handleSave}>
            Save
          </Button>
          <Button color="primary" className="font_fam" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditDidimoModal;
