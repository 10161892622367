import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Chip,
} from '@mui/material';

import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grid,
  Tooltip,
  Paper,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { styled } from '@material-ui/core/styles';
import './FilterModel.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { formatDate } from '../Helpers/formaters';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
}));

const ItemRight = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: 8,
  paddingBottom: 8,
  textAlign: 'right',
  boxShadow: 'none',
}));

const names = ['Done', 'Error'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterModal = ({
  createdAtFrom,
  createdAtTo,
  expiresAtFrom,
  expiresAtTo,
  handleCreatedDateChange,
  handleCreatedDateChangeTo,
  handleExpireDateChange,
  handleExpireDateChangeTo,
  handleFilterChange,
  handleChange,
  status,
  setSortOrder,
  ddmoKey,
  isFavorite,
  sortBy,
  open,
  handleClearFilter,
  handleApplyFilter,
  handleFilterModel,
  totalfilteredSize,
  handleCloseFilter,
}) => {
  const styles = {
    moreData: {
      color: 'black',
      fontSize: '18px',
      marginTop: '4px',
    },
    applyBtn: {
      marginLeft: '10px',
    },
    btnCancel: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textTransform: 'capitalize',
    },
    btnClear: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textTransform: 'capitalize',
      float: 'left',
    },
  };
  const [activeFilters, setActiveFilters] = useState(0);
  const [keyU, setKeyU] = useState('');
  const [isFavoriteU, setIsFavoriteU] = useState(undefined);
  const [createdAtFromU, setCreatedAtFromU] = useState('');
  const [createdAtToU, setCreatedAtToU] = useState('');
  const [expiresAtFromU, setExpiresAtFromU] = useState('');
  const [expiresAtToU, setExpiresAtToU] = useState('');
  const [statusU, setStatusU] = useState([]);
  const [sortByU, setSortByU] = useState('-created_at');

  const getInitialParamsStructure = () => {
    return {
      key: ddmoKey,
      isFavorite: isFavorite,
      createdAtFrom: createdAtFrom,
      createdAtTo: createdAtTo,
      expiresAtFrom: expiresAtFrom,
      expiresAtTo: expiresAtTo,
      status: status,
      sortBy: sortBy,
    };
  };

  const resetFilters = () => {
    let counter = 0;
    if (ddmoKey) {
      counter++;
      setKeyU(ddmoKey);
    } else {
      setKeyU('');
    }
    if (isFavorite) {
      counter++;
      setIsFavoriteU(
        isFavorite === 'true' ? true : isFavorite === 'false' ? false : 'false'
      );
    } else {
      setIsFavoriteU(false);
    }
    if (createdAtFrom) {
      counter++;
      setCreatedAtFromU(new Date(createdAtFrom));
    } else {
      setCreatedAtFromU(undefined);
    }
    if (createdAtTo) {
      counter++;
      setCreatedAtToU(new Date(createdAtTo));
    } else {
      setCreatedAtToU(undefined);
    }
    if (expiresAtFrom) {
      counter++;
      setExpiresAtFromU(new Date(expiresAtFrom));
    } else {
      setExpiresAtFromU(undefined);
    }
    if (expiresAtTo) {
      counter++;
      setExpiresAtToU(new Date(expiresAtTo));
    } else {
      setExpiresAtToU(undefined);
    }
    if (status && status.length > 0) {
      counter++;
      setStatusU(status);
    } else {
      setStatusU([]);
    }

    setSortByU(sortBy || '-created_at');

    setActiveFilters(counter);
  };

  useEffect(() => {
    resetFilters();
  }, [
    createdAtFrom,
    createdAtTo,
    expiresAtFrom,
    expiresAtTo,
    status,
    isFavorite,
    ddmoKey,
  ]);

  return (
    <>
      <div className="filter_modal">
        <Button
          aria-controls="fade-menu"
          aria-haspopup="true"
          className="modelHeader custom-font-family1"
          onClick={handleFilterModel}
        >
          + Filter ({activeFilters})
        </Button>
        {open && (
          <div className="main_content">
            <div className="abc">
              <div className="modelContent">
                <span>Filter</span>
                <div className="new_model">
                  <div className="pqr">
                    <div className="profile-detail">
                      <div className="" style={{ paddingTop: '10px' }}>
                        <TextField
                          fullWidth
                          id="didimoKey-filter"
                          label="Didimo Key"
                          variant="outlined"
                          value={keyU}
                          onChange={(e) => {
                            setKeyU(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Didimo Key">
                                <InfoIcon />
                              </Tooltip>
                            ),
                          }}
                        />
                      </div>
                      <div>
                        <p />
                      </div>
                      <div className="date">
                        <div className="date_1">
                          <h4>Created at</h4>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginLeft: '-8px' }}
                          >
                            <Grid
                              item
                              xs={8}
                              md={6}
                              style={{ paddingLeft: '8px' }}
                            >
                              <div style={{ paddingTop: '10px' }}>
                                <DatePicker
                                  //selectsRange={true}
                                  selected={createdAtFromU}
                                  //endDate={createdAt[1]}
                                  //dateFormat="MM/dd/yyyy"
                                  onChange={(update) => {
                                    setCreatedAtFromU(update);
                                  }}
                                  maxDate={
                                    createdAtToU
                                      ? moment(createdAtToU)
                                          .subtract(1, 'days')
                                          .toDate()
                                      : null
                                  }
                                  isClearable={true}
                                  placeholderText="From"
                                />
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              md={6}
                              style={{ paddingLeft: '8px' }}
                            >
                              <div style={{ paddingTop: '10px' }}>
                                <DatePicker
                                  // selectsRange={true}
                                  selected={createdAtToU}
                                  //dateFormat="MM/dd/yyyy"
                                  //endDate={createdAtTo[1]}
                                  onChange={(update) => {
                                    setCreatedAtToU(update);
                                  }}
                                  minDate={
                                    createdAtFromU
                                      ? moment(createdAtFromU)
                                          .add(1, 'days')
                                          .toDate()
                                      : null
                                  }
                                  isClearable={true}
                                  placeholderText="To"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div className="date_1">
                          <h4>Expires at</h4>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginLeft: '-8px' }}
                          >
                            <Grid
                              item
                              xs={8}
                              md={6}
                              style={{ paddingLeft: '8px' }}
                            >
                              <div style={{ paddingTop: '10px' }}>
                                <DatePicker
                                  //selectsRange={true}
                                  selected={expiresAtFromU}
                                  //dateFormat="MM/dd/yyyy"
                                  // endDate={ExpiresAt[1]}
                                  onChange={(update) => {
                                    setExpiresAtFromU(update);
                                  }}
                                  maxDate={
                                    expiresAtToU
                                      ? moment(expiresAtToU)
                                          .subtract(1, 'days')
                                          .toDate()
                                      : null
                                  }
                                  isClearable={true}
                                  placeholderText="From"
                                />
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={8}
                              md={6}
                              style={{ paddingLeft: '8px' }}
                            >
                              <div style={{ paddingTop: '10px' }}>
                                <DatePicker
                                  //selectsRange={true}
                                  selected={expiresAtToU}
                                  //dateFormat="MM/dd/yyyy"
                                  // endDate={ExpiresAtTo[1]}
                                  minDate={
                                    expiresAtFromU
                                      ? moment(expiresAtFromU)
                                          .add(1, 'days')
                                          .toDate()
                                      : null
                                  }
                                  onChange={(update) => {
                                    setExpiresAtToU(update);
                                  }}
                                  isClearable={true}
                                  placeholderText="To"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <div
                          className="statusContainer"
                          style={{ paddingTop: '10px' }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="demo-multiple-checkbox-label">
                              Status
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={statusU}
                              onChange={(e) => {
                                const value = e.target.value;
                                setStatusU(
                                  typeof value === 'string'
                                    ? value.split(',')
                                    : value
                                );
                              }}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => selected.join(', ')}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={statusU.indexOf(name) > -1}
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="date">
                          <div className="checkbox_fav">
                            <Checkbox
                              color="primary"
                              checked={isFavoriteU}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              onChange={(e) => {
                                setIsFavoriteU(e.target.checked ? 'true' : '');
                              }}
                            />
                            <h4 className="ModelFontFamily">Is Favorite </h4>
                          </div>

                          <FormControl className="form_drop_down ">
                            <h4
                              className="ModelFontFamily"
                              style={{ marginBottom: '10px' }}
                            >
                              Sort Order
                            </h4>
                            <RadioGroup
                              aria-label="filter"
                              name="filter"
                              defaultValue="created_at"
                              value={sortByU}
                              onChange={(e) => setSortByU(e.target.value)}
                            >
                              <div style={{ display: 'flex' }}>
                                <FormControlLabel
                                  value="-created_at"
                                  control={<Radio color="primary" />}
                                  label="Newest first"
                                />
                                <FormControlLabel
                                  value="created_at"
                                  control={<Radio color="primary" />}
                                  label="Oldest first"
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Box sx={{ flexGrow: 1, border: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Item>
                        <Button
                          style={styles.btnClear}
                          onClick={handleClearFilter}
                        >
                          Clear
                        </Button>
                      </Item>
                    </Grid>

                    <Grid item xs={8}>
                      <ItemRight>
                        <Button
                          onClick={(e) => {
                            handleCloseFilter(e);
                            resetFilters();
                          }}
                          style={styles.btnCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={styles.applyBtn}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleApplyFilter({
                              isFavorite: isFavoriteU ? 'true' : '',
                              key: keyU,
                              createdAtFrom: createdAtFromU
                                ? format(new Date(createdAtFromU), 'yyyy-MM-dd')
                                : '',
                              createdAtTo: createdAtToU
                                ? format(new Date(createdAtToU), 'yyyy-MM-dd')
                                : '',
                              expiresAtFrom: expiresAtFromU
                                ? format(new Date(expiresAtFromU), 'yyyy-MM-dd')
                                : '',
                              expiresAtTo: expiresAtToU
                                ? format(new Date(expiresAtToU), 'yyyy-MM-dd')
                                : '',
                              status: statusU,
                              sortBy: sortByU,
                            });
                            resetFilters();
                          }}
                        >
                          {' '}
                          Apply{' '}
                        </Button>
                      </ItemRight>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="HideChip">
        {!open && activeFilters > 0 && ddmoKey ? (
          <Chip
            label={'Key: ' + ddmoKey}
            color="primary"
            style={{ margin: '5px' }}
            onDelete={() => {
              let baseParams = getInitialParamsStructure();
              baseParams.key = '';
              handleApplyFilter(baseParams);
            }}
          />
        ) : null}
        {!open && activeFilters > 0 && isFavorite ? (
          <Chip
            label={'Is Favorite: ' + isFavorite}
            color="primary"
            style={{ margin: '5px' }}
            onDelete={() => {
              let baseParams = getInitialParamsStructure();
              baseParams.isFavorite = '';
              handleApplyFilter(baseParams);
            }}
          />
        ) : null}
        {!open && activeFilters > 0 && expiresAtFrom ? (
          <Chip
            label={'Expires At: From ' + formatDate(expiresAtFrom)}
            color="primary"
            style={{ margin: '5px' }}
            onDelete={() => {
              let baseParams = getInitialParamsStructure();
              baseParams.expiresAtFrom = '';
              handleApplyFilter(baseParams);
            }}
          />
        ) : null}
        {!open && activeFilters > 0 && expiresAtTo ? (
          <Chip
            label={'Expires at: To ' + formatDate(expiresAtTo)}
            color="primary"
            style={{ margin: '5px' }}
            onDelete={() => {
              let baseParams = getInitialParamsStructure();
              baseParams.expiresAtTo = '';
              handleApplyFilter(baseParams);
            }}
          />
        ) : null}
        {!open && activeFilters > 0 && createdAtFrom ? (
          <Chip
            label={'Created at: From ' + formatDate(createdAtFrom)}
            color="primary"
            style={{ margin: '5px' }}
            onDelete={() => {
              let baseParams = getInitialParamsStructure();
              baseParams.createdAtFrom = '';
              handleApplyFilter(baseParams);
            }}
          />
        ) : null}
        {!open && activeFilters > 0 && createdAtTo ? (
          <Chip
            label={'Created at: To ' + formatDate(createdAtTo)}
            color="primary"
            style={{ margin: '5px' }}
            onDelete={() => {
              let baseParams = getInitialParamsStructure();
              baseParams.createdAtTo = '';
              handleApplyFilter(baseParams);
            }}
          />
        ) : null}
        {!open && activeFilters > 0 && status.length > 0
          ? status.map((status) => (
              <Chip
                label={'Status: ' + status}
                color="primary"
                style={{ margin: '5px' }}
                onDelete={() => {
                  let baseParams = getInitialParamsStructure();
                  baseParams.status = baseParams.status.filter(
                    (s) => s !== status
                  );
                  handleApplyFilter(baseParams);
                }}
              />
            ))
          : null}
      </div>
    </>
  );
};

export default FilterModal;
