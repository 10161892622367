import TopLevelPanel from '../TopLevelPanel';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Edit, DeleteOutlined } from '@material-ui/icons';
import './Template.css';
import axios from 'axios';
import { formatDate } from '../../helpers/formaters';
import DeleteTemplateModel from './DeleteTemplateModel';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@mui/material/Alert';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../Transactions/TablePaginationActions';
import TableFooter from '@material-ui/core/TableFooter';

const TemplateItem = (props) => {
  const {
    showTemplates,
    isLoading,
    maxRowPerPage,
    page,
    onPageChange,
    pageData,
    onEdit,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [uid, setUid] = useState('');
  const [editData, setEditData] = useState('');
  const [editUid, setEditUid] = useState('');
  const [editSnackbar, setEditSnackbar] = useState(false);
  const [deleteSnackbar, setDeleteSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [count, setCount] = useState(0);
  const [tableRow, setTableRow] = useState([]);

  useEffect(() => {
    if (pageData) {
      let rows = pageData || [];
      if (rows.length < maxRowPerPage) {
        const addRow = maxRowPerPage - rows.length;
        for (let i = 0; i < addRow; i++) {
          rows.push({
            template_name: null,
            created_at: null,
            pipeline_versions: null,
          });
        }
      }
      setTableRow(pageData.didimo_generation_templates);
      setCount(pageData.total_size);
    }
  }, [pageData, maxRowPerPage]);

  const onDelete = (uid) => {
    setIsOpen(true);
    setUid(uid);
  };

  const handleDeleteTemplate = () => {
    axios
      .delete(`didimo_generation_templates/${uid}`)
      .then(() => {
        showTemplates();
      })
      .then(() => {
        setDeleteSnackbar(true);
      })
      .catch(() => {
        setErrorSnackbar(true);
      });
    setIsOpen(false);
  };

  const closeEditModel = () => {
    setIsOpen(false);
    setOpen(false);
  };

  return (
    <div>
      <TopLevelPanel>
        <Backdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div>
          <div width="1">
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: '#fafafa' }}>
                  <TableRow>
                    <TableCell className="table-head">Template name</TableCell>
                    <TableCell className="table-head textField_right">
                      Created at
                    </TableCell>
                    <TableCell className="table-head textField_center">
                      DGP version
                    </TableCell>
                    <TableCell className="table-head textField_left">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(tableRow || []).map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell>{item.template_name}</TableCell>
                        <TableCell className="textField_right">
                          {formatDate(item.created_at)}
                        </TableCell>
                        <TableCell className="textField_center">
                          {item.pipeline_versions}
                        </TableCell>
                        <TableCell className="textField_left">
                          <Tooltip title="Edit Template">
                            <IconButton size="small" aria-label="edit template">
                              <Edit onClick={() => onEdit(item.uuid)} />
                            </IconButton>
                          </Tooltip>
                          {item.scope !== 'system' && (
                            <Tooltip title="Delete Template">
                              <IconButton
                                size="small"
                                aria-label="delete template"
                                style={{ marginLeft: 10 }}
                              >
                                <DeleteOutlined
                                  color="error"
                                  onClick={() => onDelete(item.uuid)}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow key={props.page}>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={10}
                      count={count ? count : 0}
                      rowsPerPage={10}
                      labelDisplayedRows={({ from, to, count }) => {
                        return `${from}-${to === -1 ? count : to}`;
                      }}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={onPageChange}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>

        <DeleteTemplateModel
          isOpen={isOpen}
          onClose={closeEditModel}
          handleDeleteTemplate={handleDeleteTemplate}
        />
      </TopLevelPanel>
      <Snackbar
        open={editSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setEditSnackbar(false);
        }}
        message="Didimo template edited successfully"
      >
        <Alert
          onClose={() => {
            setEditSnackbar(false);
          }}
          severity="success"
        >
          Didimo template edited successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setDeleteSnackbar(false);
        }}
        message="Didimo template deleted successfully"
      >
        <Alert
          onClose={() => {
            setEditSnackbar(false);
          }}
          severity="success"
        >
          Didimo template deleted successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setErrorSnackbar(false);
        }}
        message="There was an error"
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorSnackbar(false);
          }}
        >
          There was an error!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TemplateItem;
